<template>
  <v-dialog v-model="show" scrollable width="1500" transition="dialog-bottom-transition">
    <v-card class="basicBackGround">
      <v-toolbar dark color="light-blue">
        <v-toolbar-title>Avenças: {{ client.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn icon class="mx-0" @click="openCreateCovenant">
              <v-icon v-on="on" color="white">mdi-cash-sync</v-icon>
            </v-btn>
          </template>
          <span>Criar avenças dos serviços selecionados</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon @click="toggleDialog()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Novo Registo</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text>
          <v-card class="elevation-0" color="transparent" v-if="covenants.length != 0">
            <v-list color="transparent">
              <v-list-item-group>
                <v-list-item>
                  <v-list-item-content>
                    <v-data-table infinite-scroll-disabled="busy"
                      :headers="headers" :items="covenants" :loading="loading" hide-default-footer
                      :items-per-page="covenants.length + 10" class="mTable">
                      <template v-slot:[`item.price`]="{ item }">
                        {{ item.price }} €
                      </template>
                      <template v-slot:[`item.start_date`]="{ item }">
                        {{ formatDate(item.start_date) }}
                      </template>
                      <template v-slot:[`item.end_date`]="{ item }">
                        <span v-if="item.end_date != null">{{ formatDate(item.end_date) }}</span>
                      </template>
                      <template v-slot:[`item.type_covenant`]="{ item }">
                        <template v-if="item.type_covenant == 'anual'">Anual</template>
                        <template v-if="item.type_covenant == 'mensal'">Mensal</template>
                        <template v-if="item.type_covenant == 'umavez'">Uma vez</template>
                      </template>
                      <template v-slot:[`item.discontinued`]="{ item }">
                        <v-icon v-if="item.discontinued" color="red darken-2">mdi-close</v-icon>
                        <v-icon v-else color="green darken-2">mdi-check</v-icon>
                      </template>

                      <template v-slot:[`item.action`]="{ item }">
                        <!--
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn icon class="mx-0" @click="edittItem(item)">
                              <v-icon v-on="on" color="teal">mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Editar</span>
                        </v-tooltip>
                      -->

                        <v-tooltip top v-if="item.umdoscyclesinvoiced != true">
                          <template v-slot:activator="{ on }">
                            <v-btn icon class="mx-0" @click="deleteItem(item)">
                              <v-icon v-on="on" color="pink">mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Eliminar</span>
                        </v-tooltip>

                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn icon class="mx-0" @click="openCovenantcyclesItem(item)">
                              <v-icon v-on="on" color="orange">mdi-cached</v-icon>
                            </v-btn>
                          </template>
                          <span>Ciclos da avença</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
          <v-card v-else class="elevation-0" align="center" justify="center" color="transparent">
            <v-icon size="200" color="red">
              mdi-file
            </v-icon>
            <v-card-title class="justify-center">Não há avenças a apresentar.</v-card-title>
          </v-card>
      </v-card-text>
    </v-card>

    <covenantscycles ref="covenantscycles" @handleCovenant="handleCovenant" @handleRemoverClientes2="handleRemoverClientes2"/>

    <v-dialog v-model="createDialog" presistent scrollable width="600" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Adicionar</v-toolbar-title>
            <v-spacer />
            <v-btn icon dark @click="createDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-autocomplete label="Tipo de cobrança" v-model="editItem.type_covenant" :items="type_covenants" item-text="text" item-value="value" :rules="[rules.required]"></v-autocomplete>
                </v-col>
              </v-row>

              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-autocomplete label="Tipo de serviço" v-model="editItem.TypeeventId" :items="typeevents" item-text="name" item-value="id" :rules="[rules.required]" @change="typeeventChange"></v-autocomplete>
                </v-col>
              </v-row>

              <v-row align="center" justify="center" v-if="editItem.TypeeventId != null">
                <v-col cols="12">
                  <v-autocomplete label="Planos" v-model="editItem.PlanId" :items="plans" item-text="Plan.name" item-value="Plan.id" @change="planChange"></v-autocomplete>
                </v-col>
              </v-row>

              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-text-field type="number" v-model="editItem.price" label="Preço (€)" :rules="[rules.required]"></v-text-field>
                </v-col>
              </v-row>

              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-dialog ref="dialog" v-model="startdateModal" :return-value.sync="editItem.start_date" persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="editItem.start_date" label="Data de início" :rules="[rules.required]" readonly v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker :max="editItem.end_date" locale="pt-pt" v-model="editItem.start_date" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="startdateModal = false">
                        Cancelar
                      </v-btn>
                      <v-btn text color="primary" @click="$refs.dialog.save(editItem.start_date)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>

                </v-col>
              </v-row>

              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-dialog ref="dialog2" v-model="enddateModal" :return-value.sync="editItem.end_date" persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="editItem.end_date" label="Data de fim" :rules="[rules.required]" readonly v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker :min="editItem.start_date" locale="pt-pt" v-model="editItem.end_date" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="enddateModal = false">
                        Cancelar
                      </v-btn>
                      <v-btn text color="primary" @click="$refs.dialog2.save(editItem.end_date)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>

              <v-row align="center" justify="center" v-if="editItem.type_covenant == 'mensal'">
                <v-col cols="12" align="center" justify="center">
                  <v-item-group multiple v-model="selectedDays" align="center" justify="center">
                    <v-subheader>Repetir :</v-subheader>
                    <v-item :value="0" v-slot="{ active, toggle }">
                      <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                        @click="toggle">Dom</v-btn>
                    </v-item>

                    <v-item :value="1" v-slot="{ active, toggle }">
                      <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                        @click="toggle">Seg</v-btn>
                    </v-item>

                    <v-item :value="2" v-slot="{ active, toggle }">
                      <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                        @click="toggle">Ter</v-btn>
                    </v-item>

                    <v-item :value="3" v-slot="{ active, toggle }">
                      <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                        @click="toggle">Qua</v-btn>
                    </v-item>

                    <v-item :value="4" v-slot="{ active, toggle }">
                      <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                        @click="toggle">Qui</v-btn>
                    </v-item>

                    <v-item :value="5" v-slot="{ active, toggle }">
                      <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                        @click="toggle">Sex</v-btn>
                    </v-item>

                    <v-item :value="6" v-slot="{ active, toggle }">
                      <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                        @click="toggle">Sab</v-btn>
                    </v-item>
                  </v-item-group>
                </v-col>
                <v-col cols="12" align="center" justify="center" v-if="selectedDays.length == 0 && editItem.type_covenant == 'mensal'">
                  <span style="color: red;">Tem de escolher pelo menos 1 dia.</span>
                </v-col>
              </v-row>

            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions v-if="$store.state.user.role === 'admin' || editItem.UserId === $store.state.user.id || !editItem.UserId">
          <v-btn text color="red" outlined @click="clear">Limpar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="primary" outlined @click="submit">Submeter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialog" presistent scrollable width="600" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Editar</v-toolbar-title>
            <v-spacer />
            <v-btn icon dark @click="editDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-form ref="form2" v-model="valid2" lazy-validation>
            <v-container>

              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-dialog ref="dialog2" v-model="enddateModal2" :return-value.sync="editItem.end_date" persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="editItem.end_date" label="Data de fim" readonly v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker locale="pt-pt" v-model="editItem.end_date" scrollable>
                      <v-btn text color="primary" @click="editItem.end_date = null">
                        Limpar
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="enddateModal2 = false">
                        Cancelar
                      </v-btn>
                      <v-btn text color="primary" @click="$refs.dialog2.save(editItem.end_date)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
              <!--
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-checkbox v-model="editItem.discontinued" label="Inactivo"></v-checkbox>
                </v-col>
              </v-row>
              -->
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions v-if="$store.state.user.role === 'admin' || editItem.UserId === $store.state.user.id || !editItem.UserId">
          <v-spacer></v-spacer>
          <v-btn text color="primary" outlined @click="submitEditItem">Submeter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogConfirmCreateCovenant" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Adicionar avenças</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <span>Quer mesmo adicionar as avenças dos serviços selecionados?</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="blue darken-1" text @click="dialogConfirmCreateCovenant = false">Fechar</v-btn>
          <v-btn color="blue darken-1" text @click="confirmCreateCovenant">Adicionar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import ServiceCovenants from "@/services/ServiceCovenants";
import ServiceTypeevents from "@/services/ServiceTypeevents";
import ServicePlantypeevents from "@/services/ServicePlantypeevents";
import ServiceClienttypeevents from "@/services/ServiceClienttypeevents";
import ServiceClientPlanstypeevents from "@/services/ServiceClientPlanstypeevents";
import Covenantscycles from "@/components/client/CovenantsCycles.vue";
export default {
  name: "ClientCovenants",
  components: {
    Covenantscycles
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_URL,
      show: false,
      loading: false,
      client: {},
      covenants: [],
      typeevents: [],
      plans: [],
      createDialog: false,
      editDialog: false,
      dialogConfirmCreateCovenant: false,
      valid: true,
      valid2: true,
      editItem: {
      },
      selectedDays: [],
      enddateModal: false,
      enddateModal2: false,
      startdateModal: false,
      startdateModal2: false,
      type_covenants: [
        { text: "Anual", value: "anual" },
        { text: "Mensal", value: "mensal" },
        { text: "Uma vez", value: "umavez" },
      ],
      headers: [
        { text: "Tipo de serviço", sortable: false, value: "Typeevent.name" },
        { text: "Plano", sortable: false, value: "Plan.name" },
        { text: "Tipo de cobrança", sortable: false, value: "type_covenant" },
        { text: "Data ínicio", sortable: false, value: "start_date" },
        { text: "Data fim", sortable: false, value: "end_date" },
        { text: "Activo", sortable: false, value: "discontinued", align: "center" },
        { text: "Ações", value: "action", sortable: false, align: "center" }
      ],
      rules: {
        required: value => !!value || "Campo Obrigatório.",
        counter: value =>
          (value && value.length <= 250) ||
          "Campo tem de ter menos de 250 caracteres",
        isValidNumber: value =>
          /^([0-9]+[.])?[0-9]+$/.test(value) || "Valor inválido"
      }
    };
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
    close() {
      this.show = false;
    },
    async open(client) {
      this.loading = true;
      this.client = client;
      this.covenants = (await ServiceCovenants.getClientCovenants(this.client.id)).data;
      this.show = true;
      this.loading = false;
    },
    async toggleDialog () {
      this.clear()
      this.createDialog = true
      this.typeevents = (await ServiceTypeevents.list()).data;
    },
    async submit() {
      try {
        let hasError = false;
        if (this.selectedDays.length == 0 && this.editItem.type_covenant == 'mensal') {
          hasError = true;
        }
        if (this.$refs.form.validate() && hasError == false) {
          this.editItem.daysOfWeek = this.selectedDays
          this.editItem.ClientId = this.client.id;

          await ServiceCovenants.createClientCovenants(this.editItem);

          this.covenants = (await ServiceCovenants.getClientCovenants(this.client.id)).data;

          this.$store.dispatch("setSnackbar", {});
          this.createDialog = false
          this.clear();
          this.$emit('handleRemoverClientes');
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    clear () {
      this.editItem = {
        id: null,
        price: '',
        numberreplacementevent: 0,
        type_covenant: '',
        end_date: null,
        start_date: null,
        TypeeventId: null,
        ClientId: null
      },
      this.selectedDays = []
    },
    async deleteItem(item) {
      if (confirm("Confirma a eliminação deste elemento?")) {
        try {
          await ServiceCovenants.deleteClientCovenants(item.id);
          this.$store.dispatch("setSnackbar", {});
          this.covenants = (await ServiceCovenants.getClientCovenants(this.client.id)).data;
          this.$emit('handleRemoverClientes');
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
        }
      }
    },
    async handleCovenant() {
      this.covenants = (await ServiceCovenants.getClientCovenants(this.client.id)).data;
    },
    handleRemoverClientes2() {
      this.$emit('handleRemoverClientes');
    },
    async edittItem (item) {
      this.editItem = item
      this.editDialog = true
    },
    async submitEditItem () {
      try {
        if (this.$refs.form2.validate()) {
          await ServiceCovenants.updateClientCovenants(this.editItem);
          this.covenants = (await ServiceCovenants.getClientCovenants(this.client.id)).data;
          this.$store.dispatch("setSnackbar", {});
          this.editDialog = false
          this.$emit('handleRemoverClientes');
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    async typeeventChange(item) {
      let typeseventclient = (await ServiceClienttypeevents.tiposservicocliente(this.client.id, item)).data;
      this.plans = (await ServicePlantypeevents.getTypeeventPlans(null, item)).data
      let preco = null
      if (typeseventclient[0]) {
        preco = typeseventclient[0].price;
        if (preco == 0) {
            let typeevent = (await ServiceTypeevents.show(item)).data;
            preco = typeevent.price
        }
      }
      this.editItem.price = preco
    },
    async openCreateCovenant() {
      this.dialogConfirmCreateCovenant = true
    },
    openCovenantcyclesItem(item) {
      this.$refs.covenantscycles.open(item);
    },
    async confirmCreateCovenant() {
      try {
        await ServiceCovenants.CreateCovenantTypeevents(this.client);
        this.$store.dispatch("setSnackbar", {});
        this.dialogConfirmCreateCovenant = false
        this.open(this.client)
        this.$emit('handleRemoverClientes');
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    async planChange (planid) {
      const clienttypeeventplans = (await ServiceClientPlanstypeevents.planostiposservicocliente(this.client.id, this.editItem.TypeeventId, planid)).data;
      let preco = null
      if (clienttypeeventplans[0]) {
        preco = clienttypeeventplans[0].price;
        if (preco == 0) {
            const plan = (await ServicePlantypeevents.getTypeeventPlans(planid, this.editItem.TypeeventId)).data
            preco = plan[0].price
        }
      }
      this.editItem.price = preco
      this.editItem.numberreplacementevent = plan[0].number_replacement_event
    },
  },
  computed: {
  },
  async mounted() {
  },
  created() {
    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {

      if (this.$refs.covenantscycles) {
        if (this.$refs.covenantscycles.$refs.eventDialog) {
          if (this.$refs.covenantscycles.$refs.eventDialog.dialog) {
            if (this.$refs.covenantscycles.$refs.eventDialog.navGuards()) {
              next(false);
              return;
            }
          }
        }

        if (this.$refs.covenantscycles.show) {
          this.$refs.covenantscycles.show = false;
          next(false);
          return;
        }
      }

      next();
    });

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard()
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
