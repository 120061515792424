import Api from '@/services/ServiceBase'
const path = 'clientevents'

export default {
  marcacaocliente (idcliente, idmarcacao) {
    return Api().get(path + '/marcacaocliente',{
      params: {
        idcliente,
        idmarcacao
      }
    })
  },
  update (elementos) {
    return Api().put(path + `/${elementos.id}`, elementos)
  },
  darmarcacoescomofaturadas () {
    return Api().get(path + '/darmarcacoescomofaturadas',{})
  },
}
