import moment from "moment";
import ServiceUsers from "@/services/ServiceUsers";
import ServiceRooms from "@/services/ServiceRooms";
import ServiceTypeevents from "@/services/ServiceTypeevents";
import ServiceGroups from "@/services/ServiceGroups";

export function round(x, digits) {
  return parseFloat(x.toFixed(digits))
}

export function onlyNumbers(e) {
  if (!/^\d*\.?\d*$/.test(e.key)) {
    e.preventDefault();
  }
}

export function capitalize(word) {
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
}


export function convertDate(date) {
  return moment(date).format("YYYY-MM-DD / HH:mm") + "h";
}

export async function getUsersAndRooms(pageUser, pageRoom, users, rooms) {
  await Promise.all([ServiceUsers.list(null, null, pageUser),
  ServiceRooms.list(pageRoom)])
    .then(results => {
      _.forEach(results[0].data, value => {
        value.valid = true;
        users.push(value);
      });
      _.forEach(results[1].data, value => {
        value.valid = true;
        rooms.push(value);
      });

      if (results[0].data.length > 0 || results[1].data.length > 0) {
        getUsersAndRooms(pageUser + 1, pageRoom + 1, users, rooms);
      }
    })
    .catch(async error => {
      throw error;
    });
}

export async function getTypeevents(pageTypeevent, typeevents) {
  await Promise.all([ServiceTypeevents.list(pageTypeevent, null, 1)])
    .then(results => {
      _.forEach(results[0].data, value => {
        value.valid = true;
        typeevents.push(value);
      });

      if (results[0].data.length > 0) {
        getTypeevents(pageTypeevent + 1, typeevents);
      }
    })
    .catch(async error => {
      throw error;
    });
}

export async function getGroups(pageGroup, groups) {
  await Promise.all([ServiceGroups.list(pageGroup)])
    .then(results => {
      _.forEach(results[0].data, value => {
        value.valid = true;
        groups.push(value);
      });

      if (results[0].data.length > 0) {
        getGroups(pageGroup + 1, groups);
      }
    })
    .catch(async error => {
      throw error;
    });
}

export function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI
    .split(",")[0]
    .split(":")[1]
    .split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], {
    type: mimeString
  });
  return blob;
}

export function isMobile() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true
    } else {
      return false
    }
  }
