<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" md="10">
        <v-data-table class="mTable" id="mytable" v-infinite-scroll="getUsers" infinite-scroll-disabled="busy"
          infinite-scroll-distance="10" :headers="headers" :items="users" :loading="loading" hide-default-footer
          :items-per-page="users.length + 10">
          <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>

          <template v-slot:[`item.data-table-expand`]="{ item }">
            <v-icon @click="invoiceLinesLogs(item)" color="blue">mdi-eye</v-icon>
          </template>

          <template v-slot:[`item.start`]="{ item }">
            {{ formatDate(item.start) }}
          </template>

          <template v-slot:[`item.hourstart`]="{ item }">
            {{ formatTime(item.start) }}
          </template>

          <template v-slot:[`item.hourend`]="{ item }">
            {{ formatTime(item.end) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="loglinesDialog" max-width="1500px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Linhas do log</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-col cols="12">
            <v-data-table :headers="headersLines" :items="invoicelineslogs" hide-default-footer
            class="mTable full-width" :items-per-page="invoicelineslogs.length + 10">

            <template v-slot:[`item.erp_billing_number`]="{ item }">
              <v-icon v-if="item.erp_billing_number" color="green darken-2">mdi-check</v-icon>
              <v-icon v-else color="red darken-2">mdi-close</v-icon>
            </template>

            <template v-slot:[`item.erp_billing_number_valid`]="{ item }">
              <v-icon v-if="item.erp_billing_number_valid" color="green darken-2">mdi-check</v-icon>
              <v-icon v-else color="red darken-2">mdi-close</v-icon>
            </template>

            <template v-slot:[`item.erp_all_articles_valid`]="{ item }">
              <v-icon v-if="item.erp_all_articles_valid" color="green darken-2">mdi-check</v-icon>
              <v-icon v-else color="red darken-2">mdi-close</v-icon>
            </template>

            <template v-slot:[`item.erp_create_document`]="{ item }">
              <v-icon v-if="item.erp_create_document" color="green darken-2">mdi-check</v-icon>
              <v-icon v-else color="red darken-2">mdi-close</v-icon>
            </template>

            <template v-slot:[`item.clinic_create_document`]="{ item }">
              <v-icon v-if="item.clinic_create_document" color="green darken-2">mdi-check</v-icon>
              <v-icon v-else color="red darken-2">mdi-close</v-icon>
            </template>

            <template v-slot:[`item.erp_print_template_valid`]="{ item }">
              <v-icon v-if="item.erp_print_template_valid" color="green darken-2">mdi-check</v-icon>
              <v-icon v-else color="red darken-2">mdi-close</v-icon>
            </template>

            <template v-slot:[`item.erp_base64_valid`]="{ item }">
              <v-icon v-if="item.erp_base64_valid" color="green darken-2">mdi-check</v-icon>
              <v-icon v-else color="red darken-2">mdi-close</v-icon>
            </template>

            <template v-slot:[`item.invite_document_email`]="{ item }">
              <v-icon v-if="item.invite_document_email" color="green darken-2">mdi-check</v-icon>
              <v-icon v-else color="red darken-2">mdi-close</v-icon>
            </template>

            <template v-slot:[`item.clinic_base64_valid`]="{ item }">
              <v-icon v-if="item.clinic_base64_valid" color="green darken-2">mdi-check</v-icon>
              <v-icon v-else color="red darken-2">mdi-close</v-icon>
            </template>

            <template v-slot:[`item.erp_login`]="{ item }">
              <v-icon v-if="item.erp_login" color="green darken-2">mdi-check</v-icon>
              <v-icon v-else color="red darken-2">mdi-close</v-icon>
            </template>
          </v-data-table>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import infiniteScroll from "vue-infinite-scroll";
import ServiceInvoiceLogs from "@/services/ServiceInvoiceLogs";
import ServiceInvoiceLogLines from "@/services/ServiceInvoiceLogLines";
import _ from "lodash";
export default {
  name: "List-Invoices",
  directives: {
    infiniteScroll
  },
  data() {
    return {
      clientTypeP: '',
      filterDialog: false,
      loglinesDialog: false,
      fab: false,
      valid: true,
      page: 1,
      headers: [
        { text: 'Linhas fatura', value: 'data-table-expand', sortable: false },
        { text: 'Descrição', value: 'description', sortable: false },
        { text: "Data", value: "start", sortable: false },
        { text: "Hora início", value: "hourstart", sortable: false },
        { text: "Hora fim", value: "hourend", sortable: false }
      ],
      headersLines: [
        ...(this.$store.state.configuracoeserp.ativo ? [{ text: "Cliente", value: "Client.name", sortable: false }] : []),
        ...(this.$store.state.configuracoeserp.ativo ? [{ text: "Tem cliente de faturação na ficha", value: "erp_billing_number", sortable: false }] : []),
        ...(this.$store.state.configuracoeserp.ativo ? [{ text: "Fez login no ERP", value: "erp_login", sortable: false }] : []),
        ...(this.$store.state.configuracoeserp.ativo ? [{ text: "Cliente de faturação na ficha é válido", value: "erp_billing_number_valid", sortable: false }] : []),
        ...(this.$store.state.configuracoeserp.ativo ? [{ text: "Todos os códigos dos artigos válidos no ERP", value: "erp_all_articles_valid", sortable: false }] : []),
        ...(this.$store.state.configuracoeserp.ativo ? [{ text: "Criou documento no ERP", value: "erp_create_document", sortable: false }] : []),
        { text: 'Criou documento na aplicação', value: 'clinic_create_document', sortable: false },
        ...(this.$store.state.configuracoeserp.ativo ? [{ text: "Modelo de impreção ERP válido", value: "erp_print_template_valid", sortable: false }] : []),
        ...(this.$store.state.configuracoeserp.ativo ? [{ text: "Foi buscar o docuemnto (base64, pdf) ao ERP", value: "erp_base64_valid", sortable: false }] : []),
        ...(this.$store.state.configuracoeserp.ativo ? [{ text: "Adicionou (base64, pdf) á fatura", value: "clinic_base64_valid", sortable: false }] : []),
        ...(this.$store.state.configuracoeserp.ativo ? [{ text: "Enviou documento por email", value: "invite_document_email", sortable: false }] : []),
      ],
      rules: {
        required: value => !!value || "Campo Obrigatório.",
        counter: value =>
          (value && value.length <= 250) ||
          "Campo tem de ter menos de 250 caracteres",
        isValidNumber: value =>
          /^([0-9]+[.])?[0-9]+$/.test(value) || "Valor inválido"
      },
      users: [],
      invoicelineslogs: [],
      busy: false,
      loading: true,
    };
  },
  methods: {
    formatTime(date) {
        const datee = new Date(date);
        const hours = String(datee.getHours()).padStart(2, '0');
        const minutes = String(datee.getMinutes()).padStart(2, '0');

        return `${hours}:${minutes}`;
    },
    formatDate(dateString) {
      const date = new Date(dateString);

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
    async getUsers() {
      this.busy = true;
      this.loading = true;
      const response = await ServiceInvoiceLogs.list(
        this.page
      );

      this.users = this.users.concat(response.data);

      this.page++;
      this.busy = false;
      if (response.data.length == 0) {
        this.busy = true;
      }
      this.loading = false;
    },

    Filter() {
      this.users = [];
      this.invoices = [];
      this.page = 1;
      this.filterDialog = false;
      this.total_faturado = 0;
      this.getUsers();
    },
    async invoiceLinesLogs (item) {
      this.loglinesDialog = true
      this.invoicelineslogs = (await ServiceInvoiceLogLines.list(item.id)).data;
    }
  },
  async mounted() {
    this.clientTypeP = config.CLIENT_TYPE_PLURAL;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
