<template>
  <v-container>
    <v-speed-dial fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false">
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark large fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-filter-variant</v-icon>
        </v-btn>
      </template>
      <v-btn fab dark large color="green" @click.stop="filterDialog = true">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-speed-dial>
    <v-row align="center" justify="center">
      <v-col cols="12" md="10" class="text-right">
        <h1>Total: {{ total_faturado }} €</h1>
      </v-col>
      <v-col cols="12" md="10">
        <v-data-table class="mTable" id="mytable" v-infinite-scroll="getUsers" infinite-scroll-disabled="busy"
          infinite-scroll-distance="10" :headers="headers" :items="users" :loading="loading" hide-default-footer
          :items-per-page="users.length + 10">
          <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>

          <template v-slot:[`item.data-table-expand`]="{ item }">
            <v-icon @click="linesInvoices(item)" color="blue">mdi-eye</v-icon>
          </template>

          <template v-slot:[`item.payment_methods`]="{ item }">
            {{paymentMethodDoc(item.payment_methods)}}
          </template>

          <template v-slot:[`item.type_document`]="{ item }">
            {{typeDoc(item.type_document)}}
          </template>

          <template v-slot:[`item.date`]="{ item }">
            {{ formatDate(item.date) }}
          </template>

          <template v-slot:[`item.price`]="{ item }">
            {{ item.price }} €
          </template>

          <template v-slot:[`item.pay`]="{ item }">
            <span v-if="item.pay === true"><v-btn color="success">Paga</v-btn></span>
            <span v-if="item.pay === false"><v-btn color="error">Não paga</v-btn></span>
          </template>

          <template v-slot:[`item.action`]="{ item }">


              <v-tooltip top v-if="item.base64pdf != null && item.base64pdf != '' && $store.state.configuracoeserp.ativo === true">
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0">
                    <v-icon v-on="on" color="teal" @click="downloadInvoice(item)">mdi-download</v-icon>
                  </v-btn>
                </template>
                <span>Download</span>
              </v-tooltip>

              <v-tooltip top v-if="item.base64pdf != null && item.base64pdf != '' && $store.state.configuracoeserp.ativo === true">
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0">
                    <v-icon v-on="on" color="primary" @click="openDatesPdfEmail(item)">mdi-email</v-icon>
                  </v-btn>
                </template>
                <span>Datas de envio do documento por email</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }" v-if="item.pay === false">
                  <v-btn icon class="mx-0">
                    <v-icon v-on="on" color="orange" @click="invoicePay(item)">mdi-credit-card</v-icon>
                  </v-btn>
                </template>
                <span>Marcar fatura como paga</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }" v-if="item.number_receipt != null && $store.state.configuracoeserp.ativo === true">
                  <v-btn icon class="mx-0">
                    <v-icon v-on="on" color="purple">mdi-receipt</v-icon>
                  </v-btn>
                </template>
                <span>Ver recibo</span>
              </v-tooltip>

          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="invoicelinesDialog" max-width="1200px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Linhas da fatura</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-col cols="12">
            <v-data-table :headers="headersLines" :items="linesinvoices" hide-default-footer
            class="mTable full-width" :items-per-page="linesinvoices.length + 10">

            <template v-slot:[`item.price`]="{ item }">
              {{ item.price }} €
            </template>

          </v-data-table>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="invoicepayDialog" max-width="1200px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Marcar fatura como paga</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-col cols="12">
              <v-autocomplete label="Método de pagamento" v-model="payment_method_select" :items="payment_methods" :rules="[rules.required]" item-text="text" item-value="method_abbreviation"></v-autocomplete>
            </v-col>
          </v-form>
          <v-col cols="12" class="text-right">
            <v-btn text color="primary" outlined @click="receiptInvoice()">Criar Recibo</v-btn>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="filterDialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Filtros :</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="filterDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-dialog ref="toggleDataInicio" v-model="toggleDataInicio" :return-value.sync="data_inicio_select"
                  persistent width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field clearable v-model="data_inicio_select" label="Data Inicio (desde)"
                      prepend-icon="mdi-clock-outline" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker :max="data_fim_select" locale="pt-pt" v-model="data_inicio_select" scrollable>
                    <v-btn text color="primary" @click="toggleDataInicio = false">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="updateDashboardDateInicio(data_inicio_select)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-dialog ref="toggleDataFim" v-model="toggleDataFim" :return-value.sync="data_fim_select"
                  persistent width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field clearable v-model="data_fim_select" label="Data Fim (até)"
                      prepend-icon="mdi-clock-outline" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker :min="data_inicio_select" locale="pt-pt" v-model="data_fim_select" scrollable>
                    <v-btn text color="primary" @click="toggleDataFim = false">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="updateDashboardDateFim(data_fim_select)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="number_invoice" clearable label="Número da fatura"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-autocomplete ref="cbClient" :label="clientTypeP"
                  :loading="loadingClients" v-debounce="newVal => myFn(newVal, 'v-text-field')" v-model="client_select"
                  :items="clients" item-text="name" item-value="id"
                  clearable :return-object="false"
                  hint="Nome, NIF ou Tlf." persistent-hint> <template v-slot:progress>
                    <span class="custom-loader">
                      <v-icon light>mdi-cached</v-icon>
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-autocomplete label="Método de pagamento" v-model="payment_method" :items="payment_methods" item-text="text" item-value="method_abbreviation" clearable></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-autocomplete label="Tipo de serviço" v-model="typeevent" :items="typeevents" item-text="name" item-value="id" clearable></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-radio-group v-model="pay" column>
                  <v-radio v-for="item in payArray" :key="item.value" :label="item.text" :value="item.value"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="blue darken-1" text @click="Filter()">Procurar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="inviteEmailPdfDialog" max-width="1200px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Datas de envio do documento por email</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
            <v-col cols="12">
              <v-data-table :headers="headersPdfEmail" :items="dataspdfemail" hide-default-footer
              class="mTable full-width" :items-per-page="dataspdfemail.length + 10">

              <template v-slot:[`item.date`]="{ item }">
                {{ formatDate(item.date) }}
              </template>

              <template v-slot:[`item.hour`]="{ item }">
                {{ formatTime(item.date) }}
              </template>

              <template v-slot:[`item.invite_success`]="{ item }">
                <v-icon v-if="item.invite_success" color="green darken-2">mdi-check</v-icon>
                <v-icon v-else color="red darken-2">mdi-close</v-icon>
              </template>

            </v-data-table>
            </v-col>
            <v-col cols="12" class="text-right">
              <v-btn text color="primary" outlined @click="invitePdfEmail()">Enviar documento por email</v-btn>
            </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import infiniteScroll from "vue-infinite-scroll";
import ServiceInvoices from "@/services/ServiceInvoices";
import ServiceInvoiceLogEmails from "@/services/ServiceInvoiceLogEmails";
import ServiceClients from '@/services/ServiceClients'
import ServiceTypeevents from "@/services/ServiceTypeevents";
import _ from "lodash";
export default {
  name: "List-Invoices",
  directives: {
    infiniteScroll
  },
  data() {
    return {
      clientTypeP: '',
      toggleDataInicio: false,
      toggleDataFim: false,
      loadingClients: false,
      filterDialog: false,
      invoicelinesDialog: false,
      inviteEmailPdfDialog: false,
      invoicepayDialog: false,
      payment_method_select: null,
      fab: false,
      valid: true,
      page: 1,
      pay: 2,
      payArray: [
        { text: "Ver todas", value: 2 },
        { text: "Ver apenas pagas", value: 0 },
        { text: "Ver apenas por pagar", value: 1 }
      ],
      headers: [
        { text: 'Linhas fatura', value: 'data-table-expand', sortable: false },
        { text: "Número fatura", value: "number_invoice", sortable: false },
        { text: "Tipo documento", value: "type_document", sortable: false },
        { text: "Cliente", value: "Client.name", sortable: false },
        { text: "Método de pagamento", value: "payment_methods", sortable: false },
        { text: "Data da faturação", value: "date", sortable: false },
        { text: "Preço", value: "price", sortable: false },
        { text: "Observações", value: "observations", sortable: false },
        { text: "", value: "pay", sortable: false },
        { text: "Ações", value: "action", sortable: false, align: "center" },
      ],
      headersLines: [
        { text: 'Referência', value: 'article_code_erp', sortable: false },
        { text: "Tipo de serviço", sortable: false, value: "Typeevent.name" },
        { text: "Plano", sortable: false, value: "Plan.name" },
        { text: 'Descrição', value: 'description', sortable: false },
        { text: 'Qnt', value: 'quantity', sortable: false },
        { text: 'Preço', value: 'price', sortable: false },
      ],
      headersPdfEmail: [
        { text: 'Documento', value: 'Invoice.number_invoice', sortable: false },
        { text: 'Data', value: 'date', sortable: false },
        { text: 'Hora', value: 'hour', sortable: false },
        { text: 'Enviou', value: 'invite_success', sortable: false },
      ],
      rules: {
        required: value => !!value || "Campo Obrigatório.",
        counter: value =>
          (value && value.length <= 250) ||
          "Campo tem de ter menos de 250 caracteres",
        isValidNumber: value =>
          /^([0-9]+[.])?[0-9]+$/.test(value) || "Valor inválido"
      },
      receiptinvoice: {},
      users: [],
      invoices: [],
      linesinvoices: [],
      busy: false,
      loading: true,
      payment_methods: [],
      typeevents: [],
      total_faturado: 0,
      clients: [],
      type_documents: [],
      itempdfemail: {},
      dataspdfemail: [],

      // pesquisas
      client_select: null,
      data_inicio_select: null,
      data_fim_select: null,
      number_invoice: null,
      payment_method: null,
      typeevent: null,
    };
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
    formatTime(dateString) {
      const date = new Date(dateString);

      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      return `${hours}:${minutes}`;
    },
    async getUsers() {
      this.busy = true;
      this.loading = true;
      const response = await ServiceInvoices.listinvoices(
        this.page,
        this.data_inicio_select,
        this.data_fim_select,
        this.client_select,
        this.payment_method,
        this.typeevent,
        this.number_invoice,
        this.pay
      );

      this.users = this.users.concat(response.data);

      this.page++;
      this.busy = false;
      if (response.data.length == 0) {
        this.busy = true;
      }
      this.loading = false;


    },
    async totalFaturado () {
      this.invoices = (await ServiceInvoices.listtotalfaturado(this.data_inicio_select, this.data_fim_select, this.client_select, this.payment_method, this.typeevent, this.number_invoice, this.pay)).data
      for (var i = 0; i < this.invoices.length; i++) {
        this.total_faturado = (parseFloat(this.total_faturado) + parseFloat(this.invoices[i].price)).toFixed(2);
      }
    },
    async downloadInvoice (item) {
      if (item.base64pdf == null || item.base64pdf == '') {
        alert("Documento sem pdf")
      } else {
        try {
          // Converter base64 em pdf
          const byteCharacters = atob(item.base64pdf);
          const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0));
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'application/pdf' });

          // Criar uma URL temporária e disparar o download
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = item.number_invoice;
          link.click();

          // Limpar a URL temporário
          URL.revokeObjectURL(link.href);
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
        }
      }
    },
    async openDatesPdfEmail (item) {
      this.itempdfemail = item
      this.inviteEmailPdfDialog = true
      this.dataspdfemail = (await ServiceInvoiceLogEmails.list(item.id)).data;
    },
    async invitePdfEmail () {
      if (this.itempdfemail.base64pdf == null || this.itempdfemail.base64pdf == '') {
        alert("Documento sem pdf")
      } else {
        const enviarpfdemail = await ServiceInvoices.invitePdfEmail(this.itempdfemail.id, this.itempdfemail.Client.email, this.itempdfemail.number_invoice);
        if (enviarpfdemail.status === 200) {
          this.dataspdfemail = (await ServiceInvoiceLogEmails.list(this.itempdfemail.id)).data;
          this.$store.dispatch("setSnackbar", {});
        } else {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
        }
      }
    },
    async invoicePay (item) {
      this.receiptinvoice = item
      this.invoicepayDialog = true
    },
    async receiptInvoice () {
      try {
          //criar recibo ggest
          if (this.$refs.form.validate()) {
            await ServiceInvoices.createReceiptInvoice(this.receiptinvoice, this.payment_method_select);
            this.invoicepayDialog = false
            this.$store.dispatch("setSnackbar", {});
            this.Filter()
          }
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    async myFn(newVal) {
      if (newVal) {
        this.loadingClients = true;
        this.clients = (await ServiceClients.listDebounce(newVal)).data;
        this.$refs.cbClient.$el.focus();
        this.loadingClients = false;
      }
    },
    async updateDashboardDateInicio(date) {
      this.$refs.toggleDataInicio.save(date);
      this.data_inicio_select = date
    },
    async updateDashboardDateFim(date) {
      this.$refs.toggleDataFim.save(date);
      this.data_fim_select = date
    },
    Filter() {
      this.users = [];
      this.invoices = [];
      this.page = 1;
      this.filterDialog = false;
      this.total_faturado = 0;
      this.getUsers();
      this.totalFaturado()
    },
    async linesInvoices (item) {
      this.invoicelinesDialog = true
      this.linesinvoices = (await ServiceInvoices.listlinesinvoices(item.id)).data;
    },
    paymentMethodDoc(value) {
      for (let paymentmethd of this.payment_methods) {
        if (paymentmethd.method_abbreviation === value) {
          return paymentmethd.text;
        }
      }
    },
    typeDoc(value) {
      for (let typedoc of this.type_documents) {
        if (typedoc.document_abbreviation === value) {
          return typedoc.text;
        }
      }
    }
  },
  async mounted() {
    this.totalFaturado()
    this.payment_methods = config.PAYMENT_METHODS;
    this.clientTypeP = config.CLIENT_TYPE_PLURAL;
    this.typeevents = (await ServiceTypeevents.list()).data;
    this.type_documents = config.TYPE_DOCUMENT;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
