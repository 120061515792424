var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","width":"1500","transition":"dialog-bottom-transition"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"basicBackGround"},[_c('v-toolbar',{attrs:{"dark":"","color":"light-blue"}},[_c('v-toolbar-title',[_vm._v("Pausas anuais para não marcar marcação")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.toggleDialog()}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Novo Registo")])])],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-dialog',{ref:"toggleDataInicio",attrs:{"return-value":_vm.data_inicio_pesquisa_select,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.data_inicio_pesquisa_select=$event},"update:return-value":function($event){_vm.data_inicio_pesquisa_select=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"clearable":"","label":"Data Inicio (desde)","prepend-icon":"mdi-clock-outline"},model:{value:(_vm.data_inicio_pesquisa_select),callback:function ($$v) {_vm.data_inicio_pesquisa_select=$$v},expression:"data_inicio_pesquisa_select"}},on))]}}]),model:{value:(_vm.toggleDataInicio),callback:function ($$v) {_vm.toggleDataInicio=$$v},expression:"toggleDataInicio"}},[_c('v-date-picker',{attrs:{"max":_vm.data_fim_pesquisa_select,"locale":"pt-pt","scrollable":""},model:{value:(_vm.data_inicio_pesquisa_select),callback:function ($$v) {_vm.data_inicio_pesquisa_select=$$v},expression:"data_inicio_pesquisa_select"}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.toggleDataInicio = false}}},[_vm._v("Cancelar")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.updateDashboardDateInicio(_vm.data_inicio_pesquisa_select)}}},[_vm._v("OK")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-dialog',{ref:"toggleDataFim",attrs:{"return-value":_vm.data_fim_pesquisa_select,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.data_fim_pesquisa_select=$event},"update:return-value":function($event){_vm.data_fim_pesquisa_select=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"clearable":"","label":"Data Fim (até)","prepend-icon":"mdi-clock-outline"},model:{value:(_vm.data_fim_pesquisa_select),callback:function ($$v) {_vm.data_fim_pesquisa_select=$$v},expression:"data_fim_pesquisa_select"}},on))]}}]),model:{value:(_vm.toggleDataFim),callback:function ($$v) {_vm.toggleDataFim=$$v},expression:"toggleDataFim"}},[_c('v-date-picker',{attrs:{"min":_vm.data_inicio_pesquisa_select,"locale":"pt-pt","scrollable":""},model:{value:(_vm.data_fim_pesquisa_select),callback:function ($$v) {_vm.data_fim_pesquisa_select=$$v},expression:"data_fim_pesquisa_select"}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.toggleDataFim = false}}},[_vm._v("Cancelar")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.updateDashboardDateFim(_vm.data_fim_pesquisa_select)}}},[_vm._v("OK")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.userbreaks.length != 0)?_c('v-data-table',{staticClass:"mTable",attrs:{"infinite-scroll-disabled":"busy","headers":_vm.headers,"items":_vm.userbreaks,"loading":_vm.loading,"hide-default-footer":"","items-per-page":_vm.userbreaks.length + 10},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.start))+" ")]}},{key:"item.starthour",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.start))+" ")]}},{key:"item.endhour",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.end))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.edittItem(item)}}},[_c('v-icon',_vm._g({attrs:{"color":"teal"}},on),[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',_vm._g({attrs:{"color":"pink"}},on),[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]}}],null,true)}):_vm._e()],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"scrollable":"","width":"600","transition":"dialog-bottom-transition"},model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Adicionar")]),_c('v-spacer')],1)],1),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.showTab)?_c('v-tabs',{attrs:{"centered":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" Normal ")]),_c('v-tab',[_vm._v(" Periódica ")]),_c('v-tab-item',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.datainicioselect,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.datainicioselect=$event},"update:return-value":function($event){_vm.datainicioselect=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data de início","prepend-icon":"mdi-clock-outline","rules":[_vm.rules.required],"readonly":""},model:{value:(_vm.datainicioselect),callback:function ($$v) {_vm.datainicioselect=$$v},expression:"datainicioselect"}},'v-text-field',attrs,false),on))]}}],null,false,1826620923),model:{value:(_vm.startdateModal),callback:function ($$v) {_vm.startdateModal=$$v},expression:"startdateModal"}},[_c('v-date-picker',{attrs:{"locale":"pt-pt","scrollable":""},model:{value:(_vm.datainicioselect),callback:function ($$v) {_vm.datainicioselect=$$v},expression:"datainicioselect"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.startdateModal = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.datainicioselect)}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1)],1),_c('v-tab-item',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.datainicioselect,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.datainicioselect=$event},"update:return-value":function($event){_vm.datainicioselect=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data de início","prepend-icon":"mdi-clock-outline","rules":[_vm.rules2.required],"readonly":""},model:{value:(_vm.datainicioselect),callback:function ($$v) {_vm.datainicioselect=$$v},expression:"datainicioselect"}},'v-text-field',attrs,false),on))]}}],null,false,3118242729),model:{value:(_vm.startdatebulkModal),callback:function ($$v) {_vm.startdatebulkModal=$$v},expression:"startdatebulkModal"}},[_c('v-date-picker',{attrs:{"max":_vm.datafimbulkselect,"locale":"pt-pt","scrollable":""},model:{value:(_vm.datainicioselect),callback:function ($$v) {_vm.datainicioselect=$$v},expression:"datainicioselect"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.startdatebulkModal = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.datainicioselect)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-dialog',{ref:"dialog2",attrs:{"return-value":_vm.datafimbulkselect,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.datafimbulkselect=$event},"update:return-value":function($event){_vm.datafimbulkselect=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data de fim","prepend-icon":"mdi-clock-outline","rules":[_vm.rules2.required],"readonly":""},model:{value:(_vm.datafimbulkselect),callback:function ($$v) {_vm.datafimbulkselect=$$v},expression:"datafimbulkselect"}},'v-text-field',attrs,false),on))]}}],null,false,2564351133),model:{value:(_vm.enddatebulkModal),callback:function ($$v) {_vm.enddatebulkModal=$$v},expression:"enddatebulkModal"}},[_c('v-date-picker',{attrs:{"min":_vm.datainicioselect,"locale":"pt-pt","scrollable":""},model:{value:(_vm.datafimbulkselect),callback:function ($$v) {_vm.datafimbulkselect=$$v},expression:"datafimbulkselect"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.enddatebulkModal = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog2.save(_vm.datafimbulkselect)}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1)],1)],1):_vm._e(),_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-dialog',{ref:"starthourModal",attrs:{"return-value":_vm.horainicioselect,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.horainicioselect=$event},"update:return-value":function($event){_vm.horainicioselect=$event},"input":function (value) { return value && _vm.$refs.picker1 && (_vm.$refs.picker1.selectingHour = true); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Hora Inicio","prepend-icon":"mdi-clock-outline","readonly":"","required":"","rules":[_vm.rules.required],"clearable":""},model:{value:(_vm.horainicioselect),callback:function ($$v) {_vm.horainicioselect=$$v},expression:"horainicioselect"}},on))]}}]),model:{value:(_vm.starthourModal),callback:function ($$v) {_vm.starthourModal=$$v},expression:"starthourModal"}},[(_vm.starthourModal)?_c('v-time-picker',{ref:"picker1",attrs:{"format":"24hr","max":_vm.horafimselect,"locale":"pt-pt"},model:{value:(_vm.horainicioselect),callback:function ($$v) {_vm.horainicioselect=$$v},expression:"horainicioselect"}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.starthourModal = false}}},[_vm._v("Cancelar")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.starthourModal.save(_vm.horainicioselect)}}},[_vm._v("OK")])],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-dialog',{ref:"endhourModal",attrs:{"return-value":_vm.horafimselect,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.horafimselect=$event},"update:return-value":function($event){_vm.horafimselect=$event},"input":function (value) { return value && _vm.$refs.picker2 && (_vm.$refs.picker2.selectingHour = true); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Hora Fim","prepend-icon":"mdi-clock-outline","readonly":"","clearable":"","required":"","rules":[_vm.rules.required]},model:{value:(_vm.horafimselect),callback:function ($$v) {_vm.horafimselect=$$v},expression:"horafimselect"}},on))]}}]),model:{value:(_vm.endhourModal),callback:function ($$v) {_vm.endhourModal=$$v},expression:"endhourModal"}},[(_vm.endhourModal)?_c('v-time-picker',{ref:"picker2",attrs:{"format":"24hr","min":_vm.horainicioselect,"locale":"pt-pt"},model:{value:(_vm.horafimselect),callback:function ($$v) {_vm.horafimselect=$$v},expression:"horafimselect"}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.endhourModal = false}}},[_vm._v("Cancelar")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.endhourModal.save(_vm.horafimselect)}}},[_vm._v("OK")])],1):_vm._e()],1)],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","align":"center","justify":"center"}},[_c('v-textarea',{attrs:{"label":"Detalhes da pausa"},model:{value:(_vm.detailsinsert),callback:function ($$v) {_vm.detailsinsert=$$v},expression:"detailsinsert"}})],1)],1),(_vm.tab === 1)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","align":"center","justify":"center"}},[_c('v-item-group',{attrs:{"multiple":"","align":"center","justify":"center"},model:{value:(_vm.selectedDays),callback:function ($$v) {_vm.selectedDays=$$v},expression:"selectedDays"}},[_c('v-subheader',[_vm._v("Repetir :")]),_c('v-item',{attrs:{"value":0},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"ma-1",attrs:{"input-value":active,"active-class":"primary white--text","depressed":"","rounded":""},on:{"click":toggle}},[_vm._v("Dom")])]}}],null,false,2824680032)}),_c('v-item',{attrs:{"value":1},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"ma-1",attrs:{"input-value":active,"active-class":"primary white--text","depressed":"","rounded":""},on:{"click":toggle}},[_vm._v("Seg")])]}}],null,false,3194965047)}),_c('v-item',{attrs:{"value":2},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"ma-1",attrs:{"input-value":active,"active-class":"primary white--text","depressed":"","rounded":""},on:{"click":toggle}},[_vm._v("Ter")])]}}],null,false,4239679717)}),_c('v-item',{attrs:{"value":3},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"ma-1",attrs:{"input-value":active,"active-class":"primary white--text","depressed":"","rounded":""},on:{"click":toggle}},[_vm._v("Qua")])]}}],null,false,3575957283)}),_c('v-item',{attrs:{"value":4},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"ma-1",attrs:{"input-value":active,"active-class":"primary white--text","depressed":"","rounded":""},on:{"click":toggle}},[_vm._v("Qui")])]}}],null,false,642087211)}),_c('v-item',{attrs:{"value":5},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"ma-1",attrs:{"input-value":active,"active-class":"primary white--text","depressed":"","rounded":""},on:{"click":toggle}},[_vm._v("Sex")])]}}],null,false,4215944296)}),_c('v-item',{attrs:{"value":6},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"ma-1",attrs:{"input-value":active,"active-class":"primary white--text","depressed":"","rounded":""},on:{"click":toggle}},[_vm._v("Sab")])]}}],null,false,3472995958)})],1)],1),(_vm.tab === 1 && _vm.selectedDays.length == 0)?_c('v-col',{attrs:{"cols":"12","align":"center","justify":"center"}},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("Tem de escolher pelo menos 1 dia.")])]):_vm._e()],1):_vm._e()],1)],1)],1),(_vm.$store.state.user.role === 'admin' || _vm.editItem.UserId === _vm.$store.state.user.id || !_vm.editItem.UserId)?_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"red","outlined":""},on:{"click":_vm.clear}},[_vm._v("Limpar")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary","outlined":""},on:{"click":_vm.submit}},[_vm._v("Submeter")])],1):_vm._e()],1)],1),_c('v-dialog',{attrs:{"scrollable":"","width":"600","transition":"dialog-bottom-transition"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Editar")]),_c('v-spacer')],1)],1),_c('v-card-text',[_c('v-form',{ref:"form2",attrs:{"lazy-validation":""},model:{value:(_vm.valid2),callback:function ($$v) {_vm.valid2=$$v},expression:"valid2"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.datainicioselect,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.datainicioselect=$event},"update:return-value":function($event){_vm.datainicioselect=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data de início","prepend-icon":"mdi-clock-outline","rules":[_vm.rules.required],"readonly":""},model:{value:(_vm.datainicioselect),callback:function ($$v) {_vm.datainicioselect=$$v},expression:"datainicioselect"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startdateModal),callback:function ($$v) {_vm.startdateModal=$$v},expression:"startdateModal"}},[_c('v-date-picker',{attrs:{"locale":"pt-pt","scrollable":""},model:{value:(_vm.datainicioselect),callback:function ($$v) {_vm.datainicioselect=$$v},expression:"datainicioselect"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.startdateModal = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.datainicioselect)}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-dialog',{ref:"starthourModal",attrs:{"return-value":_vm.horainicioselect,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.horainicioselect=$event},"update:return-value":function($event){_vm.horainicioselect=$event},"input":function (value) { return value && _vm.$refs.picker1 && (_vm.$refs.picker1.selectingHour = true); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Hora Inicio","prepend-icon":"mdi-clock-outline","readonly":"","required":"","rules":[_vm.rules.required],"clearable":""},model:{value:(_vm.horainicioselect),callback:function ($$v) {_vm.horainicioselect=$$v},expression:"horainicioselect"}},on))]}}]),model:{value:(_vm.starthourModal),callback:function ($$v) {_vm.starthourModal=$$v},expression:"starthourModal"}},[(_vm.starthourModal)?_c('v-time-picker',{ref:"picker1",attrs:{"format":"24hr","max":_vm.horafimselect,"locale":"pt-pt"},model:{value:(_vm.horainicioselect),callback:function ($$v) {_vm.horainicioselect=$$v},expression:"horainicioselect"}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.starthourModal = false}}},[_vm._v("Cancelar")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.starthourModal.save(_vm.horainicioselect)}}},[_vm._v("OK")])],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-dialog',{ref:"endhourModal",attrs:{"return-value":_vm.horafimselect,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.horafimselect=$event},"update:return-value":function($event){_vm.horafimselect=$event},"input":function (value) { return value && _vm.$refs.picker2 && (_vm.$refs.picker2.selectingHour = true); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Hora Fim","prepend-icon":"mdi-clock-outline","readonly":"","clearable":"","required":"","rules":[_vm.rules.required]},model:{value:(_vm.horafimselect),callback:function ($$v) {_vm.horafimselect=$$v},expression:"horafimselect"}},on))]}}]),model:{value:(_vm.endhourModal),callback:function ($$v) {_vm.endhourModal=$$v},expression:"endhourModal"}},[(_vm.endhourModal)?_c('v-time-picker',{ref:"picker2",attrs:{"format":"24hr","min":_vm.horainicioselect,"locale":"pt-pt"},model:{value:(_vm.horafimselect),callback:function ($$v) {_vm.horafimselect=$$v},expression:"horafimselect"}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.endhourModal = false}}},[_vm._v("Cancelar")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.endhourModal.save(_vm.horafimselect)}}},[_vm._v("OK")])],1):_vm._e()],1)],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","align":"center","justify":"center"}},[_c('v-textarea',{attrs:{"label":"Detalhes da pausa"},model:{value:(_vm.detailsinsert),callback:function ($$v) {_vm.detailsinsert=$$v},expression:"detailsinsert"}})],1)],1)],1)],1)],1),(_vm.$store.state.user.role === 'admin' || _vm.editItem.UserId === _vm.$store.state.user.id || !_vm.editItem.UserId)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary","outlined":""},on:{"click":_vm.submitEditItem}},[_vm._v("Submeter")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }