import Api from "@/services/ServiceBase";
const path = "userbreaks";

export default {
  create(item) {
    return Api().post(path, item);
  },
  createbulk(item) {
    return Api().post(path + "/bulk", item);
  },
  update(userbreak) {
    return Api().put(path + `/${userbreak.id}`, userbreak);
  },
  list(iduser, data_inicio, data_fim) {
    return Api().get(path, {
      params: {
        iduser,
        data_inicio,
        data_fim,
      },
    });
  },
  show(id) {
    return Api().get(path + `/${id}`);
  },
  delete(userbreakId) {
    return Api().get(path + `/delete/${userbreakId}`);
  },
};
