<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" md="10">
        <router-link style="text-decoration: none;" :to="{ name: 'dashevents' }">
          <span style="color: #1565C0; cursor: pointer;">Número de marcações</span>
        </router-link>
        <router-link style="text-decoration: none;" :to="{ name: 'dashemployeebilling' }" class="ml-5">
          <span style="color: #1565C0; cursor: pointer;">Faturação funcionários</span>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
export default {
  name: "Dashboard",
  data() {
    return {

    }
  },
  async mounted() {
  },
  methods: {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
