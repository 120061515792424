// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import { router } from './router'
import vuetify from './plugins/vuetify'
import { sync } from 'vuex-router-sync'
import store from '@/store/store'
import * as ModalDialogs from 'vue-modal-dialogs'
import VueObserveVisibility from 'vue-observe-visibility'
import LightTimeline from 'vue-light-timeline';
import './assets/css/global.css'
import vueDebounce from 'vue-debounce'
import VueChartkick from 'vue-chartkick';
import Chart from 'chart.js';

import './registerServiceWorker'


Vue.use(vueDebounce, {
  defaultTime: '500ms'
})

Vue.config.productionTip = false
// Vue.use(Vuetify);

Vue.use(ModalDialogs);
Vue.use(VueObserveVisibility);
Vue.use(LightTimeline);
Vue.use(VueChartkick, { adapter: Chart });
sync(store, router);

Vue.prototype.$config = config;

// Vue.component('user-tab', UserTab)

/* eslint-disable no-new */
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  template: '<App/>'
}).$mount('#app')
