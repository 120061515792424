<template>
  <v-dialog v-model="show" scrollable width="1500" transition="dialog-bottom-transition">
    <v-card class="basicBackGround">
      <v-toolbar dark color="light-blue">
        <v-toolbar-title>Ciclos da avença</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" outlined @click="addCycles">Adicionar ciclos</v-btn>
          </v-card-actions>
          <v-card class="elevation-0" color="transparent" v-if="covenantscycles.length != 0">
            <v-list color="transparent">
              <v-list-item-group>
                <v-list-item>
                  <v-list-item-content>
                    <v-data-table infinite-scroll-disabled="busy"
                      :headers="headers" :items="covenantscycles" :loading="loading" hide-default-footer
                      :items-per-page="covenantscycles.length + 10" class="mTable">
                      <template v-slot:[`item.price`]="{ item }">
                        {{ item.price }} €
                      </template>
                      <template v-slot:[`item.start_date`]="{ item }">
                        {{ formatDate(item.start_date) }}
                      </template>

                      <template v-slot:[`item.end_date`]="{ item }">
                        <span>{{ formatDate(item.end_date) }}</span>
                      </template>

                      <template v-slot:[`item.number_events`]="{ item }">
                        <span>{{ item.ClientEvents.length }} / {{ item.number_events }}</span>
                      </template>

                      <template v-slot:[`item.number_replacement_event`]="{ item }">
                        <span>{{numeroDeReplacementEvents(item)}} / {{ item.number_replacement_event }}</span>
                      </template>

                      <template v-slot:[`item.discontinued`]="{ item }">
                        <v-icon v-if="item.discontinued" color="red darken-2">mdi-close</v-icon>
                        <v-icon v-else color="green darken-2">mdi-check</v-icon>
                      </template>

                      <template v-slot:[`item.InvoiceId`]="{ item }">
                        <v-icon v-if="item.InvoiceId != null" color="green">mdi-cash</v-icon>
                      </template>

                      <template v-slot:[`item.action`]="{ item }">
                        <v-tooltip top v-if="item.InvoiceId == null">
                          <template v-slot:activator="{ on }">
                            <v-btn icon class="mx-0" @click="edittItem(item)">
                              <v-icon v-on="on" color="teal">mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Editar</span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.InvoiceId == null && item.ClientEvents.length == 0">
                          <template v-slot:activator="{ on }">
                            <v-btn icon class="mx-0" @click="deleteItem(item)">
                              <v-icon v-on="on" color="pink">mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Eliminar</span>
                        </v-tooltip>

                      </template>

                    </v-data-table>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
          <v-card v-else class="elevation-0" align="center" justify="center" color="transparent">
            <v-icon size="200" color="red">
              mdi-file
            </v-icon>
            <v-card-title class="justify-center">Não há ciclos da avença a apresentar.</v-card-title>
          </v-card>
      </v-card-text>
    </v-card>

    <v-dialog v-model="editDialog" presistent scrollable width="600" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Editar</v-toolbar-title>
            <v-spacer />
            <v-btn icon dark @click="editDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-form ref="form2" v-model="valid2" lazy-validation>
            <v-container>
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-checkbox v-model="editItem.discontinued" label="Colocar ciclo inativo"></v-checkbox>
                  <span>Não aparece para faturar</span>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-text-field type="number" v-model="editItem.price" label="Preço (€)" :rules="[rules.required]"></v-text-field>
                </v-col>
              </v-row>
              <!--
              <v-row align="center" justify="center" v-if="covenant.type_covenant != 'mensal'">
                <v-col cols="12">
                  <v-text-field type="number" v-model="editItem.number_events" label="Número de marcações"></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" v-if="covenant.type_covenant != 'mensal'">
                <v-col cols="12">
                  <v-text-field type="number" v-model="editItem.number_replacement_event" label="Número de reposições"></v-text-field>
                </v-col>
              </v-row>
              -->
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions v-if="$store.state.user.role === 'admin' || editItem.UserId === $store.state.user.id || !editItem.UserId">
          <v-spacer></v-spacer>
          <v-btn text color="primary" outlined @click="submitEditItem">Submeter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addCyclesDialog" presistent scrollable width="600" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Adicionar ciclos</v-toolbar-title>
            <v-spacer />
            <v-btn icon dark @click="addCyclesDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-form ref="form3" v-model="valid3" lazy-validation>
            <v-container>
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-text-field type="number" v-model="addCycle.price" label="Preço (€)" :rules="[rules.required]"></v-text-field>
                </v-col>
              </v-row>

              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-dialog ref="dialog" v-model="startdateModal" :return-value.sync="addCycle.start_date" persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="addCycle.start_date" label="Data de início" :rules="[rules.required]" readonly v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker :max="addCycle.end_date" locale="pt-pt" v-model="addCycle.start_date" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="startdateModal = false">
                        Cancelar
                      </v-btn>
                      <v-btn text color="primary" @click="$refs.dialog.save(addCycle.start_date)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>

                </v-col>
              </v-row>

              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-dialog ref="dialog2" v-model="enddateModal" :return-value.sync="addCycle.end_date" persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="addCycle.end_date" label="Data de fim" :rules="[rules.required]" readonly v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker :min="addCycle.start_date" locale="pt-pt" v-model="addCycle.end_date" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="enddateModal = false">
                        Cancelar
                      </v-btn>
                      <v-btn text color="primary" @click="$refs.dialog2.save(addCycle.end_date)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>

              <v-row align="center" justify="center" v-if="covenant.type_covenant == 'mensal'">
                <v-col cols="12" align="center" justify="center">
                  <v-item-group multiple v-model="selectedDays" align="center" justify="center">
                    <v-subheader>Repetir :</v-subheader>
                    <v-item :value="0" v-slot="{ active, toggle }">
                      <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                        @click="toggle">Dom</v-btn>
                    </v-item>

                    <v-item :value="1" v-slot="{ active, toggle }">
                      <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                        @click="toggle">Seg</v-btn>
                    </v-item>

                    <v-item :value="2" v-slot="{ active, toggle }">
                      <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                        @click="toggle">Ter</v-btn>
                    </v-item>

                    <v-item :value="3" v-slot="{ active, toggle }">
                      <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                        @click="toggle">Qua</v-btn>
                    </v-item>

                    <v-item :value="4" v-slot="{ active, toggle }">
                      <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                        @click="toggle">Qui</v-btn>
                    </v-item>

                    <v-item :value="5" v-slot="{ active, toggle }">
                      <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                        @click="toggle">Sex</v-btn>
                    </v-item>

                    <v-item :value="6" v-slot="{ active, toggle }">
                      <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                        @click="toggle">Sab</v-btn>
                    </v-item>
                  </v-item-group>
                </v-col>
                <v-col cols="12" align="center" justify="center" v-if="selectedDays.length == 0 && covenant.type_covenant == 'mensal'">
                  <span style="color: red;">Tem de escolher pelo menos 1 dia.</span>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions v-if="$store.state.user.role === 'admin' || editItem.UserId === $store.state.user.id || !editItem.UserId">
          <v-spacer></v-spacer>
          <v-btn text color="primary" outlined @click="submitAddCycles">Submeter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import ServiceCovenantscycles from "@/services/ServiceCovenantscycles";
import ServiceTypeevents from "@/services/ServiceTypeevents";
import ServicePlantypeevents from "@/services/ServicePlantypeevents";
import ServiceClienttypeevents from "@/services/ServiceClienttypeevents";
import ServiceClientPlanstypeevents from "@/services/ServiceClientPlanstypeevents";
export default {
  name: "ClientCovenantscycles",
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_URL,
      show: false,
      loading: false,
      client: {},
      editDialog: false,
      addCyclesDialog: false,
      covenant: {},
      covenantscycles: [],
      selectedDays: [],
      valid2: true,
      valid3: true,
      enddateModal: false,
      startdateModal: false,
      editItem: {},
      addCycle: {
        price: '',
        numberreplacementevent: 0,
      },
      headers: [
        { text: "Número", sortable: false, value: "number" },
        { text: "Data ínicio", sortable: false, value: "start_date" },
        { text: "Data fim", sortable: false, value: "end_date" },
        { text: "Quantidade", sortable: false, value: "quantity" },
        { text: "Preço (€)", sortable: false, value: "price" },
        { text: "Número de marcações", sortable: false, value: "number_events" },
        { text: "Número de reposições", sortable: false, value: "number_replacement_event" },
        { text: "Activo", sortable: false, value: "discontinued" },
        { text: "Faturado", sortable: false, value: "InvoiceId" },
        { text: "Ações", value: "action", sortable: false, align: "center" }
      ],
      rules: {
        required: value => !!value || "Campo Obrigatório.",
        counter: value =>
          (value && value.length <= 250) ||
          "Campo tem de ter menos de 250 caracteres",
        isValidNumber: value =>
          /^([0-9]+[.])?[0-9]+$/.test(value) || "Valor inválido"
      }
    };
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
    close() {
      this.show = false;
    },
    async open(item) {
      this.loading = true;
      this.client = item.ClientId;
      this.covenant = item
      this.covenantscycles = (await ServiceCovenantscycles.getClientCovenantsCycles(this.covenant.id)).data;
      this.show = true;
      this.loading = false;
    },
    async deleteItem(item) {
      if (confirm("Confirma a eliminação deste elemento?")) {
        try {
          await ServiceCovenantscycles.deleteCovenantsCycles(item.id);
          this.$store.dispatch("setSnackbar", {});
          this.covenantscycles = (await ServiceCovenantscycles.getClientCovenantsCycles(this.covenant.id)).data;
          this.$emit('handleCovenant');
          this.$emit('handleRemoverClientes2');
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
        }
      }
    },
    async edittItem (item) {
      this.editItem = item
      this.editDialog = true
    },
    async submitEditItem () {
      try {
        if (this.$refs.form2.validate()) {
          await ServiceCovenantscycles.updateClientCovenantsCycles(this.editItem);
          this.covenantscycles = (await ServiceCovenantscycles.getClientCovenantsCycles(this.covenant.id)).data;
          this.$store.dispatch("setSnackbar", {});
          this.editDialog = false
          this.$emit('handleEditCovenantCycle');
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    async addCycles () {
      this.addCyclesDialog = true

      if (this.covenant.PlanId == null) {
        let typeseventclient = (await ServiceClienttypeevents.tiposservicocliente(this.client, this.covenant.TypeeventId)).data;
        let preco = null
        if (typeseventclient[0]) {
          preco = typeseventclient[0].price;
          if (preco == 0) {
              let typeevent = (await ServiceTypeevents.show(this.covenant.TypeeventId)).data;
              preco = typeevent.price
          }
        }
        this.addCycle.price = preco
      } else {
        const clienttypeeventplans = (await ServiceClientPlanstypeevents.planostiposservicocliente(this.client, this.covenant.TypeeventId, this.covenant.PlanId)).data;
        let preco = null
        if (clienttypeeventplans[0]) {
          preco = clienttypeeventplans[0].price;
          if (preco == 0) {
              const plan = (await ServicePlantypeevents.getTypeeventPlans(this.covenant.PlanId, this.covenant.TypeeventId)).data
              preco = plan[0].price
          }
        }
        this.addCycle.price = preco
        this.addCycle.numberreplacementevent = plan[0].number_replacement_event
      }

    },
    async submitAddCycles () {
      try {
        let hasError = false;
        if (this.selectedDays.length == 0 && this.covenant.type_covenant == 'mensal') {
          hasError = true;
        }
        if (this.$refs.form3.validate() && hasError == false) {
          this.addCycle.daysOfWeek = this.selectedDays
          this.addCycle.covenantId = this.covenant.id
          this.addCycle.type_covenant = this.covenant.type_covenant
          await ServiceCovenantscycles.createCovenantsCycles(this.addCycle);
          this.$store.dispatch("setSnackbar", {});
          this.clearAddCycle()
          this.covenantscycles = (await ServiceCovenantscycles.getClientCovenantsCycles(this.covenant.id)).data;
          this.addCyclesDialog = false

          this.$emit('handleCovenant');
          this.$emit('handleRemoverClientes2');
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    clearAddCycle () {
      this.addCycle = {
        price: '',
        numberreplacementevent: 0,
        type_covenant: '',
        end_date: null,
        start_date: null,
      },
      this.selectedDays = []
    },
    numeroDeReplacementEvents(item) {
        const eventosValidos = item.ClientEvents.filter(valor => valor.replacement_EventId !== null);
        return eventosValidos.length;
    }
  },
  computed: {
  },
  async mounted() {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
