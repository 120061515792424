<template>
  <v-container>
    <v-speed-dial fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false">
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark large fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-filter-variant</v-icon>
        </v-btn>
      </template>
      <v-btn fab dark large color="green" @click.stop="filterDialog = true">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-btn fab dark large color="indigo" @click="addEditItem()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-speed-dial>

    <v-row align="center" justify="center">
      <v-col cols="12" md="10">
        <v-data-table class="mTable" id="mytable" v-infinite-scroll="getUsers" infinite-scroll-disabled="busy"
          infinite-scroll-distance="10" :headers="headers" :items="users" :loading="loading" hide-default-footer
          :items-per-page="users.length + 10">
          <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>

          <template v-slot:[`item.color`]="{ item }">

            <v-chip class="pa-5" :color="item.color" />
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <td class="justify-center layout px-0">

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="addEditItem(item)">
                    <v-icon v-on="on" color="teal">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="deleteItem(item)">
                    <v-icon v-on="on" color="pink">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Eliminar</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="openUserBreak(item)">
                    <v-icon v-on="on" color="orange">mdi-calendar-alert</v-icon>
                  </v-btn>
                </template>
                <span>Pausas anuais para não marcar marcação</span>
              </v-tooltip>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="editDialog" ref="test" persistent width="600" scrollable transition="dialog-bottom-transition">
      <add-edit-user ref="qwerty" :swatches="swatches" :user="editedItem" @submited="submitedData"
        @close="close"></add-edit-user>
    </v-dialog>

    <userbreaks ref="userbreaks" />

    <v-dialog v-model="filterDialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Filtros :</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="filterDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field label="Login" clearable v-model="login"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Nome" clearable v-model="userName"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-radio-group v-model="discontinued" column>
                  <v-radio v-for="item in items" :key="item.value" :label="item.text" :value="item.value"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="blue darken-1" text @click="Filter()">Procurar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import infiniteScroll from "vue-infinite-scroll";
import ServiceUsers from "@/services/ServiceUsers";
import AddEditUser from "@/components/user/AddEditUser.vue";
import Userbreaks from "@/components/userbreak/Userbreaks.vue";
import _ from "lodash";
export default {
  name: "List-Users",
  directives: {
    infiniteScroll
  },
  components: {
    AddEditUser,
    Userbreaks
  },
  data() {
    return {
      editDialog: false,
      editedIndex: -1,
      editedItem: {
      },
      defaultItem: {
      },

      value: "",
      fileName: "",
      filterDialog: false,
      fab: false,
      page: 1,
      headers: [
        { text: "Login", value: "userName", sortable: false },
        { text: "Nome", value: "name", sortable: false },
        { text: "Côr", value: "color", sortable: false },
        { text: "", value: "action", sortable: false, align: "center" }
      ],
      users: [],
      swatches: [],
      test: [],
      busy: false,
      loading: true,
      userName: null,
      login: null,
      externalID: null,
      discontinued: 2,
      items: [
        { text: "Ver todos", value: 2 },
        { text: "Ver apenas activos", value: 0 },
        { text: "Ver apenas descontinuados", value: 1 }
      ]
    };
  },
  methods: {
    async getUsers() {
      this.busy = true;
      this.loading = true;
      const response = await ServiceUsers.list(
        this.userName,
        this.discontinued,
        this.page,
        this.login,
        this.externalID
      );
      response.data.forEach(element => {
        this.users.push(element);
      });
      this.page++;
      this.busy = false;
      if (response.data.length == 0) {
        this.busy = true;
      }
      this.loading = false;
    },
    async deleteItem(item) {
      if (confirm("Confirma a eliminação deste elemento?")) {
        try {
          const index = this.users.indexOf(item);
          await ServiceUsers.delete(item);
          this.users.splice(index, 1);
          this.$store.dispatch("setSnackbar", {});
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
        }
      }
    },
    async addEditItem(item) {
      try {


        const usedColors = (await ServiceUsers.getUsedColors()).data;

        const fullSwatches = [
          ['#696969', '#556B2F', '#7F0000', '#483D8B', '#008000', '#3CB371'],
          ['#008B8B', '#000080', '#D2691E', '#800080', '#FF0000', '#FFA500'],
          ['#00FF00', '#8A2BE2', '#00FF7F', '#E9967A', '#DC143C', '#00FFFF'],
          ['#00BFFF', '#0000FF', '#ADFF2F', '#DA70D6', '#FF00FF', '#1E90FF'],
          ['#DB7093', '#F0E68C', '#FFFF54', '#ADD8E6', '#FF1493', '#7B68EE']
        ];

        for (let i = 0; i < usedColors.length; i++) {
          const usedColor = usedColors[i].color;
          for (let j = 0; j < fullSwatches.length; j++) {

            if (item) {
              if (item.color == usedColor) {
                continue;
              }
            }
            const index = _.findIndex(fullSwatches[j], function (o) {
              return o == usedColor;
            });
            if (index != -1) {
              fullSwatches[j].splice(index, 1);
              break;
            }
          }
        }

        this.swatches = [];
        this.swatches = this.swatches.concat(fullSwatches);

        if (item) {
          this.editedIndex = this.users.indexOf(item);
          this.editedItem = Object.assign({}, item);
        } else {
          this.editedIndex = -1;
          this.editedItem = Object.assign({}, this.defaultItem);
          if (this.swatches[0][0]) {
            this.editedItem.color = this.swatches[0][0];
          }
        }

        this.editDialog = true;
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    submitedData(item) {
      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], item);
      } else {
        this.users.unshift(item);
      }

      for (let i = 0; i < this.swatches.length; i++) {

        const index = _.findIndex(this.swatches[i], function (o) {
          return o == item.color;
        });

        if (index != -1) {
          this.swatches[i].splice(index, 1);
          break;
        }
      }
    },
    openUserBreak(item) {
      this.$refs.userbreaks.open(item);
    },
    Filter() {
      this.users = [];
      this.page = 1;
      this.filterDialog = false;
      this.getUsers();
    },
    close() {
      this.editDialog = false;
    },
  },
  async mounted() { },
  created() {
    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {

      if (this.$refs.userbreaks) {
        if (this.$refs.userbreaks.$refs.eventDialog) {
          if (this.$refs.userbreaks.$refs.eventDialog.dialog) {
            if (this.$refs.userbreaks.$refs.eventDialog.navGuards()) {
              next(false);
              return;
            }
          }
        }

        if (this.$refs.userbreaks.show) {
          this.$refs.userbreaks.show = false;
          next(false);
          return;
        }
      }

      if (this.editDialog) {
        this.editDialog = false;
        next(false);
        return;
      }

      if (this.filterDialog) {
        this.filterDialog = false;
        next(false);
        return;
      }

      next();
    });

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard()
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
