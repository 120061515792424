import Api from '@/services/ServiceBase'
const path = 'clientregulations'

export default {
  regulamentoscliente (idregulation, idcliente) {
    return Api().get(path + '/regulamentoscliente',{
      params: {
        idcliente,
        idregulation
      }
    })
  },
  addupdate(idregulation, idcliente) {
    return Api().post(path, {
      params: {
        idcliente,
        idregulation
      }
    })
  },
  enviarregulamentoindividual (idregulation, arrayclientes) {
    return Api().post(path + '/enviarregulamentoindividual', {
      params: {
        arrayclientes,
        idregulation
      }
    })
  }
}
