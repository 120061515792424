<template>
  <v-dialog v-model="show" scrollable width="1500" transition="dialog-bottom-transition">
    <v-card class="basicBackGround">
      <v-toolbar dark color="light-blue">
        <v-toolbar-title>Pausas anuais para não marcar marcação</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon @click="toggleDialog()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Novo Registo</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="2">
              <v-dialog ref="toggleDataInicio" v-model="toggleDataInicio" :return-value.sync="data_inicio_pesquisa_select"
                persistent width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field clearable v-model="data_inicio_pesquisa_select" label="Data Inicio (desde)"
                    prepend-icon="mdi-clock-outline" v-on="on"></v-text-field>
                </template>
                <v-date-picker :max="data_fim_pesquisa_select" locale="pt-pt" v-model="data_inicio_pesquisa_select" scrollable>
                  <v-btn text color="primary" @click="toggleDataInicio = false">Cancelar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="updateDashboardDateInicio(data_inicio_pesquisa_select)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="2">
              <v-dialog ref="toggleDataFim" v-model="toggleDataFim" :return-value.sync="data_fim_pesquisa_select"
                persistent width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field clearable v-model="data_fim_pesquisa_select" label="Data Fim (até)"
                    prepend-icon="mdi-clock-outline" v-on="on"></v-text-field>
                </template>
                <v-date-picker :min="data_inicio_pesquisa_select" locale="pt-pt" v-model="data_fim_pesquisa_select" scrollable>
                  <v-btn text color="primary" @click="toggleDataFim = false">Cancelar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="updateDashboardDateFim(data_fim_pesquisa_select)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12">
              <v-data-table infinite-scroll-disabled="busy"
                :headers="headers" :items="userbreaks" :loading="loading" hide-default-footer
                :items-per-page="userbreaks.length + 10" class="mTable" v-if="userbreaks.length != 0">
                <template v-slot:[`item.date`]="{ item }">
                  {{ formatDate(item.start) }}
                </template>
                <template v-slot:[`item.starthour`]="{ item }">
                  {{ formatTime(item.start) }}
                </template>
                <template v-slot:[`item.endhour`]="{ item }">
                  {{ formatTime(item.end) }}
                </template>
                <template v-slot:[`item.action`]="{ item }">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn icon class="mx-0" @click="edittItem(item)">
                            <v-icon v-on="on" color="teal">mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Editar</span>
                      </v-tooltip>

                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn icon class="mx-0" @click="deleteItem(item)">
                            <v-icon v-on="on" color="pink">mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Eliminar</span>
                      </v-tooltip>
                    </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-dialog v-model="createDialog" scrollable width="600" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Adicionar</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-tabs v-if="showTab" v-model="tab" centered>
              <v-tab>
                Normal
              </v-tab>
              <v-tab>
                Periódica
              </v-tab>
              <v-tab-item>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-dialog ref="dialog" v-model="startdateModal" :return-value.sync="datainicioselect" persistent width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="datainicioselect" label="Data de início" prepend-icon="mdi-clock-outline" :rules="[rules.required]" readonly v-bind="attrs"
                            v-on="on"></v-text-field>
                        </template>
                        <v-date-picker locale="pt-pt" v-model="datainicioselect" scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="startdateModal = false">
                            Cancelar
                          </v-btn>
                          <v-btn text color="primary" @click="$refs.dialog.save(datainicioselect)">
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-dialog ref="dialog" v-model="startdatebulkModal" :return-value.sync="datainicioselect" persistent width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="datainicioselect" label="Data de início" prepend-icon="mdi-clock-outline" :rules="[rules2.required]" readonly v-bind="attrs"
                            v-on="on"></v-text-field>
                        </template>
                        <v-date-picker :max="datafimbulkselect" locale="pt-pt" v-model="datainicioselect" scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="startdatebulkModal = false">
                            Cancelar
                          </v-btn>
                          <v-btn text color="primary" @click="$refs.dialog.save(datainicioselect)">
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-dialog ref="dialog2" v-model="enddatebulkModal" :return-value.sync="datafimbulkselect" persistent width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="datafimbulkselect" label="Data de fim" prepend-icon="mdi-clock-outline" :rules="[rules2.required]" readonly v-bind="attrs"
                            v-on="on"></v-text-field>
                        </template>
                        <v-date-picker :min="datainicioselect" locale="pt-pt" v-model="datafimbulkselect" scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="enddatebulkModal = false">
                            Cancelar
                          </v-btn>
                          <v-btn text color="primary" @click="$refs.dialog2.save(datafimbulkselect)">
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs>
            <v-container>
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <v-dialog ref="starthourModal" v-model="starthourModal"
                    :return-value.sync="horainicioselect" persistent width="290px"
                    @input="value => value && $refs.picker1 && ($refs.picker1.selectingHour = true)">
                    <template v-slot:activator="{ on }">
                      <v-text-field v-model="horainicioselect" label="Hora Inicio"
                        prepend-icon="mdi-clock-outline" readonly required :rules="[rules.required]" clearable
                        v-on="on"></v-text-field>
                    </template>
                    <v-time-picker v-if="starthourModal" format="24hr" :max="horafimselect" locale="pt-pt"
                      v-model="horainicioselect" ref="picker1">
                      <v-btn text color="primary" @click="starthourModal = false">Cancelar</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary"
                        @click="$refs.starthourModal.save(horainicioselect)">OK</v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" md="6">
                  <v-dialog ref="endhourModal" v-model="endhourModal" :return-value.sync="horafimselect"
                    persistent width="290px"
                    @input="value => value && $refs.picker2 && ($refs.picker2.selectingHour = true)">
                    <template v-slot:activator="{ on }">
                      <v-text-field v-model="horafimselect" label="Hora Fim"
                        prepend-icon="mdi-clock-outline" readonly clearable required :rules="[rules.required]"
                        v-on="on"></v-text-field>
                    </template>
                    <v-time-picker v-if="endhourModal" format="24hr" :min="horainicioselect" locale="pt-pt"
                      v-model="horafimselect" ref="picker2">
                      <v-btn text color="primary" @click="endhourModal = false">Cancelar</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="$refs.endhourModal.save(horafimselect)">OK</v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
              </v-row>

              <v-row align="center" justify="center">
                <v-col cols="12" align="center" justify="center">
                  <v-textarea label="Detalhes da pausa" v-model="detailsinsert"></v-textarea>
                </v-col>
              </v-row>

              <v-row align="center" justify="center" v-if="tab === 1">
                <v-col cols="12" align="center" justify="center">
                  <v-item-group multiple v-model="selectedDays" align="center" justify="center">
                    <v-subheader>Repetir :</v-subheader>
                    <v-item :value="0" v-slot="{ active, toggle }">
                      <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                        @click="toggle">Dom</v-btn>
                    </v-item>

                    <v-item :value="1" v-slot="{ active, toggle }">
                      <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                        @click="toggle">Seg</v-btn>
                    </v-item>

                    <v-item :value="2" v-slot="{ active, toggle }">
                      <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                        @click="toggle">Ter</v-btn>
                    </v-item>

                    <v-item :value="3" v-slot="{ active, toggle }">
                      <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                        @click="toggle">Qua</v-btn>
                    </v-item>

                    <v-item :value="4" v-slot="{ active, toggle }">
                      <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                        @click="toggle">Qui</v-btn>
                    </v-item>

                    <v-item :value="5" v-slot="{ active, toggle }">
                      <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                        @click="toggle">Sex</v-btn>
                    </v-item>

                    <v-item :value="6" v-slot="{ active, toggle }">
                      <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                        @click="toggle">Sab</v-btn>
                    </v-item>
                  </v-item-group>
                </v-col>
                <v-col cols="12" align="center" justify="center" v-if="tab === 1 && selectedDays.length == 0">
                  <span style="color: red;">Tem de escolher pelo menos 1 dia.</span>
                </v-col>
              </v-row>

            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions v-if="$store.state.user.role === 'admin' || editItem.UserId === $store.state.user.id || !editItem.UserId">
          <v-btn text color="red" outlined @click="clear">Limpar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="primary" outlined @click="submit">Submeter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialog" scrollable width="600" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Editar</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-form ref="form2" v-model="valid2" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-dialog ref="dialog" v-model="startdateModal" :return-value.sync="datainicioselect" persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="datainicioselect" label="Data de início" prepend-icon="mdi-clock-outline" :rules="[rules.required]" readonly v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker locale="pt-pt" v-model="datainicioselect" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="startdateModal = false">
                        Cancelar
                      </v-btn>
                      <v-btn text color="primary" @click="$refs.dialog.save(datainicioselect)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-dialog ref="starthourModal" v-model="starthourModal"
                    :return-value.sync="horainicioselect" persistent width="290px"
                    @input="value => value && $refs.picker1 && ($refs.picker1.selectingHour = true)">
                    <template v-slot:activator="{ on }">
                      <v-text-field v-model="horainicioselect" label="Hora Inicio"
                        prepend-icon="mdi-clock-outline" readonly required :rules="[rules.required]" clearable
                        v-on="on"></v-text-field>
                    </template>
                    <v-time-picker v-if="starthourModal" format="24hr" :max="horafimselect" locale="pt-pt"
                      v-model="horainicioselect" ref="picker1">
                      <v-btn text color="primary" @click="starthourModal = false">Cancelar</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary"
                        @click="$refs.starthourModal.save(horainicioselect)">OK</v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" md="6">
                  <v-dialog ref="endhourModal" v-model="endhourModal" :return-value.sync="horafimselect"
                    persistent width="290px"
                    @input="value => value && $refs.picker2 && ($refs.picker2.selectingHour = true)">
                    <template v-slot:activator="{ on }">
                      <v-text-field v-model="horafimselect" label="Hora Fim"
                        prepend-icon="mdi-clock-outline" readonly clearable required :rules="[rules.required]"
                        v-on="on"></v-text-field>
                    </template>
                    <v-time-picker v-if="endhourModal" format="24hr" :min="horainicioselect" locale="pt-pt"
                      v-model="horafimselect" ref="picker2">
                      <v-btn text color="primary" @click="endhourModal = false">Cancelar</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="$refs.endhourModal.save(horafimselect)">OK</v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col cols="12" align="center" justify="center">
                  <v-textarea label="Detalhes da pausa" v-model="detailsinsert"></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions v-if="$store.state.user.role === 'admin' || editItem.UserId === $store.state.user.id || !editItem.UserId">
          <v-spacer></v-spacer>
          <v-btn text color="primary" outlined @click="submitEditItem">Submeter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import ServiceUserbreaks from "@/services/ServiceUserbreaks";
export default {
  name: "PlanTypeevents",
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_URL,
      show: false,
      loading: false,
      selectedDays: [],
      userbreaks: [],
      user: {},

      tab: 0,
      showTab: false,
      // pesquisas
      toggleDataInicio: false,
      toggleDataFim: false,
      data_inicio_pesquisa_select: new Date().toISOString().split('T')[0],
      data_fim_pesquisa_select: new Date().toISOString().split('T')[0],


      startdateModal: false,
      enddatebulkModal: false,
      startdatebulkModal: false,
      starthourModal: false,
      endhourModal: false,

      datainicioselect: null,
      datafimbulkselect: null,
      horainicioselect: null,
      horafimselect: null,
      detailsinsert: '',

      createDialog: false,
      editDialog: false,
      valid: true,
      valid2: true,
      editItem: {},
      headers: [
        { text: "Data", sortable: false, value: "date" },
        { text: "Hora Início", sortable: false, value: "starthour" },
        { text: "Hora Fim", sortable: false, value: "endhour" },
        { text: "Detalhes", sortable: false, value: "details" },
        { text: "Ações", value: "action", sortable: false, align: "center" }
      ],
      rules: {
        required: value => !!value || "Campo Obrigatório.",
        counter: value =>
          (value && value.length <= 250) ||
          "Campo tem de ter menos de 250 caracteres",
        isValidNumber: value =>
          /^([0-9]+[.])?[0-9]+$/.test(value) || "Valor inválido"
      },
      rules2: {
        required: value => {
          let result = true;
          if (this.tab === 1) {
            if (!value) {
              result = "Campo Obrigatório.";
            }
          } else {
            result = true;
          }
          return result;
        }
      },
    };
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
    formatDate2(dateString) {
      const date = new Date(dateString);

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${year}-${month}-${day}`;
    },
    formatTime(dateString) {
      const date = new Date(dateString);

      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      return `${hours}:${minutes}`;
    },
    close() {
      this.show = false;
    },
    async open(user) {
      this.loading = true;
      this.user = user;
      this.userbreaks = (await ServiceUserbreaks.list(this.user.id, this.data_inicio_pesquisa_select, this.data_fim_pesquisa_select)).data;
      this.show = true;
      this.loading = false;
    },
    async toggleDialog () {
      this.clear()
      this.createDialog = true
      this.tab = 0
      this.showTab = true
    },
    async submit() {
      try {
        let hasError = false;
        if (this.tab === 1 && this.selectedDays.length == 0) {
          hasError = true;
        }
        if (this.tab === 0) {

          if (this.$refs.form.validate() && hasError === false) {
            this.editItem.startdate = this.datainicioselect
            this.editItem.enddate = this.datainicioselect
            this.editItem.starthour = this.horainicioselect
            this.editItem.endhour = this.horafimselect
            this.editItem.UserId = this.user.id
            this.editItem.details = this.detailsinsert
            this.editItem.RoomId = 1
            this.editItem.TypeeventId = 1
            await ServiceUserbreaks.create(this.editItem);

            this.userbreaks = (await ServiceUserbreaks.list(this.user.id, this.data_inicio_pesquisa_select, this.data_fim_pesquisa_select)).data;

            this.$store.dispatch("setSnackbar", {});
            this.createDialog = false
            this.clear();
          }

        } else {

          if (this.$refs.form.validate() && hasError === false) {
            this.editItem.daysOfWeek = this.selectedDays
            this.editItem.startdate = this.datainicioselect
            this.editItem.enddate = this.datafimbulkselect
            this.editItem.starthour = this.horainicioselect
            this.editItem.endhour = this.horafimselect
            this.editItem.UserId = this.user.id,
            this.editItem.details = this.detailsinsert
            this.editItem.RoomId = 1
            this.editItem.TypeeventId = 1
            await ServiceUserbreaks.createbulk(this.editItem);

            this.userbreaks = (await ServiceUserbreaks.list(this.user.id, this.data_inicio_pesquisa_select, this.data_fim_pesquisa_select)).data;

            this.$store.dispatch("setSnackbar", {});
            this.createDialog = false
            this.clear();
          }
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    clear () {
      this.editItem = {
        id: null,
        startdate: null,
        enddate: null,
        starthour: null,
        endhour: null,
        UserId: null,
        details: null
      },
      this.datainicioselect = null
      this.datafimbulkselect = null
      this.horainicioselect = null
      this.horafimselect = null
      this.selectedDays = []
    },
    async deleteItem(item) {
      if (confirm("Confirma a eliminação deste elemento?")) {
        try {
          await ServiceUserbreaks.delete(item.id);
          this.$store.dispatch("setSnackbar", {});
          this.userbreaks = (await ServiceUserbreaks.list(this.user.id, this.data_inicio_pesquisa_select, this.data_fim_pesquisa_select)).data;
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
        }
      }
    },
    async edittItem (item) {
      this.editItem.id = item.id
      this.datainicioselect = this.formatDate2(item.end)
      this.horainicioselect = this.formatTime(item.start)
      this.horafimselect = this.formatTime(item.end)
      this.detailsinsert = item.details
      this.editDialog = true
    },
    async submitEditItem () {
      try {
        if (this.$refs.form2.validate()) {
          this.editItem.startdate = this.datainicioselect
          this.editItem.enddate = this.datainicioselect
          this.editItem.starthour = this.horainicioselect
          this.editItem.endhour = this.horafimselect
          this.editItem.UserId = this.user.id
          this.editItem.details = this.detailsinsert
          await ServiceUserbreaks.update(this.editItem);
          this.userbreaks = (await ServiceUserbreaks.list(this.user.id, this.data_inicio_pesquisa_select, this.data_fim_pesquisa_select)).data;
          this.$store.dispatch("setSnackbar", {});
          this.editDialog = false
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    async updateDashboardDateInicio(date) {
      this.$refs.toggleDataInicio.save(date);
      this.userbreaks = (await ServiceUserbreaks.list(this.user.id, this.data_inicio_pesquisa_select, this.data_fim_pesquisa_select)).data;
    },
    async updateDashboardDateFim(date) {
      this.$refs.toggleDataFim.save(date);
      this.userbreaks = (await ServiceUserbreaks.list(this.user.id, this.data_inicio_pesquisa_select, this.data_fim_pesquisa_select)).data;
    }
  },
  computed: {
  },
  async mounted() {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
