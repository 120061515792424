import Api from '@/services/ServiceBase'
const path = 'configuracoeserp'

export default {
  configuracoeserp() {
    return Api().get(path, {})
  },
  configuracoeserpupdate (configuracao) {
    return Api().put(path + `/${configuracao.id}`, configuracao)
  },
}
