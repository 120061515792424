import Api from '@/services/ServiceBase'
const path = 'rooms'

export default {
  createUpdate(item) {
    return Api().post(path, item)
  },
  list(page,name,identification) {
    return Api().get(path, {
      params: {
        page,
        name,
        identification
      }
    })
  },
  show(id) {
    return Api().get(path + `/${id}`)
  },
  delete(roomId) {
    return Api().get(path + `/delete/${roomId}`)
  }
}
