<template>
  <div>
    <v-speed-dial fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false">
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark large fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-filter-variant</v-icon>
        </v-btn>
      </template>
      <v-btn fab dark large color="green" @click.stop="filterDialog = true">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-btn fab dark large color="indigo" @click="addEditItem">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn fab dark large color="red" @click="importarClientesErp" v-if="$store.state.user.role === 'admin' && $store.state.configuracoeserp.ativo === true">
        <v-icon>mdi-database-import</v-icon>
      </v-btn>
    </v-speed-dial>
    <v-container style="margin-bottom: 70px;">
      <v-row align="center" justify="center">
        <v-col cols="12" md="10">
          <v-text-field prepend-icon="mdi-magnify" label="Nome" clearable v-model="name"></v-text-field>
        </v-col>
        <v-col cols="12" md="10">
          <v-data-table v-infinite-scroll="getClients" infinite-scroll-disabled="busy"
            :headers="headers" :items="clients" :loading="loading" hide-default-footer
            :items-per-page="clients.length + 10" class="mTable">
            <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>

            <template v-slot:[`item.discontinued`]="{ item }">
              <v-icon v-if="item.discontinued" color="red darken-2">mdi-close</v-icon>
              <v-icon v-else color="green darken-2">mdi-check</v-icon>
            </template>
            <template v-slot:[`item.action`]="{ item }">

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="addEditItem(item)">
                    <v-icon v-on="on" color="teal">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="openRegulations(item)">
                    <v-icon v-on="on" color="light-blue">mdi-clipboard-text</v-icon>
                  </v-btn>
                </template>
                <span>Regulamentos/Emails</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="openAttachments(item)">
                    <v-icon v-on="on" color="light-blue">mdi-attachment</v-icon>
                  </v-btn>
                </template>
                <span>Anexos</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="openObservations(item)">
                    <v-icon v-on="on" color="light-blue">mdi-information-outline</v-icon>
                  </v-btn>
                </template>
                <span>Observações</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="openAppointments(item)">
                    <v-icon v-on="on" color="light-blue">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <span>Marcações</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="openTypeevents(item)">
                    <v-icon v-on="on" color="purple">mdi-clipboard-list</v-icon>
                  </v-btn>
                </template>
                <span>Alteração preçário do cliente</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="openCovenants(item)">
                    <v-icon v-on="on" color="brown">mdi-clipboard-search</v-icon>
                  </v-btn>
                </template>
                <span>Avenças</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="createInvoice(item)">
                    <v-icon v-on="on" color="orange">mdi-invoice-text-plus</v-icon>
                  </v-btn>
                </template>
                <span>Artigos por faturar</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="openInvoices(item)">
                    <v-icon v-on="on" color="green">mdi-invoice-text</v-icon>
                  </v-btn>
                </template>
                <span>Faturação</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <!--Se tiver erp activo e o externalId for === null dá para apagar-->
                  <template v-if="$store.state.configuracoeserp.ativo === true">
                    <v-btn icon class="mx-0" @click="deleteItem(item)" v-if="item.externalID === null">
                      <v-icon  v-on="on" color="pink">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <template v-else>
                    <v-btn icon class="mx-0" @click="deleteItem(item)" >
                      <v-icon v-on="on" color="pink">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <!-- <v-dialog v-model="editDialog" persistent fullscreen hide-overlay scrollable transition="dialog-bottom-transition">
      <add-edit-client ref="qwerty" :client="editedItem" @submited="submitedData" @close="close" />
    </v-dialog> -->

    <add-edit-client ref="addEditClient" @submited="submitedData" />
    <observations ref="observations" />
    <attachments ref="attachments" />
    <regulations ref="regulations" />
    <covenants ref="covenants" />
    <typeevents ref="typeevents" />
    <articlesnotinvoiced ref="articlesnotinvoiced" />
    <articlesinvoiced ref="articlesinvoiced" />
    <appointments ref="appointments" :rooms="rooms" :users="users" :typeevents="typeevents" :groups="groups"/>

    <v-dialog v-model="filterDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Filtros :</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field label="Número" clearable v-model="number"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field label="Nome" clearable v-model="name"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field label="Nif" clearable v-model="nif"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field label="Email" clearable v-model="email"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field label="Telefone" clearable v-model="phone"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select v-model="gender" :items="types_gender" label="Género" item-text="text" item-value="value"></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-radio-group v-model="discontinued" column>
                  <v-radio v-for="item in discontinuedArray" :key="item.value" :label="item.text" :value="item.value"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="blue darken-1" text @click="filterDialog = false">Fechar</v-btn>
          <v-btn color="blue darken-1" text @click="Filter()">Procurar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import infiniteScroll from "vue-infinite-scroll";
import ServiceClients from "@/services/ServiceClients";
import AddEditClient from "@/components/common/AddEditClient.vue";
import Observations from "@/components/client/Observations.vue";
import Covenants from "@/components/client/Covenants.vue";
import Attachments from "@/components/client/Attachments.vue";
import Regulations from "@/components/client/Regulations.vue";
import Typeevents from "@/components/client/Typeevents.vue";
import Appointments from "@/components/client/ClientAppointments";
import Articlesnotinvoiced from "@/components/client/Articlesnotinvoiced";
import Articlesinvoiced from "@/components/client/Articlesinvoiced";
import ServiceListarClientes from "@/serviceGgest/ServiceListarClientes";
import ServiceLogin from "@/serviceGgest/ServiceLogin";
import { getUsersAndRooms, getTypeevents, getGroups } from "@/components/Utils";
export default {
  name: "List-Clients",
  directives: {
    infiniteScroll
  },
  components: {
    AddEditClient,
    Observations,
    Appointments,
    Attachments,
    Regulations,
    Covenants,
    Typeevents,
    Articlesnotinvoiced,
    Articlesinvoiced
  },
  data() {
    return {
      users: [],
      rooms: [],
      typeevents: [],
      groups: [],
      editDialog: false,
      editedIndex: -1,
      editedItem: {
      },
      defaultItem: {
      },

      filterDialog: false,
      fab: false,
      page: 1,
      headers: [
        { text: "Número", sortable: false, value: "number" },
        { text: "Nome", sortable: false, value: "name" },
        { text: "Activo", sortable: false, value: "discontinued", align: "center" },
        { text: "", value: "action", sortable: false, align: "center" }
      ],
      clients: [],
      clientsggest: [],
      configuracoesggest: {},
      busy: false,
      loading: true,
      name: null,
      nif: null,
      email: null,
      phone: null,
      gender: null,
      number: null,
      externalID: null,
      discontinued: 2,
      discontinuedArray: [
        { text: "Ver todos", value: 2 },
        { text: "Ver apenas activos", value: 0 },
        { text: "Ver apenas inactivos", value: 1 }
      ],
      types_gender: [
        {text: 'Masculino', value_erp: 'masculino'},
        {text: 'Feminino', value_erp: 'feminino'},
        {text: 'Empresa', value_erp: 'empresa'},
        {text: 'Sem género', value_erp: 'semgenero'},
      ],
      dialogConfirmCreateCovenant: false
    };
  },
  watch: {
    name(newName) {
      clearTimeout(this.debounceTimeout);

      if (newName.trim() === "") {
        this.clear();
      } else {
        this.debounceTimeout = setTimeout(() => {
          this.page = 1;
          this.clients = [];
          this.getClients();
        }, 500);
      }
    },
  },
  methods: {
    openClientInfo(id) {
      this.$refs.addEditClient.open(id);
    },
    async getClients() {
      try {
        this.busy = true;
        this.loading = true;
        const response = await ServiceClients.list(
          this.name,
          this.page,
          this.discontinued,
          this.nif,
          this.email,
          this.phone,
          this.externalID,
          this.gender,
          this.number
        );
        response.data.forEach(element => {
          this.clients.push(element);
        });
        this.page++;
        this.busy = false;
        if (response.data.length == 0) {
          this.busy = true;
        }

      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
      this.loading = false;
    },
    async deleteItem(item) {
      if (confirm("Confirma a eliminação deste elemento?")) {
        try {
          const index = this.clients.indexOf(item);
          await ServiceClients.delete(item.id);
          this.clients.splice(index, 1);
          this.$store.dispatch("setSnackbar", {});
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
        }
      }
    },
    submitedData(item) {
      if (this.editedIndex > -1) {
        Object.assign(this.clients[this.editedIndex], item);
      } else {
        this.clients.unshift(item);
      }
    },
    openObservations(item) {
      this.$refs.observations.open(item);
    },
    openAttachments(item) {
      this.$refs.attachments.open(item);
    },
    openRegulations(item) {
      this.$refs.regulations.open(item);
    },
    openCovenants(item) {
      this.$refs.covenants.open(item);
    },
    openTypeevents(item) {
      this.$refs.typeevents.open(item);
    },
    openAppointments(item) {
      this.$refs.appointments.open(item);
    },
    createInvoice(item) {
      this.$refs.articlesnotinvoiced.open(item);
    },
    openInvoices(item) {
      this.$refs.articlesinvoiced.open(item);
    },
    addEditItem(item) {
      try {
        if (item) {
          this.editedIndex = this.clients.indexOf(item);
          this.editedItem = Object.assign({}, item);
        } else {
          this.editedIndex = -1;
          this.editedItem = Object.assign({}, this.defaultItem);
        }
        this.$refs.addEditClient.open(this.editedItem.id);
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    close() {
      this.editDialog = false;
    },
    Filter() {
      this.clients = [];
      this.page = 1;
      this.filterDialog = false;
      this.getClients();
    },
    clear() {
      this.clients = [];
      this.page = 1;
      this.busy = false;
      this.loading = false;
      this.discontinued = null;
      this.nif = null;
      this.email = null;
      this.phone = null;
      this.externalID = null;
      this.gender = null;
      this.number = null;
    },
    async importarClientesErp () {
      this.clients = [];
      this.page = 1;
      this.loading = true
      if (this.$store.state.configuracoeserp.erp == 'ggest') {
        const response = await ServiceLogin.login(process.env.VUE_APP_API_USER_GGEST, process.env.VUE_APP_API_PASS_GGEST);
        this.$store.dispatch("setTokenGgest", response.data.token);
        this.clientsggest = await ServiceListarClientes.listarClientes()
        //const artigos = await ServiceListarClientes.listarClientes()
        //console.log(artigos)

        for (let i = 0; i < this.clientsggest.data.dados.length; i++) {
          const clienteexiste = (await ServiceClients.showExternal(this.clientsggest.data.dados[i].codigo)).data;
          let gender = null
          if (this.clientsggest.data.dados[i].sexo == "") {
            gender = 'semgenero'
          } else {
            console.log(this.clientsggest.data.dados[i].sexo)
          }

          const criarCliente = {
            number: this.clientsggest.data.dados[i].codigo,
            name: this.clientsggest.data.dados[i].descricao,
            address: this.clientsggest.data.dados[i].morada,
            road: this.clientsggest.data.dados[i].morada2,
            postal_code: this.clientsggest.data.dados[i].codpostal,
            nif: this.clientsggest.data.dados[i].contribuinte,
            phone: this.clientsggest.data.dados[i].telemovel1,
            email: this.clientsggest.data.dados[i].email1,
            discontinued: this.clientsggest.data.dados[i].inativo,
            web: this.clientsggest.data.dados[i].web,
            observations: this.clientsggest.data.dados[i].obs,
            //birthDate: this.clientsggest.data.dados[i].data_nascimento,
            occupation: this.clientsggest.data.dados[i].area_negocio,
            externalID: this.clientsggest.data.dados[i].codigo,
            rgpd_signature: 1,
            gender: gender
          }
          if (clienteexiste.number === undefined) {
            criarCliente.erp_billing_number = this.clientsggest.data.dados[i].codigo
            await ServiceClients.add(criarCliente);
          } else {
            if (clienteexiste.erp_billing_number === null) {
              criarCliente.erp_billing_number = this.clientsggest.data.dados[i].codigo
            }
            criarCliente.id = clienteexiste.id
            await ServiceClients.add(criarCliente);
          }
          criarCliente.id = null
        }
      }

      this.getClients();

    }
  },
  async mounted() {
    try {
      await getUsersAndRooms(1, 1, this.users, this.rooms);
      await getTypeevents(1, this.typeevents);
      await getGroups(1, this.groups);
    } catch (error) {
      this.$store.dispatch("setSnackbar", {
        color: "error",
        text: error
      });
    }
  },
  created() {

    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {

      if (this.$refs.addEditClient) {
        if (this.$refs.addEditClient.show) {
          this.$refs.addEditClient.show = false;
          next(false);
          return;
        }
      }

      if (this.$refs.appointments) {

        if (this.$refs.appointments.$refs.eventDialog) {
          if (this.$refs.appointments.$refs.eventDialog.dialog) {
            if (this.$refs.appointments.$refs.eventDialog.navGuards()) {
              next(false);
              return;
            }
          }
        }

        if (this.$refs.appointments.show) {
          this.$refs.appointments.show = false;
          next(false);
          return;
        }
      }

      if (this.$refs.observations) {
        if (this.$refs.observations.editDialog) {
          this.$refs.observations.editDialog = false;
          next(false);
          return;
        }

        if (this.$refs.observations.show) {
          this.$refs.observations.show = false;
          next(false);
          return;
        }
      }


      if (this.$refs.attachments) {
        if (this.$refs.attachments.editDialog) {
          this.$refs.attachments.editDialog = false;
          next(false);
          return;
        }

        if (this.$refs.attachments.show) {
          this.$refs.attachments.show = false;
          next(false);
          return;
        }
      }

      if (this.$refs.covenants) {
        if (this.$refs.covenants.editDialog) {
          this.$refs.covenants.editDialog = false;
          next(false);
          return;
        }

        if (this.$refs.covenants.show) {
          this.$refs.covenants.show = false;
          next(false);
          return;
        }
      }

      if (this.$refs.typeevents) {
        if (this.$refs.typeevents.editDialog) {
          this.$refs.typeevents.editDialog = false;
          next(false);
          return;
        }

        if (this.$refs.typeevents.show) {
          this.$refs.typeevents.show = false;
          next(false);
          return;
        }
      }

      if (this.$refs.articlesnotinvoiced) {
        if (this.$refs.articlesnotinvoiced.editDialog) {
          this.$refs.articlesnotinvoiced.editDialog = false;
          next(false);
          return;
        }

        if (this.$refs.articlesnotinvoiced.show) {
          this.$refs.articlesnotinvoiced.show = false;
          next(false);
          return;
        }
      }

      if (this.$refs.articlesinvoiced) {
        if (this.$refs.articlesinvoiced.editDialog) {
          this.$refs.articlesinvoiced.editDialog = false;
          next(false);
          return;
        }

        if (this.$refs.articlesinvoiced.show) {
          this.$refs.articlesinvoiced.show = false;
          next(false);
          return;
        }
      }

      if (this.filterDialog) {
        this.filterDialog = false;
        next(false);
        return;
      }

      next();
    });

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard()
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
