var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-dialog',{attrs:{"scrollable":"","width":"1500","transition":"dialog-bottom-transition"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"basicBackGround"},[_c('v-toolbar',{attrs:{"dark":"","color":"light-blue"}},[_c('v-toolbar-title',[_vm._v("Alteração preçário do cliente: "+_vm._s(_vm.client.name))]),_c('v-spacer')],1),_c('v-card-text',[(_vm.typeseventclient.length != 0)?_c('v-card',{staticClass:"elevation-0",attrs:{"color":"transparent"}},[_c('v-list',{attrs:{"color":"transparent"}},[_c('v-list-item-group',[_c('v-list-item',[_c('v-list-item-content',[_c('v-data-table',{staticClass:"mTable",attrs:{"infinite-scroll-disabled":"busy","headers":_vm.headers,"items":_vm.typeseventclient,"loading":_vm.loading,"hide-default-footer":"","items-per-page":_vm.typeseventclient.length + 10},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price)+" € ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.edittItem(item)}}},[_c('v-icon',_vm._g({attrs:{"color":"teal"}},on),[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.openPlans(item)}}},[_c('v-icon',_vm._g({attrs:{"color":"orange"}},on),[_vm._v("mdi-floor-plan")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar plano cliente")])])]}}],null,true)})],1)],1)],1)],1)],1):_c('v-card',{staticClass:"elevation-0",attrs:{"align":"center","justify":"center","color":"transparent"}},[_c('v-icon',{attrs:{"size":"200","color":"red"}},[_vm._v(" mdi-file ")]),_c('v-card-title',{staticClass:"justify-center"},[_vm._v("Não há serviços de cliente a apresentar.")])],1)],1)],1),_c('v-dialog',{attrs:{"presistent":"","scrollable":"","width":"600","transition":"dialog-bottom-transition"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Editar")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.editDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"type":"number","label":"Preço (€)","rules":[_vm.rules.required]},model:{value:(_vm.editItem.price),callback:function ($$v) {_vm.$set(_vm.editItem, "price", $$v)},expression:"editItem.price"}})],1)],1)],1)],1)],1),(_vm.$store.state.user.role === 'admin' || _vm.editItem.UserId === _vm.$store.state.user.id || !_vm.editItem.UserId)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary","outlined":""},on:{"click":_vm.submitEditItem}},[_vm._v("Submeter")])],1):_vm._e()],1)],1)],1),_c('planstypeevents',{ref:"planstypeevents"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }