<template>
  <v-card>
    <v-card-title class="pa-0">
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ regulation.id == undefined ? 'Adicionar' : 'Editar' }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-text-field v-model="regulation.description" :rules="[rules.required, rules.counter]" :counter="250"
                label="Descrição" required></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-textarea v-model="regulation.content" label="Conteúdo"></v-textarea>
            </v-col>
          </v-row>
          <v-row v-if="regulation.id == null" align="center" justify="center">
            <v-col cols="12" align="center" justify="center">
              <div v-if="papadipopo" style="position:relative; max-width:500px; max-height:500px;" class="grey lighten-2">
                <embed v-if="regulation.file" :src="regulation.file" width="100%" height="500px" />
                <v-btn color="primary" style="position:absolute; top:0; right:0;" @click="$refs.inputUpload.click()">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
              <input v-show="false" accept="*/*" ref="inputUpload" id="inputUpload" type="file" @change="AddFile()" />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn v-if="regulation.id == null" outlined text color="red" @click="clear">Limpar</v-btn>
      <v-spacer></v-spacer>
      <v-btn outlined text color="primary" :disabled="!valid" @click="submit">Submeter</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { dataURItoBlob } from "@/components/Utils";
import ServiceRegulations from "@/services/ServiceRegulations";
import _ from "lodash";
export default {
  name: "AddEdit-Regulation",
  props: ['regulation'],
  data() {
    return {
      papadipopo: true,
      image: null,
      valid: true,
      rules: {
        required: value => !!value || "Campo Obrigatório.",
        counter: value =>
          (value && value.length <= 250) ||
          "Campo tem de ter menos de 250 caracteres",
        isValidNumber: value =>
          /^([0-9]+[.])?[0-9]+$/.test(value) || "Valor inválido"
      },
      locked: true,
      lockClient: false,
      loadingClients: false,
    };
  },
  methods: {
    test() {
      this.regulation.file = 'https://i.picsum.photos/id/634/510/300.jpg?hmac=cH_5Gv_UfyIZmfpVGxPbjYwoDCX1Gzz4qnUEFD5NLj8';
      this.papadipopo = false;
      this.papadipopo = true;
    },
    async submit() {
      try {
        if (this.$refs.form.validate()) {
          let response;
          if (this.regulation.id == undefined) {
            let formData = new FormData();

            for (let [key, value] of Object.entries(this.regulation)) {
              if (value !== null && key !== "file") {
                formData.set(key, value);
              }
            }

            if (this.image === true) {
              formData.append(
                "file",
                dataURItoBlob(this.regulation.file),
                this.$refs.inputUpload.files[0].name
              );
            }
            this.loading = true;

            response = await ServiceRegulations.createUpdate(formData);

            this.image = false;

            if (!this.regulation.id) {
              this.$refs.form.reset();
            }

          } else {
            response = await ServiceRegulations.createUpdate(this.regulation);
          }

          this.$emit('submited', response.data);
          this.loading = false;
          this.$store.dispatch("setSnackbar", {});
          this.close()
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    AddFile() {
      var filesize = (
        this.$refs.inputUpload.files[0].size /
        1024 /
        1024
      ).toFixed(4);

      if (filesize > 2) {
        alert("Tamanho máximo do ficheiro é 2MB");
        return;
      }

      var reader = new FileReader();

      reader.addEventListener(
        "load",
        () => {
          this.regulation.file = reader.result;
          this.image = true;
          this.papadipopo = false;
          this.papadipopo = true;
        },
        false
      );
      if (this.$refs.inputUpload.files[0]) {
        reader.readAsDataURL(this.$refs.inputUpload.files[0]);
      }
    },
    clear() {
      this.regulation.description = ''
      this.regulation.content = ''
      this.regulation.file = ''
      this.image = null
      this.papadipopo = true
    },
    close() {
      this.$emit('close');
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
