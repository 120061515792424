<template>
  <v-container>
    <v-speed-dial fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false">
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark large fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-filter-variant</v-icon>
        </v-btn>
      </template>
      <v-btn fab dark large color="green" @click.stop="filterDialog = true">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-btn fab dark large color="indigo" @click="addEditItem()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-speed-dial>

    <v-row align="center" justify="center">
      <v-col cols="12" md="10">
        <v-data-table class="mTable" id="mytable" v-infinite-scroll="getUsers" infinite-scroll-disabled="busy"
          infinite-scroll-distance="10" :headers="headers" :items="users" :loading="loading" hide-default-footer
          :items-per-page="users.length + 10">
          <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>

          <template v-slot:[`item.color`]="{ item }">
            <v-chip class="pa-5" :color="item.color" />
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <td class="justify-center layout px-0">

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="addEditItem(item)">
                    <v-icon v-on="on" color="teal">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="deleteItem(item)">
                    <v-icon v-on="on" color="pink">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Eliminar</span>
              </v-tooltip>

              <v-tooltip top v-if="item.file != ''">
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0">
                    <a :href="baseUrl + '/' + item.file"><v-icon v-on="on" color="green">mdi-download</v-icon></a>
                  </v-btn>
                </template>
                <span>Download</span>
              </v-tooltip>


              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="abrirclientes(item)">
                    <v-icon v-on="on" color="primary">mdi-email-arrow-right</v-icon>
                  </v-btn>
                </template>
                <span>Enviar email</span>
              </v-tooltip>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="editDialog" ref="test" scrollable width="600" transition="dialog-bottom-transition">
      <add-edit-user ref="qwerty" :regulation="editedItem" @submited="submitedData" @close="close"></add-edit-user>
    </v-dialog>

    <v-dialog v-model="filterDialog" max-width="600px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Filtros :</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="filterDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field label="Descrição" clearable v-model="description"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="blue darken-1" text @click="Filter()">Procurar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="clientsDialog" max-width="600px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title>{{clientTypeP}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="clientsDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-tabs v-model="tab">
              <v-tab>Não Enviados</v-tab>
              <v-tab>Enviados</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-card flat>
                  <v-card-text class="text-right">
                    <v-btn class="text-right" @click="toggleSelectAllNaoEnviados">
                      {{ selectAllNaoEnviados ? 'Desmarcar Todos' : 'Selecionar Todos' }}
                    </v-btn>
                    <v-checkbox v-for="(clientesnaoenviado, index) in clientesnaoenviadosarray" :key="index" :label="clientesnaoenviado.name" :value="clientesnaoenviado" v-model="selectedEmails" style="margin-top: 10px;"></v-checkbox>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text class="text-right">
                    <v-btn class="text-right" @click="toggleSelectAllEnviados">
                      {{ selectAllEnviados ? 'Desmarcar Todos' : 'Selecionar Todos' }}
                    </v-btn>
                    <v-checkbox v-for="(clientesenviado, index) in clientesenviadosarray" :key="index" :label="clientesenviado.name" :value="clientesenviado" v-model="selectedEmails" style="margin-top: 10px;"></v-checkbox>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="blue darken-1" text @click="enviarregulamento()">Enviar regulamento</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import infiniteScroll from "vue-infinite-scroll";
import ServiceRegulations from "@/services/ServiceRegulations";
import ServiceClients from "@/services/ServiceClients";
import ServiceClientregulations from "@/services/ServiceClientregulations";
import AddEditUser from "@/components/regulation/AddEditRegulation.vue";
import _ from "lodash";
export default {
  name: "List-Regulations",
  directives: {
    infiniteScroll
  },
  components: {
    AddEditUser
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_URL,
      editDialog: false,
      editedIndex: -1,
      editedItem: {
      },
      defaultItem: {
      },

      value: "",
      fileName: "",
      filterDialog: false,
      clientsDialog: false,
      fab: false,
      page: 1,
      tab: 0,
      headers: [
        { text: "Descrição", value: "description", sortable: false },
        { text: "Conteúdo", value: "content", sortable: false },
        { text: "", value: "action", sortable: false, align: "center" }
      ],
      users: [],
      swatches: [],
      test: [],
      busy: false,
      loading: true,
      description: null,
      login: null,
      externalID: null,
      selectedRegulationId: null,
      regulationsclient: [],
      clients: [],
      clientesnaoenviadosarray: [],
      clientesenviadosarray: [],
      selectedEmails: [],
      selectAllNaoEnviados: false,
      selectAllEnviados: false,
      clientTypeP: ''
    };
  },
  methods: {
    async getUsers() {
      this.busy = true;
      this.loading = true;
      const response = await ServiceRegulations.list(
        this.page,
        this.description
      );

      this.users = this.users.concat(response.data);

      this.page++;
      this.busy = false;
      if (response.data.length == 0) {
        this.busy = true;
      }
      this.loading = false;
    },
    async deleteItem(item) {
      if (confirm("Confirma a eliminação deste elemento?")) {
        try {
          const index = this.users.indexOf(item);
          await ServiceRegulations.delete(item.id, item.file);
          this.users.splice(index, 1);
          this.$store.dispatch("setSnackbar", {});
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
        }
      }
    },
    async addEditItem(item) {
      try {

        if (item) {
          this.editedIndex = this.users.indexOf(item);
          this.editedItem = Object.assign({}, item);
        } else {
          this.editedIndex = -1;
          this.editedItem = Object.assign({}, this.defaultItem);
        }

        this.editDialog = true;
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    submitedData(item) {
      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], item);
      } else {
        this.users.unshift(item);
      }

      for (let i = 0; i < this.swatches.length; i++) {

        const index = _.findIndex(this.swatches[i], function (o) {
          return o == item.color;
        });

        if (index != -1) {
          this.swatches[i].splice(index, 1);
          break;
        }
      }
    },
    async abrirclientes(regulation) {
      this.selectedEmails = []
      this.clientesnaoenviadosarray = []
      this.clientesenviadosarray = []
      this.selectedRegulationId = regulation.id
      this.clientsDialog = true
      this.regulationsclient = (await ServiceClientregulations.regulamentoscliente(regulation.id, null)).data;
      this.clients = (await ServiceClients.listt(null, null, 0)).data;
      this.clientesnaoenviados()
      this.clientesenviados()
    },
    async clientesnaoenviados() {
      this.clientesnaoenviadosarray = this.clients.filter(client => !this.regulationsclient.some(item => item.ClientId === client.id));
    },
    async clientesenviados() {
      this.clientesenviadosarray = this.clients.filter(client => this.regulationsclient.some(item => item.ClientId === client.id));
    },
    toggleSelectAllNaoEnviados() {
      if (this.selectAllNaoEnviados) {
        this.selectedEmails = this.selectedEmails.filter(
          selected => !this.clientesnaoenviadosarray.some(cliente => cliente.email === selected.email)
        );
      } else {
        const naoEnviadosEmails = this.clientesnaoenviadosarray;
        this.selectedEmails = [
          ...this.selectedEmails,
          ...naoEnviadosEmails.filter(
            naoEnviado => !this.selectedEmails.some(selected => selected.email === naoEnviado.email)
          )
        ];
      }
      this.selectAllNaoEnviados = !this.selectAllNaoEnviados;
    },
    toggleSelectAllEnviados() {
      if (this.selectAllEnviados) {
        this.selectedEmails = this.selectedEmails.filter(
          selected => !this.clientesenviadosarray.some(cliente => cliente.email === selected.email)
        );
      } else {
        const enviadosEmails = this.clientesenviadosarray;
        this.selectedEmails = [
          ...this.selectedEmails,
          ...enviadosEmails.filter(
            enviado => !this.selectedEmails.some(selected => selected.email === enviado.email)
          )
        ];
      }
      this.selectAllEnviados = !this.selectAllEnviados;
    },
    async enviarregulamento () {
      try {
        await ServiceClientregulations.enviarregulamentoindividual(this.selectedRegulationId, this.selectedEmails);
        this.selectedEmails = []
        this.clientesnaoenviadosarray = []
        this.clientesenviadosarray = []
        this.abrirclientes({id: this.selectedRegulationId})
        this.$store.dispatch("setSnackbar", {});
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    Filter() {
      this.users = [];
      this.page = 1;
      this.filterDialog = false;
      this.getUsers();
    },
    close() {
      this.editDialog = false;
    },
  },
  async mounted() {
    this.clientTypeP = config.CLIENT_TYPE_PLURAL;
  },
  created() {
    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {

      if (this.editDialog) {
        this.editDialog = false;
        next(false);
        return;
      }

      if (this.filterDialog) {
        this.filterDialog = false;
        next(false);
        return;
      }

      next();
    });

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard()
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*bush fix the icon was pushed to the top*/
.btn--floating .icon {
  height: 50%;
}
.v-input--selection-controls {
  margin-top: unset;
  padding-top: unset;
}
</style>
