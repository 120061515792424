<template>
  <v-dialog v-model="show" scrollable width="1500" transition="dialog-bottom-transition">
    <v-card class="basicBackGround">
      <v-toolbar dark color="light-blue">
        <v-toolbar-title>Anexos : {{ client.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon @click="toggleDialog()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Novo Registo</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text>
        <div v-if="loading" align="center" justify="center">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </div>
        <v-col v-else cols="12">
          <v-data-table v-if="attachments.length != 0" :headers="headers" :items="attachments" hide-default-footer
            class="mTable" :items-per-page="attachments.length + 10">

            <template v-slot:[`item.img`]="{ item }">
              <v-avatar size="32px" tile>
                <img :src="baseUrl + '/' + item.User.img" alt="Infordio" />
              </v-avatar>
            </template>

            <template v-slot:[`item.action`]="{ item }">

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon @click="toggleDialog(item)">
                    <v-icon v-if="$store.state.user.role === 'admin' || item.UserId === $store.state.user.id"
                      color="success">mdi-pencil</v-icon>
                    <v-icon v-else color="blue">mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>{{ ($store.state.user.role === 'admin' || item.UserId === $store.state.user.id) ? 'Editar' :
                  'Ver' }}</span>
              </v-tooltip>
              <v-tooltip bottom v-if="$store.state.user.role === 'admin' || item.UserId === $store.state.user.id">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon @click="deleteAtt(item)">
                    <v-icon color="error">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon>
                    <a :href="baseUrl + '/' + item.file"><v-icon v-on="on" color="green">mdi-download</v-icon></a>
                  </v-btn>
                </template>
                <span>Download</span>
              </v-tooltip>

            </template>
          </v-data-table>
          <v-card v-else class="elevation-0" align="center" justify="center" color="transparent">
            <v-icon size="200" color="red">
              mdi-file
            </v-icon>
            <v-card-title class="justify-center">Não há registos a apresentar.</v-card-title>
          </v-card>
        </v-col>
      </v-card-text>
    </v-card>

    <v-dialog v-model="editDialog" presistent scrollable width="600" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title>{{editItem.id ? 'Editar' : 'Adicionar' }}</v-toolbar-title>
            <v-spacer />

            <v-btn icon dark @click="toggleDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>

              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-text-field readonly v-model="client.name" label="Paciente"></v-text-field>
                </v-col>
              </v-row>

              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-textarea
                    :readonly="!($store.state.user.role === 'admin' || editItem.UserId === $store.state.user.id || !editItem.UserId)"
                    :clearable="($store.state.user.role === 'admin' || editItem.UserId === $store.state.user.id || !editItem.UserId)"
                    v-model="editItem.description" :rules="[rules.required]" label="Descrição" required></v-textarea>
                </v-col>
              </v-row>

              <v-row v-if="editItem.id == null" align="center" justify="center">
                <v-col cols="12" align="center" justify="center">
                  <div v-if="papadipopo" style="position:relative; max-width:500px; max-height:500px;" class="grey lighten-2">
                    <embed v-if="editItem.file" :src="editItem.file" width="100%" height="500px" />
                    <v-btn color="primary" style="position:absolute; top:0; right:0;" @click="$refs.inputUpload.click()">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                  <input v-show="false" accept="*/*" ref="inputUpload" id="inputUpload" type="file" @change="AddFile()" />
                </v-col>
              </v-row>

            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions v-if="$store.state.user.role === 'admin' || editItem.UserId === $store.state.user.id || !editItem.UserId">
          <v-btn v-if="editItem.id == null" text color="red" outlined @click="clear">Limpar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="primary" outlined @click="submit">Submeter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-dialog>
</template>

<script>
import ServiceClients from "@/services/ServiceClients";
import { dataURItoBlob } from "@/components/Utils";
export default {
  name: "ClientAttachments",
  data() {
    return {
      papadipopo: true,
      image: null,
      baseUrl: process.env.VUE_APP_API_URL,
      show: false,
      loading: false,
      valid: true,
      client: {},
      attachments: [],
      editDialog: false,
      editItem: {},
      editIndex: -1,
      emptyItem: {},
      rules: {
        required: value => !!value || "Campo Obrigatório.",
        counter: value =>
          (value && value.length <= 250) ||
          "Campo tem de ter menos de 250 caracteres",
        isValidNumber: value =>
          /^([0-9]+[.])?[0-9]+$/.test(value) || "Valor inválido"
      },
      headers: [
        { text: 'Imagem', value: 'img', sortable: false },
        { text: 'Funcionário', value: 'User.name', sortable: false },
        { text: 'Descrição', value: 'description', sortable: false },
        { text: 'Ações', value: 'action', sortable: false },
      ],
    };
  },
  methods: {
    async deleteAtt(item) {

      if (confirm("Confirma a eliminação deste item?")) {
        try {

          let index = this.attachments.indexOf(item);

          await ServiceClients.deleteAttachment(item.id, item.file);

          this.attachments.splice(index, 1);

          this.$store.dispatch("setSnackbar", {});
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
        }
      }
    },
    async submit() {
      try {
        if (this.$refs.form.validate()) {
          let response;
          if (this.editItem.id == undefined) {
            let formData = new FormData();

            this.editItem.ClientId = this.client.id;
            this.editItem.UserId = this.$store.state.user.id;

            for (let [key, value] of Object.entries(this.editItem)) {
              if (value !== null && key !== "file") {
                formData.set(key, value);
              }
            }

            if (this.image === true) {
              formData.append(
                "file",
                dataURItoBlob(this.editItem.file),
                this.$refs.inputUpload.files[0].name
              );
            }
            this.loading = true;
            response = await ServiceClients.createUpdateAttachment(formData, this.client.id);

            this.image = false;

          } else {
            response = await ServiceClients.createUpdateAttachment(this.editItem, this.client.id);
          }

          if (this.editItem.id) {
            Object.assign(this.attachments[this.editIndex], response.data);
          } else {
            this.attachments.unshift(response.data);
          }

          this.$emit('submited', response.data);
          this.loading = false;
          this.$store.dispatch("setSnackbar", {});
          this.toggleDialog();
          this.clear();
          this.open(this.client);
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    clear() {
      this.editItem.description = ''
      this.editItem.file = ''
      this.image = null
      this.papadipopo = true
    },
    close() {
      this.show = false;
    },
    toggleDialog(item) {
      if (item) {
        this.editIndex = this.attachments.indexOf(item);
        this.editItem = Object.assign({}, item);
      } else {
        this.editIndex = -1;
        this.editItem = Object.assign({}, this.emptyItem);
      }

      this.editDialog = !this.editDialog;
    },
    async open(client) {
      this.client = client;
      this.attachments = [];
      this.show = true;

      try {
        this.loading = true;
        this.attachments = (await ServiceClients.getClientAttachments(this.client.id)).data;

      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
      this.loading = false;
    },
    AddFile() {
      var filesize = (
        this.$refs.inputUpload.files[0].size /
        1024 /
        1024
      ).toFixed(4);

      if (filesize > 2) {
        alert("Tamanho máximo do ficheiro é 2MB");
        return;
      }

      var reader = new FileReader();

      reader.addEventListener(
        "load",
        () => {
          this.editItem.file = reader.result;
          this.image = true;
          this.papadipopo = false;
          this.papadipopo = true;
        },
        false
      );
      if (this.$refs.inputUpload.files[0]) {
        reader.readAsDataURL(this.$refs.inputUpload.files[0]);
      }
    },
  },
  computed: {
  },
  async mounted() {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
