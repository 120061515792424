<template>
  <v-dialog v-model="show" scrollable width="600" transition="dialog-bottom-transition" @input="onDialogClose2">
    <v-card class="basicBackGround">
      <v-toolbar dark color="light-blue">
        <v-toolbar-title>Faturar</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation v-if="progressacriarfatura === false">
          <v-container>
            <v-row align="center" justify="center">
              <v-col cols="12">
                <v-autocomplete label="Tipo de documento" :items="type_documents" item-text="text" v-model="type_document_name" return-object @change="typeDocument" :rules="[rules.required]"></v-autocomplete>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" v-if="type_document_payment_method === true">
              <v-col cols="12">
                <v-autocomplete label="Método de pagamento" v-model="payment_method" :items="payment_methods" item-text="text" item-value="method_abbreviation"></v-autocomplete>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="12">
                <v-textarea label="Observações da fatura" v-model="observations"></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-checkbox v-if="sacofaturar === true && $store.state.configuracoeserp.ativo === true" v-model="sacofaturarvalue" dense label="Não enviar para o ERP" class="mr-5"></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-checkbox v-if="sacofaturarvalue === false && $store.state.configuracoeserp.ativo === true" v-model="documentosporemailvalue" dense label="Enviar documentos por email" class="mr-5"></v-checkbox>
                <span v-if="documentosporemailvalue === true" style="color: red;">Os documentos só vão ser enviados por email, se o email for válido!</span>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="12" class="text-right">
                <h1 v-if="price != null">Total: {{ price.toFixed(2) }} €</h1>
                <!--<v-text-field type="number" v-model="price" label="Preço (€)" :rules="[rules.required]" disabled></v-text-field>-->
              </v-col>
            </v-row>

          </v-container>
        </v-form>
        <v-progress-linear v-else class="mt-2" color="primary" indeterminate rounded height="10"></v-progress-linear>
      </v-card-text>
      <v-card-actions v-if="progressacriarfatura === false">
        <v-spacer></v-spacer>
        <v-btn text color="primary" outlined @click="faturar">Faturar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ServiceInvoices from "@/services/ServiceInvoices";
//import ServiceTypeevents from "@/services/ServiceTypeevents";
import ServiceLogin from "@/serviceGgest/ServiceLogin";
import ServiceDocumentos from "@/serviceGgest/ServiceDocumentos";
import ServiceListarClientes from "@/serviceGgest/ServiceListarClientes";
import ServiceArtigos from "@/serviceGgest/ServiceArtigos";
import ServiceClients from "@/services/ServiceClients";
import ServiceInvoiceLogLines from "@/services/ServiceInvoiceLogLines";
import ServiceInvoiceLogs from "@/services/ServiceInvoiceLogs";
import { mapState } from "vuex";
export default {
  name: "Invoices",
  data() {
    return {
      valid: true,
      show: false,
      loading: false,
      page: 1,
      rules: {
        required: value => !!value || "Campo Obrigatório.",
        counter: value =>
          (value && value.length <= 250) ||
          "Campo tem de ter menos de 250 caracteres",
        isValidNumber: value =>
          /^([0-9]+[.])?[0-9]+$/.test(value) || "Valor inválido"
      },
      progressacriarfatura: false,
      deondevemosartigos: null,
      client: {},
      payment_methods: [],
      type_documents: [],
      observations: '',
      payment_method: null,
      invoice_pay: false,
      type_document: null,
      type_document_name: null,
      type_document_payment_method: null,
      price: null,

      arrayArtigosFaturar: [],

      erptypedocumentabbreviation: null,
      erpfaturasivaincluido: true,
      erpmoeda: 'EUR',
      erpposto: 1,
      sacofaturarvalue: false,
      documentosporemailvalue: false,
      sacofaturar: false
    };
  },
  methods: {
    formatDateTime(date) {
      const d = new Date(date);

      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const year = d.getFullYear();
      const hours = String(d.getHours()).padStart(2, '0');
      const minutes = String(d.getMinutes()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
    formatDate(date) {
      const d = new Date(date);

      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const year = d.getFullYear();

      return `${year}-${month}-${day}`;
    },
    formatTime(date) {
      const d = new Date(date);

      const hours = String(d.getHours()).padStart(2, '0');
      const minutes = String(d.getMinutes()).padStart(2, '0');

      return `${hours}:${minutes}`;
    },
    close() {
      this.show = false;
    },
    async open(client, arrayArtigos, deondevemosartigos) {
      this.deondevemosartigos = deondevemosartigos
      this.client = client;
      this.arrayArtigosFaturar = arrayArtigos
      this.price = 0
      this.loading = false;
      this.show = true;

      if (this.deondevemosartigos == 1) {

        //Preço total
        if (this.arrayArtigosFaturar != '') {
          for (var i = 0; i < this.arrayArtigosFaturar.length; i++) {
            this.price = this.price + parseFloat(this.arrayArtigosFaturar[i].Price) * this.arrayArtigosFaturar[i].Quantity
          }
        }
        //Método pagamento cliente
        this.payment_method = this.client.payment_methods

      } else if (this.deondevemosartigos == 2) {
        //Preço total
        if (this.arrayArtigosFaturar != '') {
          for (var i = 0; i < this.arrayArtigosFaturar.length; i++) {
            this.price = this.price + parseFloat(this.arrayArtigosFaturar[i].Price) * this.arrayArtigosFaturar[i].Quantity
          }
        }
      }


    },
    async faturar() {
        if (this.$refs.form.validate()) {
          if (this.type_document_payment_method != true) {
            this.payment_method = null
          }

          if (this.deondevemosartigos == 1) {
            let documentocriadonoerp = {}
            this.progressacriarfatura = true

            //iniciar logs invoices//iniciar logs invoices//iniciar logs invoices
            //iniciar logs invoices//iniciar logs invoices//iniciar logs invoices
            const dataagora = new Date()
            const iniciarinvoicelogs = {
              start: this.formatDateTime(dataagora),
              end: this.formatDateTime(dataagora),
              description: 'Logs documentos inseridos'
            }
            const iniciodeinvoicelogs = (await ServiceInvoiceLogs.createUpdate(iniciarinvoicelogs)).data

            // se vai para o ggest// se vai para o ggest// se vai para o ggest// se vai para o ggest
            // se vai para o ggest// se vai para o ggest// se vai para o ggest// se vai para o ggest
            if (this.sacofaturarvalue === false && this.$store.state.configuracoeserp.ativo === true) {
              let estadologs = 0

              //iniciar linha de logs invoices//iniciar linha de logs invoices//iniciar linha de logs invoices
              //iniciar linha de logs invoices//iniciar linha de logs invoices//iniciar linha de logs invoices
              const iniciarinvoiceloglines = {
                ClientId: this.client.id,
                InvoiceLogId: iniciodeinvoicelogs.id
              }
              const iniciodeinvoiceloglines = (await ServiceInvoiceLogLines.createUpdate(iniciarinvoiceloglines)).data



              // Se na ficha do cliente o cliente tem cliente de faturação
              // Se na ficha do cliente o cliente tem cliente de faturação
              if (this.client.erp_billing_number != null) {
                const atualizarinvoiceloglines = {
                  id: iniciodeinvoiceloglines.id,
                  erp_billing_number: true
                }
                await ServiceInvoiceLogLines.createUpdate(atualizarinvoiceloglines)
                estadologs = 1
              }


              if (estadologs === 1) {
                //se fez login no erp
                //se fez login no erp
                const response = await ServiceLogin.login(process.env.VUE_APP_API_USER_GGEST, process.env.VUE_APP_API_PASS_GGEST);
                if (response.status === 200) {
                  this.$store.dispatch("setTokenGgest", response.data.token);
                  const atualizarinvoiceloglines = {
                    id: iniciodeinvoiceloglines.id,
                    erp_login: true
                  }
                  await ServiceInvoiceLogLines.createUpdate(atualizarinvoiceloglines)
                  estadologs = 2
                }
              }


              let clientsggest = null
              if (estadologs === 2) {
                //ir buscar esse cliente ao erp e colocar os dados em baixo
                //ir buscar esse cliente ao erp e colocar os dados em baixo
                clientsggest = await ServiceListarClientes.listarClientes(this.client.erp_billing_number)
                if (clientsggest.data.dados.length && clientsggest.data.dados[0] != undefined) {
                  const atualizarinvoiceloglines = {
                    id: iniciodeinvoiceloglines.id,
                    erp_billing_number_valid: true
                  }
                  await ServiceInvoiceLogLines.createUpdate(atualizarinvoiceloglines)
                  estadologs = 3
                }
              }



              let resultadosartigosvalidos = null
              if (estadologs === 3) {
                //fazer um distinct dos artigos que estão no array para ir ao erp ver se são válidos
                //fazer um distinct dos artigos que estão no array para ir ao erp ver se são válidos
                const artigosUnicos = this.arrayArtigosFaturar.filter((artigo, index, self) =>
                    index === self.findIndex(
                        (a) => a.TypeeventArticlecodeerp === artigo.TypeeventArticlecodeerp
                    )
                );
                // ir ao erp ver se todos os artigos são válidos
                // ir ao erp ver se todos os artigos são válidos
                resultadosartigosvalidos = await Promise.all(
                  artigosUnicos.map(async (artigo) => {
                    const artigoggest = await ServiceArtigos.listarArtigosCodigo(artigo.TypeeventArticlecodeerp)
                    if (artigoggest.data.dados[0] === undefined) {
                      return {
                        resultado: false
                      };
                    }
                    return {
                      resultado: true,
                      codigo: artigoggest.data.dados[0].codigo,
                      iva: artigoggest.data.dados[0].iva1
                    };
                  })
                );
                const todosResultadosValidos = resultadosartigosvalidos.every((resultado) => resultado.resultado === true);
                if (todosResultadosValidos) {
                  const atualizarinvoiceloglines = {
                    id: iniciodeinvoiceloglines.id,
                    erp_all_articles_valid: true
                  }
                  await ServiceInvoiceLogLines.createUpdate(atualizarinvoiceloglines)
                  estadologs = 4
                }
              }


              let numerofaturagerada = ''
              let arraydeartigos = [];
              let arraydemetodopagamento = [];
              let numerolinha = 0;
              let totaldosartigosfatura = 0;
              if (estadologs === 4) {
                //criar linhas faturas
                //criar linhas faturas

                let iva = 0;
                this.arrayArtigosFaturar.forEach((artigo) => {
                  //ir buscar o iva do erp do artigo (está a pegar nos artigos distinct e ver o iva deles)
                  //ir buscar o iva do erp do artigo (está a pegar nos artigos distinct e ver o iva deles)
                  const artigoValido = resultadosartigosvalidos.find(
                    (artigoValido) => artigoValido.codigo === artigo.TypeeventArticlecodeerp
                  );
                  if (artigoValido) {
                    iva = artigoValido.iva
                  }
                  numerolinha = numerolinha + 1
                  arraydeartigos.push({
                    linha: numerolinha,
                    artigo: artigo.TypeeventArticlecodeerp,
                    quantidade: artigo.Quantity,
                    preco: artigo.Price,
                    data_mov: new Date().toISOString(),
                    cod_iva: iva,
                    descricao: artigo.TypeeventName
                    //descricao: artigo.TypeeventName + " " + artigo.PlanName + " - "  + artigo.Cycle
                  });

                  totaldosartigosfatura = totaldosartigosfatura + Number(artigo.Price)
                });

                //this.payment_method tenho de tirar o que está a seguir ao traço - NUM-1 vai sí NUM
                //Só faz isto se o type_document_payment_method === true que significa que o documento leva tipo de pagamento
                if (this.type_document_payment_method === true) {
                  arraydemetodopagamento = [
                    {
                      forma_pag: this.payment_method.split("-")[0],
                      valor: totaldosartigosfatura
                    }
                  ]
                }


                //criar fatura
                const faturaerp = {
                    tipodocumentoabreviatura: this.erptypedocumentabbreviation,
                    cliente_codigo: clientsggest.data.dados[0].codigo,
                    cliente_descricao: clientsggest.data.dados[0].descricao,
                    cliente_descricao2: clientsggest.data.dados[0].descricao2,
                    cliente_morada: clientsggest.data.dados[0].morada,
                    cliente_morada2: clientsggest.data.dados[0].morada2,
                    cliente_codpostal: clientsggest.data.dados[0].codpostal,
                    cliente_pais: clientsggest.data.dados[0].pais,
                    cliente_prefixo: clientsggest.data.dados[0].prefixo,
                    cliente_contribuinte: clientsggest.data.dados[0].contribuinte,
                    observacao: this.observations,
                    iva_inc: this.erpfaturasivaincluido,
                    moeda: this.erpmoeda,
                    posto: this.erpposto,
                    prazo_pagamento: clientsggest.data.dados[0].prazo_pagamento,
                    vendedor: clientsggest.data.dados[0].vendedor,
                    expedicao: clientsggest.data.dados[0].expedicao,
                    data_carga: this.formatDate(new Date()),
                    hora_carga: this.formatTime(new Date()),
                    morada_carga: clientsggest.data.dados[0].morada,
                    morada2_Carga: clientsggest.data.dados[0].morada2,
                    codpostal_carga: clientsggest.data.dados[0].codpostal,
                    pais_carga: clientsggest.data.dados[0].espaco_fiscal,
                    data_descarga: this.formatDate(new Date()),
                    hora_descarga: this.formatTime(new Date()),
                    morada_descarga: clientsggest.data.dados[0].morada,
                    morada2_descarga: clientsggest.data.dados[0].morada2,
                    codpostal_descarga: clientsggest.data.dados[0].codpostal,
                    pais_descarga: clientsggest.data.dados[0].espaco_fiscal,
                    linhas: arraydeartigos,
                    metododepagamento: arraydemetodopagamento
                }

                //criar documento no erp
                //criar documento no erp
                documentocriadonoerp = await ServiceDocumentos.gravarDocumento(faturaerp)
                if (documentocriadonoerp.status === 200) {
                  numerofaturagerada = documentocriadonoerp.data.doc_codigo + " " + documentocriadonoerp.data.doc_serie + "/" + documentocriadonoerp.data.doc_numero
                  const atualizarinvoiceloglines = {
                    id: iniciodeinvoiceloglines.id,
                    erp_create_document: true
                  }
                  await ServiceInvoiceLogLines.createUpdate(atualizarinvoiceloglines)
                  estadologs = 5
                }
              }


              let documentominhabd = null
              if (estadologs === 5) {
                //criar documento na minha bd
                //criar documento na minha bd
                documentominhabd = await ServiceInvoices.createinvoicecliente(this.client.id, this.arrayArtigosFaturar, this.payment_method, this.type_document, this.invoice_pay, numerofaturagerada, this.observations)
                if (documentominhabd.status === 200) {
                  const atualizarinvoiceloglines = {
                    id: iniciodeinvoiceloglines.id,
                    clinic_create_document: true
                  }
                  await ServiceInvoiceLogLines.createUpdate(atualizarinvoiceloglines)
                  estadologs = 6
                }
              }


              let modelodeimpressaodocumento = null
              if (estadologs === 6) {
                //ver no ggest qual é o modelo de impressão do documento criado
                //ver no ggest qual é o modelo de impressão do documento criado
                modelodeimpressaodocumento = await ServiceDocumentos.listarModelosDocumento(this.type_document, this.erpposto)
                if (modelodeimpressaodocumento.status === 200) {
                  const atualizarinvoiceloglines = {
                    id: iniciodeinvoiceloglines.id,
                    erp_print_template_valid: true
                  }
                  await ServiceInvoiceLogLines.createUpdate(atualizarinvoiceloglines)
                  estadologs = 7
                }
              }


              let base64 = ''
              let nomeficheiro = ''
              let idinvoice = ''
              if (estadologs === 7) {
                //enviar dados para o ggest para gerar pdf
                //enviar dados para o ggest para gerar pdf
                const gerarpdfggest = {
                  posto: this.erpposto,
                  modelo_codigo: modelodeimpressaodocumento.data.dados[0].codigo,
                  modelo_numero_vias: modelodeimpressaodocumento.data.dados[0].numero_vias,
                  modelo_iniciar_via: modelodeimpressaodocumento.data.dados[0].iniciar_via,
                  doc_codigo: documentocriadonoerp.data.doc_codigo,
                  doc_numero: documentocriadonoerp.data.doc_numero,
                  doc_serie: documentocriadonoerp.data.doc_serie,
                  doc_ano: documentocriadonoerp.data.doc_ano,
                  doc_entidade: documentocriadonoerp.data.doc_entidade
                }
                // ir buscar o documento em base64
                const pdfggestbase = await ServiceDocumentos.imprimirDocumento(gerarpdfggest)
                if (pdfggestbase.status === 200) {
                  const atualizarinvoiceloglines = {
                    id: iniciodeinvoiceloglines.id,
                    erp_base64_valid: true
                  }
                  await ServiceInvoiceLogLines.createUpdate(atualizarinvoiceloglines)
                  base64 = pdfggestbase.data.pdf
                  nomeficheiro = numerofaturagerada
                  idinvoice = documentominhabd.data.id
                  estadologs = 8
                }
              }


              if (estadologs === 8) {
                //verificar se o email é válido
                if (this.documentosporemailvalue === true && this.client.email != '' && this.client.email != null) {
                    //enviar email
                    //enviar email
                    const emailenviado = await ServiceInvoices.addBase64PdfEmail(base64, nomeficheiro, idinvoice, this.client.email)
                    // se o base64 foi atualizado na invoice
                    // se o base64 foi atualizado na invoice
                    if (emailenviado.data.statusbase64updateinvoice === 200) {
                      const atualizarinvoiceloglines = {
                          id: iniciodeinvoiceloglines.id,
                          clinic_base64_valid: true
                      }
                      await ServiceInvoiceLogLines.createUpdate(atualizarinvoiceloglines)
                    }
                    // se o email foi enviado
                    // se o email foi enviado
                    if (emailenviado.data.statusemailenviado === 200) {
                      const atualizarinvoiceloglines = {
                          id: iniciodeinvoiceloglines.id,
                          invite_document_email: true
                      }
                      await ServiceInvoiceLogLines.createUpdate(atualizarinvoiceloglines)
                    }
                } else {
                    //se o base64 foi adicionado á invoice
                    //se o base64 foi adicionado á invoice
                    const base64adionado = await ServiceInvoices.addBase64Pdf(base64, idinvoice)
                    if (base64adionado.status === 200) {
                      const atualizarinvoiceloglines = {
                        id: iniciodeinvoiceloglines.id,
                        clinic_base64_valid: true
                      }
                      await ServiceInvoiceLogLines.createUpdate(atualizarinvoiceloglines)
                    }
                }
              }
              arraydeartigos = [];
              numerolinha = 0;
              estadologs = 0;

            } else {
              // se não vai para o ggest// se não vai para o ggest// se não vai para o ggest
              // se não vai para o ggest// se não vai para o ggest// se não vai para o ggest

              //iniciar linha de logs invoices//iniciar linha de logs invoices//iniciar linha de logs invoices
              //iniciar linha de logs invoices//iniciar linha de logs invoices//iniciar linha de logs invoices
              const iniciarinvoiceloglines = {
                ClientId: this.client.id,
                InvoiceLogId: iniciodeinvoicelogs.id
              }
              const iniciodeinvoiceloglines = (await ServiceInvoiceLogLines.createUpdate(iniciarinvoiceloglines)).data

              // introduzir fatura na minha base de dados// introduzir fatura na minha base de dados// introduzir fatura na minha base de dados
              // introduzir fatura na minha base de dados// introduzir fatura na minha base de dados// introduzir fatura na minha base de dados
              const numerofaturagerada = 1
              const documentominhabd = await ServiceInvoices.createinvoicecliente(this.client.id, this.arrayArtigosFaturar, this.payment_method, this.type_document, this.invoice_pay, numerofaturagerada, this.observations);
              if (documentominhabd.status === 200) {
                const atualizarinvoiceloglines = {
                  id: iniciodeinvoiceloglines.id,
                  clinic_create_document: true
                }
                await ServiceInvoiceLogLines.createUpdate(atualizarinvoiceloglines)
              }
            }
            //finalizar logs invoices//finalizar logs invoices//finalizar logs invoices
            //finalizar logs invoices//finalizar logs invoices//finalizar logs invoices
            const dataagora2 = new Date()
            const finalizarinvoicelogs = {
              id: iniciodeinvoicelogs.id,
              end: this.formatDateTime(dataagora2)
            }
            await ServiceInvoiceLogs.createUpdate(finalizarinvoicelogs)
            this.$emit('handleInvoice');
            this.show = false;
            this.onDialogClose2(this.show)
          } else if (this.deondevemosartigos == 2) {
            let documentocriadonoerp = {}
            this.progressacriarfatura = true
            const gruposPorCliente = this.arrayArtigosFaturar.reduce((acc, item) => {
                const clientid = item.ClientId;
                if (!acc[clientid]) {
                    acc[clientid] = {
                        clientId: clientid,
                        clientName: item.ClientName,
                        artigos: []
                    };
                }
                acc[clientid].artigos.push(item);
                return acc;
            }, {});

            //iniciar logs invoices//iniciar logs invoices//iniciar logs invoices
            //iniciar logs invoices//iniciar logs invoices//iniciar logs invoices
            const dataagora = new Date()
            const iniciarinvoicelogs = {
              start: this.formatDateTime(dataagora),
              end: this.formatDateTime(dataagora),
              description: 'Logs documentos inseridos'
            }
            const iniciodeinvoicelogs = (await ServiceInvoiceLogs.createUpdate(iniciarinvoicelogs)).data

            for (const clientid in gruposPorCliente) {
                const cliente = gruposPorCliente[clientid];
                const itemsDoCliente = cliente.artigos

                // se vai para o ggest// se vai para o ggest// se vai para o ggest// se vai para o ggest
                // se vai para o ggest// se vai para o ggest// se vai para o ggest// se vai para o ggest
                if (this.sacofaturarvalue === false && this.$store.state.configuracoeserp.ativo === true) {
                  let estadologs = 0

                  //tenho de ir buscar o cliente com este id
                  const clientedados = (await ServiceClients.show(cliente.clientId)).data
                  // se vai para o ggest

                  //iniciar linha de logs invoices//iniciar linha de logs invoices//iniciar linha de logs invoices
                  //iniciar linha de logs invoices//iniciar linha de logs invoices//iniciar linha de logs invoices
                  const iniciarinvoiceloglines = {
                    ClientId: clientedados.id,
                    InvoiceLogId: iniciodeinvoicelogs.id
                  }
                  const iniciodeinvoiceloglines = (await ServiceInvoiceLogLines.createUpdate(iniciarinvoiceloglines)).data

                  // Se na ficha do cliente o cliente tem cliente de faturação
                  // Se na ficha do cliente o cliente tem cliente de faturação
                  if (clientedados.erp_billing_number != null) {
                    const atualizarinvoiceloglines = {
                      id: iniciodeinvoiceloglines.id,
                      erp_billing_number: true
                    }
                    await ServiceInvoiceLogLines.createUpdate(atualizarinvoiceloglines)
                    estadologs = 1
                  }


                  if (estadologs === 1) {
                    //se fez login no erp
                    //se fez login no erp
                    const response = await ServiceLogin.login(process.env.VUE_APP_API_USER_GGEST, process.env.VUE_APP_API_PASS_GGEST);
                    if (response.status === 200) {
                      this.$store.dispatch("setTokenGgest", response.data.token);
                      const atualizarinvoiceloglines = {
                        id: iniciodeinvoiceloglines.id,
                        erp_login: true
                      }
                      await ServiceInvoiceLogLines.createUpdate(atualizarinvoiceloglines)
                      estadologs = 2
                    }
                  }


                  let clientsggest = null
                  if (estadologs === 2) {
                    //ir buscar esse cliente ao erp e colocar os dados em baixo
                    //ir buscar esse cliente ao erp e colocar os dados em baixo
                    clientsggest = await ServiceListarClientes.listarClientes(clientedados.erp_billing_number)
                    if (clientsggest.data.dados.length === 1 && clientsggest.data.dados[0] != undefined && estadologs === 2) {
                      const atualizarinvoiceloglines = {
                        id: iniciodeinvoiceloglines.id,
                        erp_billing_number_valid: true
                      }
                      await ServiceInvoiceLogLines.createUpdate(atualizarinvoiceloglines)
                      estadologs = 3
                    }
                  }


                  let resultadosartigosvalidos = null
                  if (estadologs === 3) {
                    //fazer um distinct dos artigos que estão no array para ir ao erp ver se são válidos
                    //fazer um distinct dos artigos que estão no array para ir ao erp ver se são válidos
                    const artigosUnicos = itemsDoCliente.filter((artigo, index, self) =>
                        index === self.findIndex(
                            (a) => a.TypeeventArticlecodeerp === artigo.TypeeventArticlecodeerp
                        )
                    );
                    // ir ao erp ver se todos os artigos são válidos
                    // ir ao erp ver se todos os artigos são válidos
                    resultadosartigosvalidos = await Promise.all(
                      artigosUnicos.map(async (artigo) => {
                        const artigoggest = await ServiceArtigos.listarArtigosCodigo(artigo.TypeeventArticlecodeerp)
                        if (artigoggest.data.dados[0] === undefined) {
                          return {
                            resultado: false
                          };
                        }
                        return {
                          resultado: true,
                          codigo: artigoggest.data.dados[0].codigo,
                          iva: artigoggest.data.dados[0].iva1
                        };
                      })
                    );
                    const todosResultadosValidos = resultadosartigosvalidos.every((resultado) => resultado.resultado === true);
                    if (todosResultadosValidos === true && estadologs === 3) {
                      const atualizarinvoiceloglines = {
                        id: iniciodeinvoiceloglines.id,
                        erp_all_articles_valid: true
                      }
                      await ServiceInvoiceLogLines.createUpdate(atualizarinvoiceloglines)
                      estadologs = 4
                    }
                  }


                  let numerofaturagerada = ''
                  let arraydeartigos = [];
                  let arraydemetodopagamento = [];
                  let numerolinha = 0;
                  let totaldosartigosfatura = 0;
                  if (estadologs === 4) {
                    //criar linhas faturas
                    //criar linhas faturas
                    let iva = 0;
                    itemsDoCliente.forEach((artigo) => {
                      //ir buscar o iva do erp do artigo (está a pegar nos artigos distinct e ver o iva deles)
                      //ir buscar o iva do erp do artigo (está a pegar nos artigos distinct e ver o iva deles)
                      const artigoValido = resultadosartigosvalidos.find(
                        (artigoValido) => artigoValido.codigo === artigo.TypeeventArticlecodeerp
                      );
                      if (artigoValido) {
                        iva = artigoValido.iva
                      }
                      numerolinha = numerolinha + 1
                      arraydeartigos.push({
                        linha: numerolinha,
                        artigo: artigo.TypeeventArticlecodeerp,
                        quantidade: artigo.Quantity,
                        preco: artigo.Price,
                        data_mov: new Date().toISOString(),
                        cod_iva: iva,
                        descricao: artigo.TypeeventName
                        //descricao: artigo.TypeeventName + " " + artigo.PlanName + " - "  + artigo.Cycle
                      });

                      totaldosartigosfatura = totaldosartigosfatura + Number(artigo.Price)
                    });

                    //this.payment_method tenho de tirar o que está a seguir ao traço - NUM-1 vai sí NUM
                    //Só faz isto se o type_document_payment_method === true que significa que o documento leva tipo de pagamento
                    if (this.type_document_payment_method === true) {
                      arraydemetodopagamento = [
                        {
                          forma_pag: this.payment_method.split("-")[0],
                          valor: totaldosartigosfatura
                        }
                      ]
                    }

                    //criar fatura
                    const faturaerp = {
                        tipodocumentoabreviatura: this.erptypedocumentabbreviation,
                        cliente_codigo: clientsggest.data.dados[0].codigo,
                        cliente_descricao: clientsggest.data.dados[0].descricao,
                        cliente_descricao2: clientsggest.data.dados[0].descricao2,
                        cliente_morada: clientsggest.data.dados[0].morada,
                        cliente_morada2: clientsggest.data.dados[0].morada2,
                        cliente_codpostal: clientsggest.data.dados[0].codpostal,
                        cliente_pais: clientsggest.data.dados[0].pais,
                        cliente_prefixo: clientsggest.data.dados[0].prefixo,
                        cliente_contribuinte: clientsggest.data.dados[0].contribuinte,
                        observacao: this.observations,
                        iva_inc: this.erpfaturasivaincluido,
                        moeda: this.erpmoeda,
                        posto: this.erpposto,
                        prazo_pagamento: clientsggest.data.dados[0].prazo_pagamento,
                        vendedor: clientsggest.data.dados[0].vendedor,
                        expedicao: clientsggest.data.dados[0].expedicao,
                        data_carga: this.formatDate(new Date()),
                        hora_carga: this.formatTime(new Date()),
                        morada_carga: clientsggest.data.dados[0].morada,
                        morada2_Carga: clientsggest.data.dados[0].morada2,
                        codpostal_carga: clientsggest.data.dados[0].codpostal,
                        pais_carga: clientsggest.data.dados[0].espaco_fiscal,
                        data_descarga: this.formatDate(new Date()),
                        hora_descarga: this.formatTime(new Date()),
                        morada_descarga: clientsggest.data.dados[0].morada,
                        morada2_descarga: clientsggest.data.dados[0].morada2,
                        codpostal_descarga: clientsggest.data.dados[0].codpostal,
                        pais_descarga: clientsggest.data.dados[0].espaco_fiscal,
                        linhas: arraydeartigos,
                        metododepagamento: arraydemetodopagamento
                    }

                    //criar documento no erp
                    //criar documento no erp
                    documentocriadonoerp = await ServiceDocumentos.gravarDocumento(faturaerp)
                    if (documentocriadonoerp.status === 200) {
                      numerofaturagerada = documentocriadonoerp.data.doc_codigo + " " + documentocriadonoerp.data.doc_serie + "/" + documentocriadonoerp.data.doc_numero
                      const atualizarinvoiceloglines = {
                        id: iniciodeinvoiceloglines.id,
                        erp_create_document: true
                      }
                      await ServiceInvoiceLogLines.createUpdate(atualizarinvoiceloglines)
                      estadologs = 5
                    }
                  }


                  let documentominhabd = null
                  if (estadologs === 5) {
                    //criar documento na minha bd
                    //criar documento na minha bd
                    documentominhabd = await ServiceInvoices.createinvoicecliente(cliente.clientId, itemsDoCliente, this.payment_method, this.type_document, this.invoice_pay, numerofaturagerada, this.observations)
                    if (documentominhabd.status === 200) {
                      const atualizarinvoiceloglines = {
                        id: iniciodeinvoiceloglines.id,
                        clinic_create_document: true
                      }
                      await ServiceInvoiceLogLines.createUpdate(atualizarinvoiceloglines)
                      estadologs = 6
                    }
                  }


                  let modelodeimpressaodocumento = null
                  if (estadologs === 6) {
                    //ver no ggest qual é o modelo de impressão do documento criado
                    //ver no ggest qual é o modelo de impressão do documento criado
                    modelodeimpressaodocumento = await ServiceDocumentos.listarModelosDocumento(this.type_document, this.erpposto)
                    if (modelodeimpressaodocumento.status === 200) {
                      const atualizarinvoiceloglines = {
                        id: iniciodeinvoiceloglines.id,
                        erp_print_template_valid: true
                      }
                      await ServiceInvoiceLogLines.createUpdate(atualizarinvoiceloglines)
                      estadologs = 7
                    }
                  }



                  let base64 = ''
                  let nomeficheiro = ''
                  let idinvoice = ''
                  if (estadologs === 7) {
                    //enviar dados para o ggest para gerar pdf
                    //enviar dados para o ggest para gerar pdf
                    const gerarpdfggest = {
                      posto: this.erpposto,
                      modelo_codigo: modelodeimpressaodocumento.data.dados[0].codigo,
                      modelo_numero_vias: modelodeimpressaodocumento.data.dados[0].numero_vias,
                      modelo_iniciar_via: modelodeimpressaodocumento.data.dados[0].iniciar_via,
                      doc_codigo: documentocriadonoerp.data.doc_codigo,
                      doc_numero: documentocriadonoerp.data.doc_numero,
                      doc_serie: documentocriadonoerp.data.doc_serie,
                      doc_ano: documentocriadonoerp.data.doc_ano,
                      doc_entidade: documentocriadonoerp.data.doc_entidade
                    }
                    // ir buscar o documento em base64
                    const pdfggestbase = await ServiceDocumentos.imprimirDocumento(gerarpdfggest)
                    if (pdfggestbase.status === 200) {
                      const atualizarinvoiceloglines = {
                        id: iniciodeinvoiceloglines.id,
                        erp_base64_valid: true
                      }
                      await ServiceInvoiceLogLines.createUpdate(atualizarinvoiceloglines)
                      base64 = pdfggestbase.data.pdf
                      nomeficheiro = numerofaturagerada
                      idinvoice = documentominhabd.data.id
                      estadologs = 8
                    }
                  }



                  if (estadologs === 8) {
                    //verificar se o email é válido
                    if (this.documentosporemailvalue === true && clientedados.email != '' && clientedados.email != null) {
                      //enviar email
                      //enviar email
                      const emailenviado = await ServiceInvoices.addBase64PdfEmail(base64, nomeficheiro, idinvoice, clientedados.email)
                      // se o base64 foi atualizado na invoice
                      // se o base64 foi atualizado na invoice
                      if (emailenviado.data.statusbase64updateinvoice === 200) {
                        const atualizarinvoiceloglines = {
                            id: iniciodeinvoiceloglines.id,
                            clinic_base64_valid: true
                        }
                        await ServiceInvoiceLogLines.createUpdate(atualizarinvoiceloglines)
                      }
                      // se o email foi enviado
                      // se o email foi enviado
                      if (emailenviado.data.statusemailenviado === 200) {
                        const atualizarinvoiceloglines = {
                            id: iniciodeinvoiceloglines.id,
                            invite_document_email: true
                        }
                        await ServiceInvoiceLogLines.createUpdate(atualizarinvoiceloglines)
                      }
                    } else {
                      //se o base64 foi adicionado á invoice
                      //se o base64 foi adicionado á invoice
                      const base64adionado = await ServiceInvoices.addBase64Pdf(base64, idinvoice)
                      if (base64adionado.status === 200) {
                        const atualizarinvoiceloglines = {
                          id: iniciodeinvoiceloglines.id,
                          clinic_base64_valid: true
                        }
                        await ServiceInvoiceLogLines.createUpdate(atualizarinvoiceloglines)
                      }
                    }
                  }
                  arraydeartigos = [];
                  numerolinha = 0;
                  estadologs = 0;

                } else {
                  // se não vai para o ggest// se não vai para o ggest// se não vai para o ggest
                  // se não vai para o ggest// se não vai para o ggest// se não vai para o ggest

                  //iniciar linha de logs invoices//iniciar linha de logs invoices//iniciar linha de logs invoices
                  //iniciar linha de logs invoices//iniciar linha de logs invoices//iniciar linha de logs invoices
                  const iniciarinvoiceloglines = {
                    ClientId: cliente.clientId,
                    InvoiceLogId: iniciodeinvoicelogs.id
                  }
                  const iniciodeinvoiceloglines = (await ServiceInvoiceLogLines.createUpdate(iniciarinvoiceloglines)).data

                  // introduzir fatura na minha base de dados// introduzir fatura na minha base de dados// introduzir fatura na minha base de dados
                  // introduzir fatura na minha base de dados// introduzir fatura na minha base de dados// introduzir fatura na minha base de dados
                  const numerofaturagerada = 1
                  const documentominhabd = await ServiceInvoices.createinvoicecliente(cliente.clientId, itemsDoCliente, this.payment_method, this.type_document, this.invoice_pay, numerofaturagerada, this.observations);
                  if (documentominhabd.status === 200) {
                    const atualizarinvoiceloglines = {
                      id: iniciodeinvoiceloglines.id,
                      clinic_create_document: true
                    }
                    await ServiceInvoiceLogLines.createUpdate(atualizarinvoiceloglines)
                  }
                }
            }
            //finalizar logs invoices//finalizar logs invoices//finalizar logs invoices
            //finalizar logs invoices//finalizar logs invoices//finalizar logs invoices
            const dataagora2 = new Date()
            const finalizarinvoicelogs = {
              id: iniciodeinvoicelogs.id,
              end: this.formatDateTime(dataagora2)
            }
            await ServiceInvoiceLogs.createUpdate(finalizarinvoicelogs)
            this.$emit('handleInvoice');
            this.show = false;
            this.onDialogClose2(this.show)
            this.$store.dispatch("setSnackbar", {});
          }
        }

    },
    async typeDocument (item) {
      this.type_document_name = item.text
      this.type_document = item.document_abbreviation
      this.type_document_payment_method = item.payment_methods
      this.invoice_pay = item.pay
      this.erptypedocumentabbreviation = item.document_abbreviation
    },
    onDialogClose2(isOpen) {
      if (!isOpen) {
        this.arrayArtigosFaturar = null
        this.price = null
        this.type_document = null
        this.payment_method = null
        this.type_document_payment_method = null
        this.type_document_name = null
        this.observations = ''
        this.erptypedocumentabbreviation = null
        this.invoice_pay = null
        this.progressacriarfatura = false
        this.sacofaturarvalue = false
        this.documentosporemailvalue =  false
      }
    },
  },
  computed: {
    ...mapState(["snackbar", "user", 'isUserLoggedIn', 'title']),
  },
  async mounted() {
    this.payment_methods = config.PAYMENT_METHODS;
    this.type_documents = config.TYPE_DOCUMENT;
    this.erpfaturasivaincluido = config.FATURAS_ERP_IVA_INCLUIDO
    this.erpposto = config.POSTO_ERP
    this.erpmoeda = config.POSTO_MOEDA
    this.sacofaturar = config.SACO_FATURAR
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
