import Api from '@/services/ServiceBase'
const path = 'clients'

export default {
  add(client) {
    return Api().post(path, client)
  },
  list(name, page, discontinued, nif, email, phone, externalID, gender, number) {
    return Api().get(path, {
      params: {
        name,
        page,
        discontinued,
        nif,
        email,
        phone,
        externalID,
        gender,
        number
      }
    })
  },
  listt() {
    return Api().get(path + '/listt', {})
  },
  listDebounce(search) {
    return Api().get(path + `/debounce`, {
      params: {
        search
      }
    })
  },
  show(id) {
    return Api().get(path + `/${id}`)
  },
  showExternal(externalID) {
    return Api().get(path + `/showExternal/${externalID}`)
  },
  showNif(contribuinte) {
    return Api().get(path + `/showNif/${contribuinte}`)
  },
  getClientObservations(clientId) {
    return Api().get(path + `/observation/list/${clientId}`)
  },
  // put(client) {
  //   return Api().put(path + `/${client.id}`, client)
  // },
  createUpdateObservation(observation) {
    return Api().post(path + `/observation/createUpdate`, observation)
  },
  delete(clientId) {
    return Api().get(path + '/delete' + `/${clientId}`)
  },
  deleteObservation (observationId) {
    return Api().delete(path + `/observation/delete/${observationId}`)
  },
  getClientAttachments(clientId) {
    return Api().get(path + `/attachment/list/${clientId}`)
  },
  createUpdateAttachment(attachment, idcliente) {
    return Api().post(path + `/attachment/createUpdate`, attachment, {
      params: {
        idcliente
      }
    })
  },
  deleteAttachment (attachmentId, file) {
    return Api().delete(path + `/attachment/delete/${attachmentId}`,{
      params: { file: file }
    })
  },
  listClientsMarcacao(clients, eventid) {
    return Api().get(path + `/marcacao/list`, {
      params: {
        clients,
        eventid
      }
    })
  },
}
