import Api from "@/services/ServiceBase";
const path = "invoicelogemails";

export default {
  list(idinvoice) {
    return Api().get(path, {
      params: {
        idinvoice,
      },
    });
  },
};
