import Api from '@/services/ServiceBase'
const path = 'invoices'

export default {
  createinvoicecliente(clientid, arrayartigosfaturar, payment_method, type_document, invoice_pay, numerofaturagerada, observacoes) {
    return Api().post(path + '/createinvoicecliente', {
      params: {
        clientid,
        arrayartigosfaturar,
        payment_method,
        type_document,
        invoice_pay,
        numerofaturagerada,
        observacoes
      }
    })
  },
  listinvoices(page, data_primeiro_documento, data_ultimo_documento, clientId, payment_method, typeevent, number_invoice, pay) {
    return Api().get(path + '/listinvoices', {
      params: {
        page,
        data_primeiro_documento,
        data_ultimo_documento,
        clientId,
        payment_method,
        typeevent,
        number_invoice,
        pay
      }
    })
  },
  listarticlesnotinvoiced(page, clientid, data_primeiro_documento, data_ultimo_documento, planid) {
    return Api().get(path + '/listarticlesnotinvoiced', {
      params: {
        page,
        clientid,
        data_primeiro_documento,
        data_ultimo_documento,
        planid
      }
    })
  },
  listlinesinvoices(invoiceid) {
    return Api().get(path + '/listlinesinvoices', {
      params: {
        invoiceid
      }
    })
  },
  listtotalfaturado (data_primeiro_documento, data_ultimo_documento, clientId, payment_method, typeevent, number_invoice, pay) {
    return Api().get(path + '/listtotalfaturado', {
      params: {
        data_primeiro_documento,
        data_ultimo_documento,
        clientId,
        payment_method,
        typeevent,
        number_invoice,
        pay
      }
    })
  },
  createReceiptInvoice(item, payment_method_select) {
    return Api().post(path + '/createreceiptonvoice', item, {
      params: {
        payment_method_select
      }
    })
  },
  addBase64Pdf(base64, idinvoice) {
    return Api().post(path + '/addbase64pdf', {
      params: {
        base64,
        idinvoice
      }
    })
  },
  addBase64PdfEmail(base64, nomeficheiro, idinvoice, emailclient) {
    return Api().post(path + '/addbase64pdfemail', {
      params: {
        base64,
        nomeficheiro,
        idinvoice,
        emailclient
      }
    })
  },
  createPdfInvoice(base64, nomeficheiro, idinvoice, idcliente) {
    return Api().post(path + '/createpdfinvoice', {
      params: {
        base64,
        nomeficheiro,
        idinvoice,
        idcliente
      }
    })
  },
  invitePdfEmail(idinvoice, emailclient, nomeficheiro) {
    return Api().post(path + '/invitepdfemail', {
      params: {
        idinvoice,
        emailclient,
        nomeficheiro
      }
    })
  },
}
