import Api from '@/services/ServiceBase'
const path = 'clienttypeevents'

export default {
  tiposservicocliente (idcliente, idtypeevent) {
    return Api().get(path + '/tiposservicocliente',{
      params: {
        idcliente,
        idtypeevent
      }
    })
  },
  addupdate(idtypeevent, price) {
    return Api().post(path, {
      params: {
        price,
        idtypeevent
      }
    })
  },
}
