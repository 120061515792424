import Api from '@/services/ServiceBase'
const path = 'users'

export default {
  add (user) {
    return Api().post(path, user)
  },
  list (name,discontinued,page,userName,externalID) {
    return Api().get(path, {
        params: {
          name,
          discontinued,
          page: page,
          userName,
          externalID
        }
      })
  },
  changePassword(newPassword) {
      return Api().post(path + `/changePassword`, {
          newPassword
      })
  },
  show(id){
      return Api().get(path + `/${id}`)
  },
  put (user) {
    return Api().put(path + `/${user.id}`, user)
  },
  delete (user) {
    return Api().get(path + '/delete' + `/${user.id}`)
  },
  calendar () {
    return Api().get(path + `/calendar`)
  },
  getUsedColors()
  {
    return Api().get(path + `/getUsedColors`)
  },
  login(userName,pass)
  {
    return Api().get(path + `/login`,{
        params: {
          userName: userName,
          password: pass
        }
      })
  },
}
