<template>
  <v-card>
    <v-card-title class="pa-0">
      <v-toolbar dark color="primary">

        <v-toolbar-title>{{ crm.id == undefined ? 'Adicionar' : 'Editar' }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="crm.name" :rules="[rules.required, rules.counter]" :counter="250"
                label="Nome" required></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field type="number" v-model="crm.number_utente" label="Numero utente (CC)"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete label="Responsável" v-model="crm.UserId" :items="users" item-text="name" item-value="id" :rules="[rules.required]"></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field type="number" v-model="crm.phone" label="Telefone"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="crm.email" :rules="[rules.email]" label="E-mail"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-select v-model="crm.gender" :items="types_gender" label="Género" item-text="text" item-value="value" :rules="[rules.required]"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-select v-model="crm.find" :items="finds" label="Como nos conheceu" item-text="text" item-value="value"></v-select>
            </v-col>
            <v-col cols="12"  md="4">
              <v-autocomplete label="Tipo de serviço" v-model="crm.TypeeventId" :items="typeevents" item-text="name" item-value="id" @change="typeeventChange"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" v-if="crm.TypeeventId != null">
              <v-autocomplete label="Planos" v-model="crm.PlanId" :items="plans" item-text="Plan.name" item-value="Plan.id"></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-autocomplete label="Ultima pessoa que contactou" v-model="crm.status_UserId" :items="users" item-text="name" item-value="id" :rules="[rules.required]"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-select v-model="crm.status" :items="clientcrmstatus" label="Estado" item-text="text" item-value="value" :rules="[rules.required]"></v-select>
            </v-col>
            <v-col cols="12" md="4" v-if="crm.status == 'naoinscrito'">
              <v-text-field v-model="crm.status_not_register" label="Motivo da não inscrição"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea v-model="crm.observations" label="Observações"></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn outlined text color="red" @click="clear">Limpar</v-btn>
      <v-spacer></v-spacer>
      <v-btn outlined text color="primary" :disabled="!valid" @click="submit">Submeter</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ServiceCrms from "@/services/ServiceCrms";
import ServiceTypeevents from "@/services/ServiceTypeevents";
import ServicePlantypeevents from "@/services/ServicePlantypeevents";
import ServiceUsers from "@/services/ServiceUsers";
export default {
  name: "AddEdit-Crm",
  props: ['crm'],
  data() {
    return {
      valid: true,
      typeevents: [],
      users: [],
      finds: [],
      plans: [],
      clientcrmstatus: [],
      types_gender: [
        {text: 'Masculino', value: 'masculino'},
        {text: 'Feminino', value: 'feminino'},
        {text: 'Empresa', value: 'empresa'},
        {text: 'Sem género', value: 'semgenero'},
      ],
      rules: {
        required: value => !!value || "Campo Obrigatório.",
        email: value => {
          if (!value) {
            return true;
          }
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'E-mail inválido.';
        },
        counter: value =>
          (value && value.length <= 250) ||
          "Campo tem de ter menos de 250 caracteres",
        isValidNumber: value =>
          /^([0-9]+[.])?[0-9]+$/.test(value) || "Valor inválido"
      },
    };
  },
  methods: {
    async submit() {
      try {
        if (this.$refs.form.validate()) {
          this.loading = true;
          const response = await ServiceCrms.createUpdate(this.crm);

          if (!this.crm.id) {
            this.$refs.form.reset();
          }

          this.$emit('submited', response.data);

          this.loading = false;
          this.$store.dispatch("setSnackbar", {});
          this.close()
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    async typeeventChange(item) {
      this.plans = (await ServicePlantypeevents.getTypeeventPlans(null, item)).data
    },
    clear() {
      this.$refs.form.reset();
    },
    close() {
      this.$emit('close');
    }
  },
  async mounted() {
    this.finds = config.CLIENT_FIND;
    this.clientcrmstatus = config.CLIENT_CRM_STATUS;
    this.users = (await ServiceUsers.list()).data;
    this.typeevents = (await ServiceTypeevents.list(null, null, 1)).data;
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
