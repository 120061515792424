import Api from '@/services/ServiceBase'
const path = 'notes'

export default {
  addEditNote(item) {
    return Api().post(path, item)
  },
  delete(noteId) {
    return Api().delete(path + `/${noteId}`)
  },
}
