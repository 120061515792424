import Api from "@/services/ServiceBase";
const path = "dashboard";

export default {
  listclients(discontinued) {
    return Api().get(path + `/listclients`, {
      params: {
        discontinued,
      },
    });
  },

  listclientsondenosconheceu() {
    return Api().get(path + `/listclientsondenosconheceu`, {});
  },

  listeventsano() {
    return Api().get(path + `/listeventsano`, {});
  },
  listeventsanoatual(eventosano) {
    return Api().get(path + `/listeventsanoatual`, {
      params: {
        eventosano,
      },
    });
  },

  listclientsinscritospesquisa(datainicio, datafim) {
    return Api().get(path + `/listclientsinscritospesquisa`, {
      params: {
        datainicio,
        datafim,
      },
    });
  },

  listclientsdesistenciaspesquisa(datainicio, datafim) {
    return Api().get(path + `/listclientsdesistenciaspesquisa`, {
      params: {
        datainicio,
        datafim,
      },
    });
  },
  listclientsondenosconheceupesquisa(datainicio, datafim) {
    return Api().get(path + `/listclientsondenosconheceupesquisa`, {
      params: {
        datainicio,
        datafim,
      },
    });
  },
  listeventsnumeropesquisa(datainicio, datafim, clientId, userId) {
    return Api().get(path + `/listeventsnumeropesquisa`, {
      params: {
        datainicio,
        datafim,
        clientId,
        userId,
      },
    });
  },
  listeventstiposdeservicopesquisa(datainicio, datafim, clientId, userId) {
    return Api().get(path + `/listeventstiposdeservicopesquisa`, {
      params: {
        datainicio,
        datafim,
        clientId,
        userId,
      },
    });
  },
  listeventstiposdestatuspesquisa(datainicio, datafim, clientId, userId) {
    return Api().get(path + `/listeventstiposdestatuspesquisa`, {
      params: {
        datainicio,
        datafim,
        clientId,
        userId,
      },
    });
  },
  //faturação dos funcionarios
  listemployeebilling(datainicio, datafim) {
    return Api().get(path + `/listemployeebilling`, {
      params: {
        datainicio,
        datafim,
      },
    });
  },
};
