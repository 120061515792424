import Api from '@/services/ServiceBase'
const path = 'plantypeevents'

export default {
  getTypeeventPlans (idplano, idtypeevent) {
    return Api().get(path + '/gettypeeventplans',{
      params: {
        idplano,
        idtypeevent
      }
    })
  },
  createTypeeventPlans(item) {
    return Api().post(path, item)
  },
  updateTypeeventPlans (item) {
    return Api().put(path + `/${item.id}`, item)
  },
  deleteTypeeventPlans(typeeventplanId) {
    return Api().delete(path + `/${typeeventplanId}`)
  },
}
