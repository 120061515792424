<template>
  <v-card>
    <v-card-title class="pa-0">
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ user.id ? 'Editar Funcionário' : 'Adicionar Funcionário' }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-text-field v-model="user.userName" :rules="[rules.required, rules.counter]" :counter="250" label="Login"
                required></v-text-field>
            </v-col>
          </v-row>

          <!-- <v-row align="center" justify="center">
        <v-col cols="12" >
          <v-text-field v-if="$route.params.userid" v-model="user.password" :counter="25" label="Password" required>
          </v-text-field>
          <v-text-field v-else v-model="user.password" :rules="[rules.required]" :counter="25" label="Password"
            required></v-text-field>
        </v-col>
      </v-row> -->

          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-text-field autocomplete="new-password" v-model="user.password" :rules="[rules.counter2]" :counter="25" label="Password"
                type="password" required></v-text-field>
            </v-col>

            <!-- <v-col cols="12">
              <v-text-field :rules="[rules.required, rules.counter2]" id="confirmPassword" name="confirmPassword"
                label="Confirmação Password" type="password" v-model="confirmPass" :counter="25"></v-text-field>
            </v-col> -->

          </v-row>

          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-text-field v-model="user.name" :rules="[rules.required, rules.counter]" :counter="250" label="Nome"
                required></v-text-field>
            </v-col>
          </v-row>

          <v-row align="center" justify="center">
            <v-col cols="12" align="center" justify="center">
              <v-img v-if="papadipopo" :eager="true" :src="user.img" aspect-ratio="1" class="grey lighten-2"
                max-width="500" max-height="300" align="center" justify="center" style="position:relative;">
                <!-- <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-img :src="require('@/assets/placeholder.png')" aspect-ratio="1" class="grey lighten-2"
                      max-width="500" max-height="300"></v-img>

                  </v-row>
                </template> -->
                <v-btn color="primary" style="position:absolute; top:0; right:0;"
                  @click="$refs.inputUpload.click()"><v-icon>mdi-plus</v-icon></v-btn>
              </v-img>
              <input v-show="false" accept="image/*" ref="inputUpload" id="inputUpload" type="file" @change="AddFile()" />
            </v-col>
          </v-row>
          <!-- <v-row align="center" justify="center">
        <v-col cols="12" >
          <v-text-field v-model="user.cost" :rules="[rules.isValidNumber]" type="number" label="Custo" required>
          </v-text-field>
        </v-col>
      </v-row> -->

          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-select v-model="user.role" :items="roles" :rules="[rules.required]" item-text="text" required
                item-value="value" label="Nível de permissão"></v-select>
            </v-col>
          </v-row>

          <!-- <v-row align="center" justify="center">
        <v-col cols="12" >
          <v-select v-model="user.TaxId" required :items="taxes" label="Imposto" item-text="description" item-value="id"
            :rules="[rules.required]"></v-select>
        </v-col>
      </v-row> -->

          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-checkbox v-model="user.discontinued" label="Descontinuado"></v-checkbox>
            </v-col>
          </v-row>

          <!-- <v-row align="center" justify="center">
            <v-col cols="12" >
              <v-text-field v-model="user.externalID" label="ExternalID" :counter="250"></v-text-field>
            </v-col>
          </v-row> -->

          <v-row align="center" justify="center">
            <v-col cols="12" align="center" justify="center">
              <v-color-picker v-model="user.color" hide-canvas hide-inputs hide-mode-switch hide-sliders show-swatches
                :swatches="swatches" swatches-max-height="250"></v-color-picker>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn outlined text color="red" @click="clear">Limpar</v-btn>
      <v-spacer></v-spacer>
      <v-btn outlined text color="primary" :disabled="!valid" @click="submit">Submeter</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { dataURItoBlob } from "@/components/Utils";
import ServiceUsers from "@/services/ServiceUsers";
export default {
  name: "AddEdit-User",
  props: ['swatches', 'user'],
  data() {
    return {
      papadipopo: true,
      image: null,
      roles: [
        { text: "Administrador", value: "admin" },
        { text: "Funcionário", value: "employee" }
      ],
      valid: true,
      rules: {
        required: value => !!value || "Campo Obrigatório.",
        counter: value =>
          (value && value.length <= 250) ||
          "Campo tem de ter menos de 250 caracteres",
        counter2: value => {
          if (value) {
            if (value.length < 4) {
              return "Este campo tem de ter pelo menos 4 caracteres"
            }else
            {
              return true;
            }
          } else {
            return true;
          }
        },
        isValidNumber: value =>
          /^([0-9]+[.])?[0-9]+$/.test(value) || "Valor inválido"
      }
    };
  },
  methods: {
    test() {
      this.user.img = 'https://i.picsum.photos/id/634/510/300.jpg?hmac=cH_5Gv_UfyIZmfpVGxPbjYwoDCX1Gzz4qnUEFD5NLj8';
      this.papadipopo = false;
      this.papadipopo = true;
    },
    AddFile() {
      var filesize = (
        this.$refs.inputUpload.files[0].size /
        1024 /
        1024
      ).toFixed(4); // MB

      if (filesize > 2) {
        alert("Tamanho máximo do ficheiro é 2MB");
        return;
      }

      var reader = new FileReader();

      reader.addEventListener(
        "load",
        () => {
          this.user.img = reader.result;
          this.image = true;
          this.papadipopo = false;
          this.papadipopo = true;
        },
        false
      );

      if (this.$refs.inputUpload.files[0]) {
        reader.readAsDataURL(this.$refs.inputUpload.files[0]);
      }
    },
    async submit() {
      try {
        if (this.$refs.form.validate()) {
          // let response;

          let formData = new FormData();

          for (let [key, value] of Object.entries(this.user)) {
            if (value !== null && key !== "img") {
              formData.set(key, value);
            }
          }

          if (this.image === true) {
            formData.append(
              "file",
              dataURItoBlob(this.user.img),
              this.$refs.inputUpload.files[0].name
            );
          }
          this.loading = true;
          const response = await ServiceUsers.add(formData);

          this.image = false;

          if (!this.user.id) {
            this.$refs.form.reset();
          }

          this.$emit('submited', response.data);

          this.loading = false;
          this.$store.dispatch("setSnackbar", {});
          this.close()
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    clear() {
      this.$refs.form.reset();
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
