import Api from '@/services/ServiceBase'
const path = 'regulations'

export default {
  createUpdate (regulation) {
    return Api().post(path, regulation)
  },
  list(page, description) {
    return Api().get(path, {
      params: {
        page,
        description
      }
    })
  },
  show(id) {
    return Api().get(path + `/${id}`)
  },
  delete(regulationId, file) {
    return Api().get(path + `/delete/${regulationId}`,{
      params: { file: file }
    })
  },
}
