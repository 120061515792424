<template>
  <v-dialog v-model="show" scrollable width="1500" transition="dialog-bottom-transition">
    <v-card class="basicBackGround">
      <v-toolbar dark color="light-blue">
        <v-toolbar-title>Regulamentos/Emails: {{ client.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <div v-if="loading" align="center" justify="center">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </div>
        <v-col v-else cols="12">
          <v-data-table v-if="regulations.length != 0" :headers="headers" :items="regulationsclientenviado" hide-default-footer
            class="mTable" :items-per-page="regulationsclientenviado.length + 10">

            <template v-slot:[`item.enviado`]="{ item }">
              <v-icon v-if="!item.enviado" color="red darken-2">mdi-close</v-icon>
              <v-icon v-else color="green darken-2">mdi-check</v-icon>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon @click="enviarregulamento(item)">
                    <v-icon color="success">mdi-email-arrow-right</v-icon>
                  </v-btn>
                </template>
                <span>Enviar por email</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-card v-else class="elevation-0" align="center" justify="center" color="transparent">
            <v-icon size="200" color="red">
              mdi-file
            </v-icon>
            <v-card-title class="justify-center">Não há registos a apresentar.</v-card-title>
          </v-card>
        </v-col>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ServiceRegulations from "@/services/ServiceRegulations";
import ServiceClientregulations from "@/services/ServiceClientregulations";
export default {
  name: "ClientRegulation",
  data() {
    return {
      show: false,
      loading: false,
      client: {},
      regulations: [],
      regulationsclient: [],
      regulationsclientenviado: [],
      page: 1,
      headers: [
        { text: 'Descrição', value: 'description', sortable: false },
        { text: 'Enviado', value: 'enviado', sortable: false },
        { text: 'Ações', value: 'action', sortable: false },
      ],
    };
  },
  methods: {
    close() {
      this.show = false;
    },
    async open(client) {
      this.client = client;
      this.regulations = (await ServiceRegulations.list(this.page)).data;
      this.regulationsclient = (await ServiceClientregulations.regulamentoscliente(null, this.client.id)).data;
      this.show = true;
      this.loading = false;
      this.regulamentoeviado()
    },
    async enviarregulamento(regulation) {
      try {
        this.regulationsclient = (await ServiceClientregulations.addupdate(regulation.id, this.client.id)).data;
        this.regulamentoeviado()
        this.$store.dispatch("setSnackbar", {});

      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: 'Email não enviado (Email pode não ser válido)'
        });
      }
    },
    async regulamentoeviado () {
      this.regulationsclientenviado = this.regulations.map(item => {
          const associatedClient = this.regulationsclient.find(regulationclient => regulationclient.RegulationId === item.id);
          return {
              ...item,
              enviado: associatedClient ? true : false
          };
      });
    }
  },
  computed: {
  },
  async mounted() {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
