<template>
  <v-card>
    <v-card-title class="pa-0">
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ typeevent.id == undefined ? 'Adicionar' : 'Editar' }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-text-field v-model="typeevent.article_code_erp" :counter="250"
                label="Referência"></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-text-field v-model="typeevent.name" :rules="[rules.required, rules.counter]" :counter="250"
                label="Descrição" required></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-text-field type="number" v-model="typeevent.price" :counter="250" label="Preço (€)"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-dialog ref="toggleDurationTime" v-model="toggleDurationTime" :return-value.sync="typeevent.duration" persistent
                width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field clearable v-model="typeevent.duration" label="Duração do serviço"
                    prepend-icon="mdi-clock-outline" v-on="on"></v-text-field>
                </template>
                <v-time-picker locale="pt-pt" format="24hr" v-model="typeevent.duration" scrollable>
                  <v-btn text color="primary" @click="toggleDurationTime = false">Cancelar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="$refs.toggleDurationTime.save(typeevent.duration)">OK</v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-checkbox v-model="typeevent.in_event" label="Aparecer na marcação"></v-checkbox>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" v-if="typeevent.in_event">
            <v-col cols="12">
              <v-checkbox v-model="typeevent.client_one_time_event" label="Só existe uma marcação com este serviço por cliente"></v-checkbox>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-checkbox v-model="typeevent.invoice" label="Serviço para faturar"></v-checkbox>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-checkbox v-model="typeevent.automatically_create_covenant_client" label="Criar avença na ficha automaticamente"></v-checkbox>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" v-if="typeevent.automatically_create_covenant_client == true">
            <v-col cols="12">
              <v-autocomplete label="Tipo de cobrança" v-model="typeevent.type_covenant" :items="type_covenants" item-text="text" item-value="value"></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn outlined text color="red" @click="clear">Limpar</v-btn>
      <v-spacer></v-spacer>
      <v-btn outlined text color="primary" :disabled="!valid" @click="submit">Submeter</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ServiceTypeevents from "@/services/ServiceTypeevents";
export default {
  name: "AddEdit-Typeevent",
  props: ['typeevent'],
  data() {
    return {
      valid: true,
      toggleDurationTime: false,
      rules: {
        required: value => !!value || "Campo Obrigatório.",
        counter: value =>
          (value && value.length <= 250) ||
          "Campo tem de ter menos de 250 caracteres",
        isValidNumber: value =>
          /^([0-9]+[.])?[0-9]+$/.test(value) || "Valor inválido"
      },
      type_covenants: [
        { text: "Anual", value: "anual" },
        { text: "Mensal", value: "mensal" },
        { text: "Uma vez", value: "umavez" },
      ],
    };
  },
  methods: {
    async submit() {
      try {
        if (this.$refs.form.validate()) {
          if (this.typeevent.automatically_create_covenant_client == true) {
            if (this.typeevent.type_covenant == '') {
              this.typeevent.type_covenant = 'anual'
            }
          }
          this.loading = true;
          const response = await ServiceTypeevents.createUpdate(this.typeevent);

          if (!this.typeevent.id) {
            this.$refs.form.reset();
          }

          this.$emit('submited', response.data);

          this.loading = false;
          this.$store.dispatch("setSnackbar", {});
          this.close()
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    clear() {
      this.$refs.form.reset();
    },
    close() {
      this.$emit('close');
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
