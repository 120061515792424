var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","width":"1500","transition":"dialog-bottom-transition"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"basicBackGround"},[_c('v-toolbar',{attrs:{"dark":"","color":"light-blue"}},[_c('v-toolbar-title',[_vm._v("Marcações : "+_vm._s(_vm.client.name))]),_c('v-spacer'),(_vm.client.discontinued != true)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.eventDialog.open(null, _vm.client.id, _vm.client.name)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1),_c('v-card-text',[(_vm.loading)?_c('div',{attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1):_c('v-col',{attrs:{"cols":"12"}},[(_vm.appointments.length != 0)?_c('v-data-table',{ref:"mTimeLine",staticClass:"mTable",attrs:{"headers":_vm.headers,"items":_vm.appointments,"hide-default-footer":"","items-per-page":_vm.appointments.length + 10,"item-class":_vm.getRowClass},scopedSlots:_vm._u([{key:"item.userName",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"32px","tile":""}},[_c('img',{attrs:{"src":_vm.baseUrl + '/' + item.img,"alt":"Infordio"}})]),_vm._v(" "+_vm._s(item.userName)+" ")]}},{key:"item.start",fn:function(ref){
var item = ref.item;
return [(item === _vm.nextAppointment)?_c('tr',{ref:"nextAppointmentRow"}):_vm._e(),_vm._v(" "+_vm._s(_vm.formatDate(item.start))+" ")]}},{key:"item.end",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.end))+" ")]}},{key:"item.room",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.roomIdentification + ' - ' + item.roomName)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return _vm._l((item.ClientEvents),function(statusevent){return _c('div',{key:statusevent.status},[_c('v-icon',{staticClass:"pr-2",attrs:{"color":_vm.getStatus(statusevent.status).color,"small":""}},[_vm._v("mdi-circle")]),_vm._v(" "+_vm._s(_vm.getStatus(statusevent.status).text)+" ")],1)})}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""}},[_c('v-icon',_vm._g({attrs:{"color":"blue"},on:{"click":function($event){return _vm.$refs.eventDialog.open(item, _vm.client.id, _vm.client.name)}}},on),[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver marcação")])])]}}],null,true)}):_c('v-card',{staticClass:"elevation-0",attrs:{"align":"center","justify":"center","color":"transparent"}},[_c('v-icon',{attrs:{"size":"200","color":"red"}},[_vm._v(" mdi-file ")]),_c('v-card-title',{staticClass:"justify-center"},[_vm._v("Não há registos a apresentar.")])],1)],1)],1),_c('event-dialog',{ref:"eventDialog",attrs:{"rooms":_vm.rooms,"users":_vm.users,"typeevents":_vm.typeevents,"groups":_vm.groups},on:{"handleEvent":_vm.handleEvent,"handleDelete":_vm.handleDelete,"handleStatus":_vm.handleStatus}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }