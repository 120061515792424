<template>
  <v-dialog v-model="show" scrollable width="1500" transition="dialog-bottom-transition" @input="onDialogClose">
    <v-card>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card-title class="pa-0">
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{ client.id ? client_type_edit : client_type_add }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="show = !show">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12" md="8" v-if="client.datecontinued != null">
                <span style="font-size: 15px; font-weight: bold;">Data de Inscrição: {{ formatDate(client.datecontinued) }}</span>
              </v-col>
              <v-col cols="12" md="8" v-else>
              </v-col>
              <v-col cols="6" md="2">
                <v-checkbox v-model="client.discontinued" dense label="Inactivo"></v-checkbox>
              </v-col>
              <v-col cols="6" md="2">
                <v-checkbox v-model="client.rgpd_signature" dense label="Assinou RGPD"></v-checkbox>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="client.name" dense :rules="[rules.required, rules.counter]" :counter="250" label="Nome" required></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field type="number" dense v-model="client.nif" :rules="[rules.required, rules.counter]" :counter="250" label="NIF"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field type="number" dense v-model="client.phone" label="Telefone"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="client.email" dense :rules="[rules.email]" label="E-mail"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-select v-model="client.gender" dense :items="types_gender" label="Género" item-text="text" item-value="value" :rules="[rules.required]"></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-dialog ref="dialog" v-model="birthDateModal" :return-value.sync="client.birthDate" persistent width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="client.birthDate" dense label="Data de Nascimento" readonly v-bind="attrs"
                      v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="client.birthDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="birthDateModal = false">
                      Cancelar
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.dialog.save(client.birthDate)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3">
                <v-select v-model="client.find" dense :items="finds" label="Como nos conheceu" item-text="text" item-value="value"></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="client.occupation" dense label="Profissão"></v-text-field>
              </v-col>
              <v-col cols="12" md="3" v-if="client.discontinued == false">
                <v-dialog ref="dialog2" v-model="discontinuedModal" :return-value.sync="client.datediscontinued" persistent width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="client.datediscontinued" dense label="Data de inatividade do cliente" readonly v-bind="attrs"
                      v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="client.datediscontinued" scrollable>
                    <v-btn text color="primary" @click="client.datediscontinued = null">
                        Limpar
                      </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="discontinuedModal = false">
                      Cancelar
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.dialog2.save(client.datediscontinued)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" md="3" v-if="client.discontinued == true && client.datediscontinued != null">
                <span style="font-size: 15px; font-weight: bold;">Data de desistência: {{ client.datediscontinued }}</span>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="4">
                <v-text-field v-model="client.road" dense label="Rua/Número" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="client.address" dense label="Morada" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="client.postal_code" dense label="Código postal" :rules="[rules.required]"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12">
                <v-textarea v-model="client.observations" dense label="Observações"></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="text-center"><h3>Faturação</h3></v-col>
              <v-col cols="12" v-if="$store.state.configuracoeserp.ativo === true"><span v-if="clientserpapresentarnome != null">Faturação está com o cliente <b>{{ clientserpapresentarnome }}</b></span><br><span v-if="this.client.erp_billing_number != null && $store.state.configuracoeserp.ativo === true && client.id != undefined" style="color: red; font-weight: bold; cursor: pointer;" @click="removerFaturacao"> Remover faturação</span></v-col>
              <v-col cols="12" md="4" v-if="$store.state.configuracoeserp.ativo === true">
                <v-autocomplete dense style="padding-top: 0px; margin-top: 0px" ref="cbClient" label="Faturação" :filter="customFilter"
                  :loading="loadingClientsNome" v-debounce="newVal => myFnErpNome(newVal, 'v-text-field')" @input="selectClienteErpNome" v-model="selectedClientErpNome"
                  :items="clientserpnome" item-text="descricao" item-value="codigo"
                  clearable :return-object="true" class="mt-3" hint="Nome" persistent-hint></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4" v-if="$store.state.configuracoeserp.ativo === true">
                <v-autocomplete dense style="padding-top: 0px; margin-top: 0px" ref="cbClient" label="Faturação" :filter="customFilter"
                  :loading="loadingClientsNif" v-debounce="newVal => myFnErpNif(newVal, 'v-text-field')" @input="selectClienteErpNif" v-model="selectedClientErpNif"
                  :items="clientserpnif" item-text="contribuinte" item-value="codigo"
                  clearable :return-object="true" class="mt-3" hint="Contribuinte" persistent-hint></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete dense style="padding-top: 0px; margin-top: 0px" ref="cbClient" label="Método de pagamento pretendido"
                  v-model="client.payment_methods" :items="payment_methods" item-text="text" item-value="method_abbreviation"
                  clearable class="mt-3" hint="Dinheiro/Multibanco/MBWay/Transferência Bancária/Débito-Direto" persistent-hint></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-center"><h3>Outros dados</h3></v-col>
              <v-col cols="12" md="3">
                <v-text-field type="number" dense v-model="client.number_utente" label="Número utente"></v-text-field>
              </v-col>
            </v-row>

            <!-- <v-row align="center" justify="center">
        <v-col cols="12">
          <v-card-actions>
            <v-btn text color="red" outlined @click="clear">Limpar</v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="primary" outlined :loading="loading" :disabled="!valid" @click="submit">Submeter</v-btn>
          </v-card-actions>
        </v-col>
      </v-row> -->
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn text color="red" outlined @click="clear">Limpar</v-btn>
        <v-spacer></v-spacer>
        <v-checkbox v-if="criarclienteerp === true && $store.state.configuracoeserp.ativo == true" v-model="criarclienteerpvalue" dense label="Criar cliente no erp" class="mr-5"></v-checkbox>
        <v-btn text color="primary" outlined @click="submitCovenants" v-if="!client.id">Submeter e abrir avenças</v-btn>
        <v-btn text color="primary" outlined @click="submit">Submeter</v-btn>
      </v-card-actions>
    </v-card>
    <covenants ref="covenants" />
  </v-dialog>
</template>

<script>
import ServiceClients from "@/services/ServiceClients";
import Covenants from "@/components/client/Covenants.vue";
import ServiceCrms from "@/services/ServiceCrms";
import ServiceListarClientes from "@/serviceGgest/ServiceListarClientes";
import ServiceLogin from "@/serviceGgest/ServiceLogin";
// import backButton from '@/mixins/backButton';
export default {
  name: "AddEditClient",
  components: {
    Covenants,
  },
  // mixins: [
  //   backButton(['show'])
  // ],
  data() {
    return {
      birthDateModal: false,
      discontinuedModal: false,
      show: false,
      client: {},
      editDialog: false,
      loading: false,
      valid: true,
      client_type_add: '',
      client_type_edit: '',
      clientcrm: null,
      finds: [],
      payment_methods: [],
      types_gender: [
        {text: 'Masculino', value: 'masculino'},
        {text: 'Feminino', value: 'feminino'},
        {text: 'Empresa', value: 'empresa'},
        {text: 'Sem género', value: 'semgenero'},
      ],
      rules: {
        required: value => !!value || "Campo Obrigatório.",
        email: value => {
          if (!value) {
            return true;
          }
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'E-mail inválido.';
        },
        counter: value =>
          (value && value.length <= 250) ||
          "Campo tem de ter menos de 250 caracteres",
        isValidNumber: value =>
          /^([0-9]+[.])?[0-9]+$/.test(value) || "Valor inválido"
      },
      loadingClientsNome: false,
      loadingClientsNif: false,
      selectedClientErpNome: null,
      selectedClientErpNif: null,
      clientserpnome: [],
      clientserpnif: [],
      clientserpapresentarnome: null,
      criarclienteerp: false,
      criarclienteerpvalue: false,
      contribuinteantigo: null
    };
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
    async open(id) {
      this.show = true;
      this.client = {};
      this.valid = true;
      if (id) {
        try {
          this.loading = true;
          this.client = (await ServiceClients.show(id)).data;
          this.contribuinteantigo = this.client.nif
          if (this.$store.state.configuracoeserp.ativo === true) {
            if (this.client.externalID === null) {
              this.criarclienteerp = true
            } else {
              this.criarclienteerp = false
            }

            // ver o nome do cliente da faturação
            const response = await ServiceLogin.login(process.env.VUE_APP_API_USER_GGEST, process.env.VUE_APP_API_PASS_GGEST);
            this.$store.dispatch("setTokenGgest", response.data.token);
            if (this.client.erp_billing_number != null) {
              const clientserpfat = (await ServiceListarClientes.listarClientes(this.client.erp_billing_number)).data.dados
              if (clientserpfat[0] != undefined) {
                this.clientserpapresentarnome = clientserpfat[0].descricao + " - " + clientserpfat[0].contribuinte
              } else {
                this.clientserpapresentarnome = null
              }
            } else {
              this.clientserpapresentarnome = null
            }

          }


        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
        }
      } else {
        this.criarclienteerp = true
      }
      this.loading = false;
    },
    async openCrm(crm) {
      this.show = true;
      this.client = {};
      this.client.name = crm.name
      this.client.number_utente = crm.number_utente
      this.client.phone = crm.phone
      this.client.email = crm.email
      this.client.gender = crm.gender
      this.client.find = crm.find
      this.client.observations = ''
      if (crm.Typeevent != null && crm.Plan != null && crm.observations != null) {
        this.client.observations = crm.Typeevent.name + '\n' + crm.Plan.name + '\n' + crm.observations
      } else if (crm.Typeevent != null && crm.observations != null) {
        this.client.observations = crm.Typeevent.name + '\n' + crm.observations
      } else if (crm.observations != null) {
        this.client.observations = crm.observations
      } else if (crm.Typeevent != null) {
        this.client.observations = crm.Typeevent.name
      }

      this.criarclienteerp = true
      this.valid = true;
      this.loading = false;
      this.clientcrm = crm.id
    },
    async submit() {
      try {
        if (this.$refs.form.validate()) {
          let response2;
          this.loading = true;

          // if (this.client.Id) {
          //   response = await ServiceClients.put(this.client);
          // } else {
          //   response = await ServiceClients.add(this.client);
          //   this.$refs.form.reset();
          // }

          //verificar se contribuinte existe
          let verificarif = false
          if (this.contribuinteantigo != null && this.contribuinteantigo != this.client.nif) {
            verificarif = true
          }

          const clientenifexiste = (await ServiceClients.showNif(this.client.nif)).data;
          if (clientenifexiste.number != undefined && verificarif == true) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Já existe um cliente com esse contribuinte"
            });
          } else {
            if (this.$store.state.configuracoeserp.ativo === true) {
              if (this.criarclienteerpvalue === true) {
                if (this.$store.state.configuracoeserp.erp == 'ggest') {
                  const response = await ServiceLogin.login(process.env.VUE_APP_API_USER_GGEST, process.env.VUE_APP_API_PASS_GGEST);
                  this.$store.dispatch("setTokenGgest", response.data.token);

                  //criar cliente
                  const clienteerp = {
                      nome: this.client.name,
                      nif: this.client.nif,
                      telemovel: this.client.phone,
                      codigopostal: this.client.postal_code,
                      email: this.client.email,
                      address: this.client.address,
                      pais: 'PORTUGAL',
                      abreviaturapais: 'PT',
                      obs: this.client.observations,
                      inactivo: false,
                      espacofiscal: "PTC",
                      prazopagamento: "PR000",
                      expedicao: "NV",
                      vendedor: 1,
                      moeda: 'EUR',
                      preco: 1
                  }
                  const clientecriadonoerp = await ServiceListarClientes.criarCliente(clienteerp)
                  if (clientecriadonoerp.status === 200) {
                    this.client.number = clientecriadonoerp.data.dados[0].codigo
                    this.client.externalID = clientecriadonoerp.data.dados[0].codigo
                    this.client.erp_billing_number = clientecriadonoerp.data.dados[0].codigo
                    response2 = await ServiceClients.add(this.client);
                    this.$emit('submited', response2.data);

                    if (this.clientcrm != null) {
                      await ServiceCrms.delete(this.clientcrm);
                    }

                    this.$store.dispatch("setSnackbar", {});
                  }
                }
              } else {
                response2 = await ServiceClients.add(this.client);
                this.$emit('submited', response2.data);

                if (this.clientcrm != null) {
                  await ServiceCrms.delete(this.clientcrm);

                }
                this.$store.dispatch("setSnackbar", {});
              }
            } else {
              response2 = await ServiceClients.add(this.client);
              this.$emit('submited', response2.data);

              if (this.clientcrm != null) {
                await ServiceCrms.delete(this.clientcrm);

              }
              this.$store.dispatch("setSnackbar", {});
            }

            this.clientcrm == null
            this.selectedClientErpNome = null
            this.selectedClientErpNif = null
            this.criarclienteerpvalue = false
            this.show = false;
          }
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }

      this.loading = false;
    },
    async submitCovenants() {
      try {
        if (this.$refs.form.validate()) {
          let response;
          this.loading = true;

          // if (this.client.Id) {
          //   response = await ServiceClients.put(this.client);
          // } else {
          //   response = await ServiceClients.add(this.client);
          //   this.$refs.form.reset();
          // }

          response = await ServiceClients.add(this.client);
          this.$emit('submited', response.data);

          if (this.clientcrm != null) {
            await ServiceCrms.delete(this.clientcrm);
          }
          this.clientcrm == null

          this.$refs.covenants.open(response.data);

          this.$store.dispatch("setSnackbar", {});
          this.show = false;
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }

      this.loading = false;


    },
    async removerFaturacao () {
      try {
          let response;
          this.client.erp_billing_number = null
          response = await ServiceClients.add(this.client);
          this.$emit('submited', response.data);
          this.show = false;
          this.$store.dispatch("setSnackbar", {});
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }

      this.loading = false;
    },
    customFilter() {
      return true;
    },
    async myFnErpNome(newVal) {
      if (newVal) {
        this.loadingClientsNome = true;
        this.clientserpnome = (await ServiceListarClientes.listarClientes(null, newVal)).data.dados
        this.$refs.cbClient.$el.focus();
        this.loadingClientsNome = false;
      }
    },
    async myFnErpNif(newVal) {
      if (newVal) {
        this.loadingClientsNif = true;
        this.clientserpnif = (await ServiceListarClientes.listarClientes(null, null, null, newVal)).data.dados
        this.$refs.cbClient.$el.focus();
        this.loadingClientsNif = false;
      }
    },
    async selectClienteErpNome (selectedClientErpNome) {
      this.client.erp_billing_number = selectedClientErpNome.codigo

      this.myFnErpNif(selectedClientErpNome.contribuinte)
      this.selectedClientErpNif = selectedClientErpNome.codigo
    },
    async selectClienteErpNif (selectedClientErpNif) {
      this.client.erp_billing_number = selectedClientErpNif.codigo

      this.myFnErpNome(selectedClientErpNif.descricao)
      this.selectedClientErpNome = selectedClientErpNif.codigo
    },
    clear() {
      this.$refs.form.reset();
    },
    close() {
      this.$emit('close');
    },
    onDialogClose () {
      this.client = {}
      this.selectedClientErpNome = null
      this.selectedClientErpNif = null
      this.clientserpnome = []
      this.clientserpnif = []
      this.clientserpapresentarnome = null
    }
  },
  async mounted() {
    this.finds = config.CLIENT_FIND;
    this.payment_methods = config.PAYMENT_METHODS;
    this.client_type_add = "Adicionar " + config.CLIENT_TYPE_SINGULAR;
    this.client_type_edit = "Editar " + config.CLIENT_TYPE_SINGULAR;
  },
  created() {

const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {

    if (this.$refs.covenants) {
      if (this.$refs.covenants.editDialog) {
        this.$refs.covenants.editDialog = false;
        next(false);
        return;
      }

      if (this.$refs.covenants.show) {
        this.$refs.covenants.show = false;
        next(false);
        return;
      }
    }
    next();
  });

  this.$once('hook:destroyed', () => {
    unregisterRouterGuard()
  });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
