<template>
    <v-dialog
      v-model="dialog"
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar dark :color="options.color" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold">
            {{ title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <!--
            <v-btn text icon @click.native="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>-->
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-show="!!message" class="pa-4 black--text" v-html="message"></v-card-text>
        <v-card-actions class="pt-3">
          <v-btn v-if="!options.noconfirm" outlined color="primary" class="body-2 font-weight-bold" @click.native="cancel">{{options.disagreeText}}</v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="!options.noagree" color="primary" class="body-2 font-weight-bold" outlined @click.native="agree">{{options.agreeText}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>

  <script>
    export default {
      name: "ConfirmDlg",
      data() {
        return {
          dialog: false,
          resolve: null,
          reject: null,
          message: null,
          title: null,
          options: {
            color: "green lighten-1",
            width: 400,
            zIndex: 200,
            noconfirm: false,
            noagree: false,
            disagreeText: "Cancelar",
            agreeText: "OK",
          },
        };
      },

      methods: {
        open(title, message, options) {
          this.dialog = true;
          this.title = title;
          this.message = message;
          this.options = Object.assign(this.options, options);
          return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
          });
        },
        agree() {
          this.resolve(true);
          this.dialog = false;
        },
        cancel() {
          this.resolve(false);
          this.dialog = false;
        },
      },
    };
  </script>
