import Api from '@/serviceGgest/ServiceBase'

export default {
  listarArtigos (codigo, descricao, barras) {
    return Api().get('listarArtigos',{
      params: {
        codigo,
        descricao,
        barras
      }
    })
  },
  listarArtigosCodigo (codigo) {
    return Api().get(`listarArtigos/codigo/${codigo}`)
  },
}
