import Api from '@/services/ServiceBase'
const path = 'crms'

export default {
  createUpdate(item) {
    return Api().post(path, item)
  },
  list(page, name, status, gender, phone, typeeventid) {
    return Api().get(path, {
      params: {
        page,
        name,
        status,
        gender,
        phone,
        typeeventid
      }
    })
  },
  show(id) {
    return Api().get(path + `/${id}`)
  },
  delete(crmId) {
    return Api().get(path + `/delete/${crmId}`)
  },
}
