import Api from '@/services/ServiceBase'
const path = 'clientplanstypeevents'

export default {
  planostiposservicocliente (idcliente, idtypeevent, idplan) {
    return Api().get(path,{
      params: {
        idcliente,
        idtypeevent,
        idplan
      }
    })
  },
  update (elementos) {
    return Api().put(path + `/${elementos.id}`, elementos)
  },
}
