import Api from '@/services/ServiceBase'
const path = 'plans'

export default {
  createUpdate(item) {
    return Api().post(path, item)
  },
  list(page, name) {
    return Api().get(path, {
      params: {
        page,
        name
      }
    })
  },
  show(id) {
    return Api().get(path + `/${id}`)
  },
  delete(planId) {
    return Api().get(path + `/delete/${planId}`)
  },
}
