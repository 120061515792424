import Api from '@/serviceGgest/ServiceBase'
const path = 'pedirToken'

export default {
  login(user,password) {
    return Api().post(path,{
      utilizador: user,
      palavrapasse: password
    })
  },
}
