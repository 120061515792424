import Api from '@/services/ServiceBase'
const path = 'invoiceloglines'

export default {
  createUpdate(item) {
    return Api().post(path, item)
  },
  list(idinvoicelog) {
    return Api().get(path, {
      params: {
        idinvoicelog
      }
    })
  },
}
