<template>
  <v-container>
    <v-speed-dial fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false">
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark large fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-filter-variant</v-icon>
        </v-btn>
      </template>
      <v-btn fab dark large color="green" @click.stop="filterDialog = true">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-btn fab dark large color="indigo" @click="addEditItem()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-speed-dial>

    <v-row align="center" justify="center">
      <v-col cols="12" md="10">
        <v-data-table class="mTable" id="mytable" v-infinite-scroll="getUsers" infinite-scroll-disabled="busy"
          infinite-scroll-distance="10" :headers="headers" :items="users" :loading="loading" hide-default-footer
          :items-per-page="users.length + 10">
          <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>

          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>

          <template v-slot:[`item.action`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="addEditItem(item)">
                    <v-icon v-on="on" color="teal">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="deleteItem(item)">
                    <v-icon v-on="on" color="pink">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Eliminar</span>
              </v-tooltip>

              <v-tooltip top v-if="item.discontinued == false">
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="concluirWaitinglist(item)">
                    <v-icon v-on="on" color="orange">mdi-playlist-check</v-icon>
                  </v-btn>
                </template>
                <span>Concluir</span>
              </v-tooltip>

          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="editDialog" ref="test" scrollable width="1500" transition="dialog-bottom-transition">
      <add-edit-user ref="qwerty" :waitinglist="editedItem" @submited="submitedData" @close="close"></add-edit-user>
    </v-dialog>

    <v-dialog v-model="filterDialog" max-width="600px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Filtros :</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="filterDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-autocomplete style="padding-top: 0px; margin-top: 0px" ref="cbClient" :label="clientTypeP" :filter="customFilter"
                  :loading="loadingClients" v-debounce="newVal => myFn(newVal, 'v-text-field')" @input="selectCliente"
                  :items="clients" item-text="name" item-value="id"
                  clearable :return-object="true"
                  hint="Nome, NIF ou Tlf." persistent-hint> <template v-slot:progress>
                    <span class="custom-loader">
                      <v-icon light>mdi-cached</v-icon>
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete label="Tipo de serviço" clearable v-model="typeeventid" :items="typeevents" item-text="name" item-value="id"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-radio-group v-model="discontinued" column>
                  <v-radio v-for="item in discontinuedArray" :key="item.value" :label="item.text" :value="item.value"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="blue darken-1" text @click="Filter()">Procurar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import infiniteScroll from "vue-infinite-scroll";
import ServiceWaitinglists from "@/services/ServiceWaitinglists";
import ServiceClients from "@/services/ServiceClients";
import ServiceTypeevents from "@/services/ServiceTypeevents";
import AddEditUser from "@/components/waitinglist/AddEditWaitinglist.vue";
import _ from "lodash";
export default {
  name: "List-Waitinglists",
  directives: {
    infiniteScroll
  },
  components: {
    AddEditUser,
  },
  data() {
    return {
      editDialog: false,
      editedIndex: -1,
      editedItem: {
      },
      defaultItem: {
      },
      value: "",
      fileName: "",
      filterDialog: false,
      fab: false,
      clientTypeP: '',
      loadingClients: false,
      page: 1,
      headers: [
        { text: "Data", value: "createdAt", sortable: false },
        { text: "Responsável", value: "User.name", sortable: false },
        { text: "Nome", value: "Client.name", sortable: false },
        { text: "Tipo de serviço", value: "Typeevent.name", sortable: false },
        { text: "Plano", value: "Plan.name", sortable: false },
        { text: "Observações", value: "observations", sortable: false },
        { text: "", value: "action", sortable: false, align: "center" }
      ],
      discontinued: 0,
      discontinuedArray: [
        { text: "Ver todos", value: 2 },
        { text: "Ver apenas activos", value: 0 },
        { text: "Ver apenas inactivos", value: 1 }
      ],
      users: [],
      typeevents: [],
      clients: [],
      swatches: [],
      test: [],
      busy: false,
      loading: true,
      typeeventid: null,
      clientid: null,
    };
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
    async getUsers() {
      this.busy = true;
      this.loading = true;
      const response = await ServiceWaitinglists.list(
        this.page,
        this.clientid,
        this.typeeventid,
        this.discontinued
      );

      this.users = this.users.concat(response.data);

      this.page++;
      this.busy = false;
      if (response.data.length == 0) {
        this.busy = true;
      }
      this.loading = false;
    },
    async deleteItem(item) {
      if (confirm("Confirma a eliminação deste elemento?")) {
        try {
          const index = this.users.indexOf(item);
          await ServiceWaitinglists.delete(item.id);
          this.users.splice(index, 1);
          this.$store.dispatch("setSnackbar", {});
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
        }
      }
    },
    async addEditItem(item) {
      try {

        if (item) {
          this.editedIndex = this.users.indexOf(item);
          this.editedItem = Object.assign({}, item);
        } else {
          this.editedIndex = -1;
          this.editedItem = Object.assign({}, this.defaultItem);
        }
        this.editDialog = true;

      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    submitedData(item) {
      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], item);
      } else {
        this.users.unshift(item);
      }

      for (let i = 0; i < this.swatches.length; i++) {

        const index = _.findIndex(this.swatches[i], function (o) {
          return o == item.color;
        });

        if (index != -1) {
          this.swatches[i].splice(index, 1);
          break;
        }
      }
      this.users = [];
      this.page = 1;
      this.getUsers();
    },
    customFilter() {
      return true;
    },
    async myFn(newVal) {
      if (newVal) {
        this.loadingClients = true;
        this.clients = (await ServiceClients.listDebounce(newVal)).data;
        this.$refs.cbClient.$el.focus();
        this.loadingClients = false;
      }
    },
    async selectCliente(selectedClient) {
      if (selectedClient && selectedClient.id) {
        this.clientid = selectedClient.id;
      } else {
        this.clientid = null;
      }
    },
    async concluirWaitinglist(item) {
      if (confirm("Concluir este elemento?")) {
        try {
          const index = this.users.indexOf(item);
          item.discontinued = true
          await ServiceWaitinglists.update(item);
          this.users.splice(index, 1);
          this.$store.dispatch("setSnackbar", {});
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
        }
      }
    },
    Filter() {
      this.users = [];
      this.page = 1;
      this.filterDialog = false;
      this.getUsers();
    },
    close() {
      this.editDialog = false;
    },
  },
  async mounted() {
    this.clientTypeP = config.CLIENT_TYPE_PLURAL;
    this.typeevents = (await ServiceTypeevents.list(null, null, 1)).data;
  },
  created() {
    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {

      if (this.editDialog) {
        this.editDialog = false;
        next(false);
        return;
      }

      if (this.filterDialog) {
        this.filterDialog = false;
        next(false);
        return;
      }

      next();
    });

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard()
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
/*bush fix the icon was pushed to the top*/
.btn--floating .icon {
  height: 50%;
}
</style>
