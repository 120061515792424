import Api from '@/services/ServiceBase'
const path = 'waitinglists'

export default {
  createUpdate(item) {
    return Api().post(path, item)
  },
  list(page, clientid, typeeventid, discontinued) {
    return Api().get(path, {
      params: {
        page,
        clientid,
        typeeventid,
        discontinued
      }
    })
  },
  show(id) {
    return Api().get(path + `/${id}`)
  },
  delete(crmId) {
    return Api().get(path + `/delete/${crmId}`)
  },
  update (elementos) {
    return Api().put(path + `/${elementos.id}`, elementos)
  },
}
