<template>
  <v-container fill-height fluid>

    <v-row align="center" justify="center">
        <v-col cols="12" md="8">
        <v-card>
          <v-toolbar dark color="primary" class="elevation-0">
            <v-toolbar-title>Autenticação</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-form @keyup.native.enter="doLogin()">
              <v-text-field prepend-icon="mdi-account" name="login" label="Utilizador" type="text" v-model="userName">
              </v-text-field>
              <v-text-field id="password" prepend-icon="mdi-lock" name="password" label="Password" type="password"
                v-model="password"></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" :loading="loading" @click="doLogin()">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ServiceUsers from "@/services/ServiceUsers";
import ServiceConfiguracoesErp from "@/services/ServiceConfiguracoesErp";
export default {
  name: "Login",
  data() {
    return {
      userName: "",
      password: "",
      errors: [],
      loading:false
    };
  },
  methods: {
    async doLogin() {
      try {
        this.loading = true;
        const response = await ServiceUsers.login(this.userName, this.password);

        this.$store.dispatch("setToken", response.data.token);
        this.$store.dispatch("setUser", response.data.user);
        this.$store.dispatch("setLogTime", response.data.logTime);

        this.$router.push(this.$route.query.redirect || "home");

        if (this.$store.state.isUserLoggedIn == true) {
          const configuracoeserp = (await ServiceConfiguracoesErp.configuracoeserp()).data
          this.$store.dispatch("setConfiguracoeserp", configuracoeserp);
        }

      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: "Login inválido."
        });
      }
        this.loading = false;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
