import Api from '@/services/ServiceBase'
const path = 'groups'

export default {
  createUpdate(item) {
    return Api().post(path, item)
  },
  list(page, name) {
    return Api().get(path, {
      params: {
        page,
        name
      }
    })
  },
  show(id, clientdiscontinued) {
    return Api().get(path + `/${id}`, {
      params: {
        clientdiscontinued
      }
    })
  },
  delete(groupId) {
    return Api().get(path + `/delete/${groupId}`)
  }
}
