<template>
  <v-dialog v-model="show" scrollable width="1500" transition="dialog-bottom-transition" @input="onDialogClose">
    <v-card class="basicBackGround">
      <v-toolbar dark color="light-blue">
        <v-toolbar-title>Artigos por faturar: {{ client.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
          <v-card class="elevation-0" color="transparent" v-if="articlesnotinvoiced.length != 0">
            <v-list color="transparent">
              <v-list-item-group>
                <v-list-item>
                  <v-list-item-content>
                    <v-data-table infinite-scroll-disabled="busy"
                      :headers="headers" :items="articlesnotinvoiced" :loading="loading" hide-default-footer
                      :items-per-page="articlesnotinvoiced.length + 10" class="mTable" show-select item-value="id" v-model="selectedArticles">

                      <template v-slot:[`item.Date`]="{ item }">
                        {{ formatDate(item.Date) }}
                      </template>

                      <template v-slot:[`item.Price`]="{ item }">
                        {{ item.Price }} €
                      </template>

                      <template v-slot:[`item.action`]="{ item }">
                        <td class="justify-center layout px-0">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn icon class="mx-0">
                                <v-icon v-on="on" color="teal" @click="openCovenantcyclesItem(item)">mdi-pencil</v-icon>
                              </v-btn>
                            </template>
                            <span>Editar avença</span>
                          </v-tooltip>
                        </td>
                      </template>
                    </v-data-table>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" outlined @click="openInvoices()">Criar fatura</v-btn>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
          <v-card v-else class="elevation-0" align="center" justify="center" color="transparent">
            <v-icon size="200" color="red">
              mdi-file
            </v-icon>
            <v-card-title class="justify-center">Não há artigos por faturar a apresentar.</v-card-title>
          </v-card>
      </v-card-text>
    </v-card>
    <invoices @handleInvoice="handleInvoice" ref="invoices" />
    <covenantscycles @handleEditCovenantCycle="handleEditCovenantCycle" ref="covenantscycles" />
  </v-dialog>
</template>

<script>
import ServiceInvoices from "@/services/ServiceInvoices";
import Invoices from "@/components/invoice/Invoices.vue";
import Covenantscycles from "@/components/client/CovenantsCycles.vue";
export default {
  name: "ClientCovenants",
  components: {
    Invoices,
    Covenantscycles
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_URL,
      show: false,
      loading: false,
      client: {},
      articlesnotinvoiced: [],
      selectedArticles: [],
      payment_methods: [],
      selectAll: false,
      headers: [
        { text: "Data", sortable: false, value: "Date" },
        { text: "Descrição", sortable: false, value: "Cycle" },
        { text: "Tipo de serviço", sortable: false, value: "TypeeventName" },
        { text: "Plano", sortable: false, value: "PlanName" },
        { text: "Qnt", sortable: false, value: "Quantity" },
        { text: "Preço (€)", sortable: false, value: "Price" },
        { text: "Ações", value: "action", sortable: false, align: "center" }
      ],
      rules: {
        required: value => !!value || "Campo Obrigatório.",
        counter: value =>
          (value && value.length <= 250) ||
          "Campo tem de ter menos de 250 caracteres",
        isValidNumber: value =>
          /^([0-9]+[.])?[0-9]+$/.test(value) || "Valor inválido"
      }
    };
  },
  watch: {
    selectedArticles(val) {
      this.selectAll = val.length === this.articlesnotinvoiced.length;
    }
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
    async handleInvoice () {
      //show false porque nã oestá a atualizar
      this.selectedArticles = []
      this.articlesnotinvoiced = (await ServiceInvoices.listarticlesnotinvoiced(null, this.client.id, null, null, null)).data
      this.show = false;
    },
    close() {
      this.show = false;
    },
    async open(client) {
      this.loading = true;
      this.client = client;
      this.articlesnotinvoiced = (await ServiceInvoices.listarticlesnotinvoiced(null, this.client.id, null, null, null)).data
      this.show = true;
      this.loading = false;
    },
    toggleSelectAll() {
      if (this.selectAll) {
        this.selectedArticles = [];
      } else {
        this.selectedArticles = [...this.articlesnotinvoiced];
      }
      this.selectAll = !this.selectAll;
    },
    openInvoices() {
      if (this.selectedArticles != '') {
        this.$refs.invoices.open(this.client, this.selectedArticles, 1);
      }
    },
    openCovenantcyclesItem(item) {
      this.$refs.covenantscycles.open({id: item.CovenantCycle.Covenant.id, ClientId: item.ClientId});
    },
    async handleEditCovenantCycle () {
      this.articlesnotinvoiced = (await ServiceInvoices.listarticlesnotinvoiced(null, this.client.id, null, null, null)).data
    },
    onDialogClose(isOpen) {
      if (!isOpen) {
        this.selectedArticles = [];
      }
    },
  },
  computed: {
  },
  async mounted() {
    this.payment_methods = config.PAYMENT_METHODS;
  },
  created() {

    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {

      if (this.$refs.invoices) {
        if (this.$refs.invoices.editDialog) {
          this.$refs.invoices.editDialog = false;
          next(false);
          return;
        }

        if (this.$refs.invoices.show) {
          this.$refs.invoices.show = false;
          next(false);
          return;
        }
      }

      if (this.$refs.covenantscycles) {
        if (this.$refs.covenantscycles.editDialog) {
          this.$refs.covenantscycles.editDialog = false;
          next(false);
          return;
        }

        if (this.$refs.covenantscycles.show) {
          this.$refs.covenantscycles.show = false;
          next(false);
          return;
        }
      }

      next();
    });

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard()
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
