<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-container>
          <v-row>
            <v-col cols="12" md="2">
              <v-dialog ref="toggleDataInicio" v-model="toggleDataInicio" :return-value.sync="data_inicio_select"
                persistent width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field clearable v-model="data_inicio_select" label="Data Inicio (desde)"
                    prepend-icon="mdi-clock-outline" v-on="on"></v-text-field>
                </template>
                <v-date-picker :max="data_fim_select" locale="pt-pt" v-model="data_inicio_select" scrollable>
                  <v-btn text color="primary" @click="toggleDataInicio = false">Cancelar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="updateDashboardDateInicio(data_inicio_select)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="2">
              <v-dialog ref="toggleDataFim" v-model="toggleDataFim" :return-value.sync="data_fim_select"
                persistent width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field clearable v-model="data_fim_select" label="Data Fim (até)"
                    prepend-icon="mdi-clock-outline" v-on="on"></v-text-field>
                </template>
                <v-date-picker :min="data_inicio_select" locale="pt-pt" v-model="data_fim_select" scrollable>
                  <v-btn text color="primary" @click="toggleDataFim = false">Cancelar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="updateDashboardDateFim(data_fim_select)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete ref="cbClient" :label="clientTypeP"
                :loading="loadingClients" v-debounce="newVal => myFn(newVal, 'v-text-field')" v-model="client_select"
                :items="clients" item-text="name" item-value="id"
                clearable :return-object="false" @change="updateDashboardClient"
                hint="Nome, NIF ou Tlf." persistent-hint> <template v-slot:progress>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete @change="updateDashboardClient" label="Funcionário" v-model="user_select" :items="users" item-text="name" item-value="id" clearable></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" v-if="client_select == null && user_select == null">
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-card>
                <h4 class="text-center" style="padding: 5px;">{{ clientTypeP }}</h4>
                <pie-chart class="graficosTamanho" style="padding: 10px;" :colors="['#1565C0', '#0d2149']" :data="[['Inscritos', clientes_inscitos_pesquisa], ['Desistências', clientes_desistencias_pesquisa]]"></pie-chart>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card>
                <h4 class="text-center" style="padding: 5px;">{{ clientTypeP }} inscritos (Onde nos conheceram)</h4>
                <column-chart class="graficosTamanho" style="padding: 10px;" :data="chartOndeNosConheceuPesquisa" :colors="['#1565C0']"></column-chart>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="12" md="6">
        <v-container>
          <v-row>
            <v-col cols="12" md="4">
              <v-card>
                <h4 class="text-center" style="padding: 5px;">Número de marcações</h4>
                <pie-chart class="graficosTamanho" style="padding: 10px;" :colors="['#1565C0']" :data="[['Marcações', eventos_numero_pesquisa]]"></pie-chart>
              </v-card>
            </v-col>
            <v-col cols="12" md="4">
              <v-card>
                <h4 class="text-center" style="padding: 5px;">Tipos de status marcações</h4>
                <column-chart class="graficosTamanho" style="padding: 10px;" :data="chartTipoStatusPesquisa" :colors="['#1565C0']"></column-chart>
              </v-card>
            </v-col>
            <v-col cols="12" md="4">
              <v-card>
                <h4 class="text-center" style="padding: 5px;">Tipos de serviço marcações</h4>
                <column-chart class="graficosTamanho" style="padding: 10px;" :data="chartTipoServicosPesquisa" :colors="['#1565C0']"></column-chart>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-card>
                <h4 class="text-center" style="padding: 5px;">{{ clientTypeP }}</h4>
                <pie-chart class="graficosTamanho" style="padding: 10px;" :colors="['#1565C0', '#0d2149']" :data="[['Ativos', clientes_ativos], ['Inativos', clientes_inativos]]"></pie-chart>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card>
                <h4 class="text-center" style="padding: 5px;">{{ clientTypeP }} (Onde nos conheceram)</h4>
                <column-chart class="graficosTamanho" style="padding: 10px;" :data="chartOndeNosConheceu" :colors="['#1565C0']"></column-chart>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="12" md="6">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-card>
                <h4 class="text-center" style="padding: 5px;">Marcações por ano</h4>
                <line-chart class="graficosTamanho" style="padding: 10px;" :data="eventos_ano" :colors="['#1565C0']"></line-chart>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-container>
          <v-row>
            <v-col cols="12">
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="12" md="6">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-card>
                <h4 class="text-center" style="padding: 5px;">Marcações ano ({{currentYear}}) <v-icon color="black" @click="alterarAnoEventosMenosUm">mdi-chevron-left</v-icon><v-icon color="black" @click="alterarAnoEventosMaisUm">mdi-chevron-right</v-icon></h4>
                <line-chart class="graficosTamanho" style="padding: 10px;" :data="eventos_ano_atual" :colors="['#1565C0']"></line-chart>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ServiceDashboard from '@/services/ServiceDashboard'
import ServiceClients from '@/services/ServiceClients'
import ServiceTypeevents from '@/services/ServiceTypeevents'
import ServiceUsers from '@/services/ServiceUsers'
import _ from "lodash";
export default {
  name: "Dashboard",
  data() {
    return {
      toggleDataInicio: false,
      toggleDataFim: false,
      clientTypeP: '',
      status: [],
      clients: [],
      typeevents: [],
      users: [],
      finds: [],
      loadingClients: false,
      currentYear: new Date().getFullYear(),

      // pesquisas
      client_select: null,
      user_select: null,
      data_inicio_select: new Date().toISOString().substr(0, 10),
      data_fim_select: new Date().toISOString().substr(0, 10),

      // dados clientes
      clientes_ativos: 0,
      clientes_inativos: 0,
      clientes_ondenosconheceu: [],

      // dados marcações
      eventos_ano: [],
      eventos_ano_atual: [],

      // dados com pesquisa
      clientes_inscitos_pesquisa: 0,
      clientes_desistencias_pesquisa: 0,
      clientes_ondenosconheceu_pesquisa: [],
      eventos_numero_pesquisa: 0,
      eventos_tiposdeservico_pesquisa: [],
      eventos_tiposdestatus_pesquisa: [],
    }
  },
  async mounted() {
    this.clientTypeP = config.CLIENT_TYPE_PLURAL;
    this.status = config.EVENT_STATUS;
    this.finds = config.CLIENT_FIND;
    this.typeevents = (await ServiceTypeevents.list()).data;
    this.users = (await ServiceUsers.list(null, false)).data;

    // dados clientes
    this.clientes_ativos = (await ServiceDashboard.listclients(0)).data
    this.clientes_inativos = (await ServiceDashboard.listclients(1)).data
    this.clientes_ondenosconheceu = (await ServiceDashboard.listclientsondenosconheceu()).data

    // dados marcações
    this.eventos_ano = (await ServiceDashboard.listeventsano()).data
    this.eventos_ano_atual = (await ServiceDashboard.listeventsanoatual(this.currentYear)).data

    // dados com pesquisa
    this.clientes_inscitos_pesquisa = (await ServiceDashboard.listclientsinscritospesquisa(this.data_inicio_select, this.data_fim_select)).data
    this.clientes_desistencias_pesquisa = (await ServiceDashboard.listclientsdesistenciaspesquisa(this.data_inicio_select, this.data_fim_select)).data
    this.clientes_ondenosconheceu_pesquisa = (await ServiceDashboard.listclientsondenosconheceupesquisa(this.data_inicio_select, this.data_fim_select)).data
    this.eventos_numero_pesquisa = (await ServiceDashboard.listeventsnumeropesquisa(this.data_inicio_select, this.data_fim_select, this.client_select, this.user_select)).data
    this.eventos_tiposdeservico_pesquisa = (await ServiceDashboard.listeventstiposdeservicopesquisa(this.data_inicio_select, this.data_fim_select, this.client_select, this.user_select)).data
    this.eventos_tiposdestatus_pesquisa = (await ServiceDashboard.listeventstiposdestatuspesquisa(this.data_inicio_select, this.data_fim_select, this.client_select, this.user_select)).data
  },
  computed: {
    chartOndeNosConheceu() {
      let data = [];
      this.finds.forEach(find => {
        this.clientes_ondenosconheceu.forEach(ondenosconheceu => {
          if (find.value === ondenosconheceu.text) {
            data.push([find.text, ondenosconheceu.cont]);
          }
        });
      });
      return data;
    },
    chartOndeNosConheceuPesquisa() {
      let data = [];
      this.finds.forEach(find => {
        this.clientes_ondenosconheceu_pesquisa.forEach(ondenosconheceupesquisa => {
          if (find.value === ondenosconheceupesquisa.text) {
            data.push([find.text, ondenosconheceupesquisa.cont]);
          }
        });
      });
      return data;
    },
    chartTipoStatusPesquisa() {
      let data = [];
      this.status.forEach(statu => {
        this.eventos_tiposdestatus_pesquisa.forEach(tiposdestatus => {
          if (statu.value === tiposdestatus.text) {
            data.push([statu.text, tiposdestatus.cont]);
          }
        });
      });
      return data;
    },
    chartTipoServicosPesquisa() {
      let data = [];
      this.typeevents.forEach(typeevent => {
        this.eventos_tiposdeservico_pesquisa.forEach(tiposdeservico => {
          if (typeevent.id === tiposdeservico.text) {
            data.push([typeevent.name, tiposdeservico.cont]);
          }
        });
      });
      return data;
    }
  },
  methods: {
    async updateDashboardDateInicio(date) {
      this.$refs.toggleDataInicio.save(date);
      this.clientes_inscitos_pesquisa = (await ServiceDashboard.listclientsinscritospesquisa(this.data_inicio_select, this.data_fim_select)).data
      this.clientes_desistencias_pesquisa = (await ServiceDashboard.listclientsdesistenciaspesquisa(this.data_inicio_select, this.data_fim_select)).data
      this.clientes_ondenosconheceu_pesquisa = (await ServiceDashboard.listclientsondenosconheceupesquisa(this.data_inicio_select, this.data_fim_select)).data
      this.eventos_numero_pesquisa = (await ServiceDashboard.listeventsnumeropesquisa(this.data_inicio_select, this.data_fim_select, this.client_select, this.user_select)).data
      this.eventos_tiposdeservico_pesquisa = (await ServiceDashboard.listeventstiposdeservicopesquisa(this.data_inicio_select, this.data_fim_select, this.client_select, this.user_select)).data
      this.eventos_tiposdestatus_pesquisa = (await ServiceDashboard.listeventstiposdestatuspesquisa(this.data_inicio_select, this.data_fim_select, this.client_select, this.user_select)).data
    },
    async updateDashboardDateFim(date) {
      this.$refs.toggleDataFim.save(date);
      this.clientes_inscitos_pesquisa = (await ServiceDashboard.listclientsinscritospesquisa(this.data_inicio_select, this.data_fim_select)).data
      this.clientes_desistencias_pesquisa = (await ServiceDashboard.listclientsdesistenciaspesquisa(this.data_inicio_select, this.data_fim_select)).data
      this.clientes_ondenosconheceu_pesquisa = (await ServiceDashboard.listclientsondenosconheceupesquisa(this.data_inicio_select, this.data_fim_select)).data
      this.eventos_numero_pesquisa = (await ServiceDashboard.listeventsnumeropesquisa(this.data_inicio_select, this.data_fim_select, this.client_select, this.user_select)).data
      this.eventos_tiposdeservico_pesquisa = (await ServiceDashboard.listeventstiposdeservicopesquisa(this.data_inicio_select, this.data_fim_select, this.client_select, this.user_select)).data
      this.eventos_tiposdestatus_pesquisa = (await ServiceDashboard.listeventstiposdestatuspesquisa(this.data_inicio_select, this.data_fim_select, this.client_select, this.user_select)).data
    },
    async updateDashboardClient() {
      this.eventos_numero_pesquisa = (await ServiceDashboard.listeventsnumeropesquisa(this.data_inicio_select, this.data_fim_select, this.client_select, this.user_select)).data
      this.eventos_tiposdeservico_pesquisa = (await ServiceDashboard.listeventstiposdeservicopesquisa(this.data_inicio_select, this.data_fim_select, this.client_select, this.user_select)).data
      this.eventos_tiposdestatus_pesquisa = (await ServiceDashboard.listeventstiposdestatuspesquisa(this.data_inicio_select, this.data_fim_select, this.client_select, this.user_select)).data
    },
    async myFn(newVal) {
      if (newVal) {
        this.loadingClients = true;
        this.clients = (await ServiceClients.listDebounce(newVal)).data;
        this.$refs.cbClient.$el.focus();
        this.loadingClients = false;
      }
    },
    async alterarAnoEventosMenosUm () {
      this.currentYear = this.currentYear - 1
      this.eventos_ano_atual = (await ServiceDashboard.listeventsanoatual(this.currentYear)).data
    },
    async alterarAnoEventosMaisUm () {
      this.currentYear = this.currentYear + 1
      this.eventos_ano_atual = (await ServiceDashboard.listeventsanoatual(this.currentYear)).data
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.graficosTamanho{
  height: 160px !important;
  line-height: 160px !important;
}
</style>
