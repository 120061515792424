import Api from '@/services/ServiceBase'
const path = 'covenants'

export default {
  createClientCovenants(item) {
    return Api().post(path, item)
  },
  getClientCovenants(clientId, discontinued, typeeventId) {
    return Api().get(path + `/listclient/`,{
      params: {
        clientId,
        discontinued,
        typeeventId
      }
    })
  },
  deleteClientCovenants(covenantId) {
    return Api().delete(path + `/${covenantId}`)
  },
  updateClientCovenants(item) {
    return Api().put(path + `/${item.id}`, item)
  },
  CreateCovenantTypeevents(item) {
    return Api().post(path + '/createcovenanttypeevents', item)
  },
}
