<template>
  <v-container>
    <v-dialog v-model="show" scrollable width="1500" transition="dialog-bottom-transition">
    <v-card class="basicBackGround">
      <v-toolbar dark color="light-blue">
        <v-toolbar-title>Alteração preçário do cliente: {{ client.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
          <v-card class="elevation-0" color="transparent" v-if="typeseventclient.length != 0">
            <v-list color="transparent">
              <v-list-item-group>
                <v-list-item>
                  <v-list-item-content>
                    <v-data-table infinite-scroll-disabled="busy"
                      :headers="headers" :items="typeseventclient" :loading="loading" hide-default-footer
                      :items-per-page="typeseventclient.length + 10" class="mTable">
                      <template v-slot:[`item.price`]="{ item }">
                        {{ item.price }} €
                      </template>

                      <template v-slot:[`item.action`]="{ item }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn icon class="mx-0" @click="edittItem(item)">
                              <v-icon v-on="on" color="teal">mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Editar</span>
                        </v-tooltip>

                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn icon class="mx-0" @click="openPlans(item)">
                              <v-icon v-on="on" color="orange">mdi-floor-plan</v-icon>
                            </v-btn>
                          </template>
                          <span>Editar plano cliente</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
          <v-card v-else class="elevation-0" align="center" justify="center" color="transparent">
            <v-icon size="200" color="red">
              mdi-file
            </v-icon>
            <v-card-title class="justify-center">Não há serviços de cliente a apresentar.</v-card-title>
          </v-card>
      </v-card-text>
    </v-card>



    <v-dialog v-model="editDialog" presistent scrollable width="600" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Editar</v-toolbar-title>
            <v-spacer />
            <v-btn icon dark @click="editDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-text-field type="number" v-model="editItem.price" label="Preço (€)" :rules="[rules.required]"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions v-if="$store.state.user.role === 'admin' || editItem.UserId === $store.state.user.id || !editItem.UserId">
          <v-spacer></v-spacer>
          <v-btn text color="primary" outlined @click="submitEditItem">Submeter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>

  <planstypeevents ref="planstypeevents" />
  </v-container>

</template>

<script>
import ServiceClienttypeevents from "@/services/ServiceClienttypeevents";
import Planstypeevents from "@/components/client/Planstypeevents.vue";
import _ from "lodash";
export default {
  name: "ClientTypeevent",
  components: {
    Planstypeevents
  },
  data() {
    return {
      show: false,
      loading: false,
      editDialog: false,
      client: {},
      typeseventclient: [],
      editItem: {},
      valid: true,
      page: 1,
      preco: null,
      headers: [
        { text: "Tipo de serviço", sortable: false, value: "Typeevent.name" },
        { text: "Preço (€)", sortable: false, value: "price" },
        { text: "Ações", value: "action", sortable: false, align: "center" }
      ],
      rules: {
        required: value => !!value || "Campo Obrigatório.",
        counter: value =>
          (value && value.length <= 250) ||
          "Campo tem de ter menos de 250 caracteres",
        isValidNumber: value =>
          /^([0-9]+[.])?[0-9]+$/.test(value) || "Valor inválido"
      }
    };
  },
  methods: {
    close() {
      this.show = false;
    },
    async open(client) {
      this.client = client;
      this.typeseventclient = (await ServiceClienttypeevents.tiposservicocliente(this.client.id, null)).data;
      this.show = true;
      this.loading = false;
    },
    async edittItem (item) {
      this.editItem = item
      this.editDialog = true
    },
    async submitEditItem () {
      try {
        if (this.$refs.form.validate()) {
          this.typeseventclient = (await ServiceClienttypeevents.addupdate(this.editItem.id, this.editItem.price)).data;
          this.$store.dispatch("setSnackbar", {});
          this.editDialog = false
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    openPlans(item) {
      this.$refs.planstypeevents.open(item, this.client);
    },
  },
  computed: {
  },
  async mounted() {
  },
  created() {
    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {

      if (this.$refs.planstypeevents) {
        if (this.$refs.planstypeevents.$refs.eventDialog) {
          if (this.$refs.planstypeevents.$refs.eventDialog.dialog) {
            if (this.$refs.planstypeevents.$refs.eventDialog.navGuards()) {
              next(false);
              return;
            }
          }
        }

        if (this.$refs.planstypeevents.show) {
          this.$refs.planstypeevents.show = false;
          next(false);
          return;
        }
      }

      next();
    });

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard()
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
