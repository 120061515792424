import Api from '@/services/ServiceBase'
const path = 'covenantscycles'

export default {
  createCovenantsCycles(item) {
    return Api().post(path, item)
  },
  getClientCovenantsCycles(covenantId) {
    return Api().get(path + `/listclient/`,{
      params: {
        covenantId
      }
    })
  },
  deleteCovenantsCycles(covenantcycleId) {
    return Api().delete(path + `/${covenantcycleId}`)
  },
  updateClientCovenantsCycles(item) {
    return Api().put(path + `/${item.id}`, item)
  },
}
