<template>
  <v-row class="fill-height">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-btn fixed dark fab bottom right large color="indigo" @click.stop="$refs.eventDialog.open()">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-col>
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            Hoje
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ auxCapitalize($refs.calendar.title) }}
          </v-toolbar-title>

          <v-icon color="primary" @click="modal = true">mdi-calendar</v-icon>

          <v-dialog ref="dialog" v-model="modal" persistent width="290px">
            <v-date-picker v-model="auxFocus" scrollable>
              <v-btn text color="error" @click="modal = false">
                Cancelar
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text color="success" @click="saveDate()">
                Confirmar
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <v-spacer></v-spacer>

          <v-menu right>
            <template v-slot:activator="{ on }">
              <v-btn outlined color="grey darken-2" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Mês</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Semana</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Dia</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu right>
            <template v-slot:activator="{ on }">
              <v-btn outlined color="grey darken-2" v-on="on" class="ml-2">
                <span>{{ typeToLabelIntervalTime[intervalMinutes] }}</span>
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="intervalMinutes = 15">
                <v-list-item-title>15min</v-list-item-title>
              </v-list-item>
              <v-list-item @click="intervalMinutes = 30">
                <v-list-item-title>30min</v-list-item-title>
              </v-list-item>
              <v-list-item @click="intervalMinutes = 60">
                <v-list-item-title>1h</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu>
            <template v-slot:activator="{ attrs, on }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on" outlined class="ml-2">
                Legenda
                <v-icon right dark>
                  mdi-home
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in rooms" :key="index">
                <v-list-item-avatar>
                  <v-chip class="pa-4 rounded-circle" :color="item.color">
                  </v-chip>
                </v-list-item-avatar>
                <v-list-item-title>{{ item.identification }} - {{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet class="calHeight">
        <v-calendar ref="calendar" interval-width="60" locale="pt-PT" v-model="focus" color="primary" category-text="name"
          :events="events" :first-time=$config.Calendar_First_Time :interval-count="intervalCount" :weekdays="weekday"
          :interval-minutes="intervalMinutes" :type="type" @change="getCalendar"
          @click:event="showEvent" @click:time="createEventClick"
          @mousedown:event="startDragMover" @mousedown:time="startTimeMover" @mousemove:time="mouseMoveMover" @mouseup:time="endDragMover" @mouseleave.native="cancelDragMover">
          <template v-slot:event="{ event }">
              <!--Se não tiver clientes na marcação-->
              <template v-if="event.ClientEvents.length == 0">
                <!--Se for uma marcação normal-->
                <template v-if="event.iseventnotebreak === 1">
                  <div style="padding-left: 5px;">
                    {{event.roomIdentification}}
                  </div>
                  <div style="padding-left: 5px;">
                    {{event.typeeventName}}
                  </div>
                  <div style="padding-left: 5px;">
                    {{ formatTime(event.start) }} - {{ formatTime(event.end) }}
                  </div>
                  <div style="padding-left: 5px;">
                    {{event.details}}
                  </div>
                </template>
                <!--Se for uma pausa (userbreak)-->
                <template v-else-if="event.iseventnotebreak === 3">
                  <div style="padding-left: 5px;">
                    {{event.userName}}
                  </div>
                  <div style="padding-left: 5px;">
                    {{event.details}}
                  </div>
                </template>
                <!--Se for uma nota-->
                <template v-else>
                  <div style="padding-left: 5px;">
                    {{event.roomName}}
                  </div>
                  <div style="padding-left: 5px;">
                    {{event.details}}
                  </div>
                </template>
              </template>
              <template v-else-if="event.ClientEvents.length != 1">
                <span style="padding-left: 5px">
                    {{ event.roomIdentification + ' - Marcação com ' + event.ClientEvents.length }} {{ clientTypeP }}
                  <div style="padding-left: 5px">
                    {{ event.typeeventName }}
                  </div>
                  <div style="padding-left: 5px;">
                    {{ formatTime(event.start) }} - {{ formatTime(event.end) }}
                  </div>
                  <div style="padding-left: 5px;">
                    {{event.details}}
                  </div>
                </span>
              </template>
              <template v-else>
                <span :style="{paddingLeft: '5px',borderLeft: '10px solid',borderLeftColor: statusColor(event.ClientEvents[0].status), whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis',display: 'inline-block',width: '100%',height: '100%'}" style="padding-left: 5px;">
                  <div>
                    {{ event.roomIdentification + ' - ' + event.ClientEvents[0].Client.name }}
                  </div>
                  <div>
                    {{ event.typeeventName }}
                  </div>
                  <div>
                    {{ formatTime(event.start) }} - {{ formatTime(event.end) }}
                  </div>
                  <div>
                    {{event.details}}
                  </div>
                  <v-tooltip top v-if="event.ClientEvents[0].sms == true">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="green">mdi-message-text</v-icon>
                    </template>
                    <span>{{ formatDate(event.ClientEvents[0].sms_date) }}</span>
                  </v-tooltip>
                  <v-tooltip top v-else>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="red">mdi-message-text</v-icon>
                    </template>
                    <span>SMS não enviado</span>
                  </v-tooltip>
                </span>
              </template>
          </template>
        </v-calendar>
      </v-sheet>
    </v-col>
    <event-dialog ref="eventDialog" :rooms="rooms" :users="users" :typeevents="typeevents" :groups="groups" @handleEvent="handleEvent"
        @handleDelete="handleDelete" @handleReplacementEvent="handleReplacementEvent" @handleReplacementEventClose="handleReplacementEventClose"></event-dialog>
  </v-row>
</template>

<script>
import ServiceCalendars from "@/services/ServiceCalendars";
import EventDialog from "@/components/common/EventDialog.vue";
import _ from "lodash";
import { getUsersAndRooms, capitalize, getTypeevents, getGroups } from "@/components/Utils";
import moment from "moment";

export default {
  name: "Home",
  components: {
    EventDialog
  },
  data() {
    return {

      typeToLabel: {
        month: "Mês",
        week: "Semana",
        day: "Dia"
      },

      type: "week",
      intervalMinutes: 60,
      intervalCount: 24,
      types: [
        { text: "Mês", value: "month" },
        { text: "Semana", value: "week" },
        { text: "Dia", value: "day" }
      ],
      typeToLabelIntervalTime: {
        15: 15,
        30: 30,
        60: 60
      },


      loading: false,
      focus: '',
      auxFocus: '',
      events: [],
      users: [],
      rooms: [],
      typeevents: [],
      groups: [],
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
      modal: false,
      clientTypeP: '',
      eventstatus: [],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      newEventReplacement: {},
      newEventReplacementClients: [],
      replacementeventstatus: false,

      //variáveis mover evento
      moverEvent: null,
      moverTime: null
    };
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
    formatTime(dateString) {
      const date = new Date(dateString);

      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      return `${hours}:${minutes}`;
    },
    statusColor(value) {
      for (let statu of this.eventstatus) {
        if (statu.value === value) {
          return statu.color;
        }
      }
    },
    auxCapitalize(word) {
      return capitalize(word);
    },
    saveDate() {
      this.focus = this.auxFocus;
      this.modal = false;
    },
    setToday() {
      this.focus = ''
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    //clicar num espaço em branco e criar evento
    createEventClick(campoescolhido) {
      if (!this.events) {
        this.events = [];
      }
      const intervalMinutes = this.intervalMinutes;
      const startdate = new Date(campoescolhido.year, campoescolhido.month - 1, campoescolhido.day, campoescolhido.hour, campoescolhido.minute);

      const minutes = startdate.getMinutes();
      const roundedMinutes = Math.floor(minutes / intervalMinutes) * intervalMinutes;

      startdate.setMinutes(roundedMinutes);
      startdate.setSeconds(0);
      startdate.setMilliseconds(0);
      const enddate = new Date(startdate.getTime() + intervalMinutes * 60 * 1000);


      const dataInicio = `${startdate.getFullYear()}-${String(startdate.getMonth() + 1).padStart(2, '0')}-${String(startdate.getDate()).padStart(2, '0')}`;
      const dataFim = `${enddate.getFullYear()}-${String(enddate.getMonth() + 1).padStart(2, '0')}-${String(enddate.getDate()).padStart(2, '0')}`;
      const horaInicio = `${String(startdate.getHours()).padStart(2, '0')}:${String(startdate.getMinutes()).padStart(2, '0')}`;
      const horaFim = `${String(enddate.getHours()).padStart(2, '0')}:${String(enddate.getMinutes()).padStart(2, '0')}`;

      if (this.replacementeventstatus === false) {
        const newEventClick = {
          dataInicio: dataInicio,
          dataFim: dataFim,
          horaInicio: horaInicio,
          horaFim: horaFim,
        };

        this.$refs.eventDialog.open(null, null, null, newEventClick, null);
      } else {
        const newEventReplacementClick = {
          id: this.newEventReplacement.id,
          dataInicio: dataInicio,
          dataFim: dataFim,
          horaInicio: horaInicio,
          horaFim: horaFim,
          TypeeventId: this.newEventReplacement.TypeeventId,
          RoomId: this.newEventReplacement.RoomId,
          UserId: this.newEventReplacement.UserId,
          invoiced: this.newEventReplacement.invoiced,
          details: this.newEventReplacement.details,
          linkedId: this.newEventReplacement.linkedId,
          arrayDeClientes: this.newEventReplacementClients
        };

        this.$refs.eventDialog.open(null, null, null, null, newEventReplacementClick);

      }
    },
    //clicar no evento e abrir EventDialog
    showEvent({ nativeEvent, event }) {
      // se clicarmos numa pausa (userbreak), o iseventnotebreak está 3 e não abre o EventDialog
      if (event.iseventnotebreak != 3) {
        this.$refs.eventDialog.open(event, null, null, null, null);
      }

      nativeEvent.stopPropagation();
    },
    //mover evento para outro dia e abrir EventDialog
    startDragMover (event) {
        this.moverEvent = event
        this.moverTime = null
    },
    startTimeMover (tms) {
        const mouse = this.toTime(tms)
        if (this.moverEvent && this.moverTime === null) {
          let dateinicioevento = new Date(this.moverEvent.event.start);
          let timestampdateinicioevento = dateinicioevento.getTime();
          const start = timestampdateinicioevento
          this.moverTime = mouse - start
        }
    },
    mouseMoveMover (tms) {
        const mouse = this.toTime(tms)
        // se tentarmos arrastar uma pausa (userbreak), o iseventnotebreak está 3, não arraste e não abre o EventDialog
        if (this.moverEvent && this.moverTime !== null && this.moverEvent.event.iseventnotebreak != 3) {
          let dateinicioevento = new Date(this.moverEvent.event.start);
          let timestampdateinicioevento = dateinicioevento.getTime();
          let datefimevento = new Date(this.moverEvent.event.end);
          let timestampdatefimevento = datefimevento.getTime();
          const start = timestampdateinicioevento
          const end = timestampdatefimevento
          const duration = end - start
          const newStartTime = mouse - this.moverTime
          const newStart = this.roundTime(newStartTime)
          const newEnd = newStart + duration

          let datainicio = new Date(newStart);
          let datafim = new Date(newEnd);

          let formattedDateinicio = datainicio.getFullYear() + '-' + String(datainicio.getMonth() + 1).padStart(2, '0') + '-' + String(datainicio.getDate()).padStart(2, '0') + ' ' + String(datainicio.getHours()).padStart(2, '0') + ':' + String(datainicio.getMinutes()).padStart(2, '0');
          let formattedDateifim = datafim.getFullYear() + '-' + String(datafim.getMonth() + 1).padStart(2, '0') + '-' + String(datafim.getDate()).padStart(2, '0') + ' ' + String(datafim.getHours()).padStart(2, '0') + ':' + String(datafim.getMinutes()).padStart(2, '0');

          this.moverEvent.event.start = formattedDateinicio
          this.moverEvent.event.end = formattedDateifim
        }

    },
    endDragMover () {
      // se tentarmos arrastar uma pausa (userbreak), o iseventnotebreak está 3, não arraste e não abre o EventDialog
      if (this.moverEvent.event.iseventnotebreak != 3) {
        this.$refs.eventDialog.open(this.moverEvent.event, null, null, null, null);
      }
      this.moverTime = null
      this.moverEvent = null
    },
    cancelDragMover () {
        this.moverTime = null
        this.moverEvent = null
    },
    roundTime (time, down = true) {
      const roundTo = 15
      const roundDownTime = roundTo * 60 * 1000

      return down
        ? time - time % roundDownTime
        : time + (roundDownTime - (time % roundDownTime))
    },
    toTime (tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
    },
    handleDelete(id) {

      let mIds = [];
      mIds = mIds.concat(id);

      mIds.forEach(id => {
        let index = -1;
        index = _.findIndex(this.events, { id });
        if (index != -1) this.events.splice(index, 1);
      });
    },
    handleEvent(events) {

      let mEvents = [];
      mEvents = mEvents.concat(events);
      mEvents.forEach(event => {
        if (event.UserId === this.$store.state.user.id) {
          event.color = event.roomColor;

          const index = _.findIndex(this.events, { 'id': event.id });

          if (index === -1) {
            this.events.push(event);
          } else {
            this.events.splice(index, 1, event);
          }
        }
      });
    },
    handleReplacementEvent (replacementEvent, clientsReplacementEvent) {
      this.replacementeventstatus = true
      this.newEventReplacement = replacementEvent
      this.newEventReplacementClients = clientsReplacementEvent
    },
    handleReplacementEventClose () {
      this.replacementeventstatus = false
    },
    async getCalendar() {
      this.clientTypeP = config.CLIENT_TYPE_PLURAL;
      try {

        if (this.users.length == 0) {
          return;
        }

        this.loading = true;
        this.events = [];
        // var date = new Date(this.$refs.calendar.rt.date);

        // let startDate = date.toISOString().slice(0, 10);
        // date.setDate(date.getDate() + 1);
        // let endDate = date.toISOString().slice(0, 10);

        const lastEnd = moment(this.$refs.calendar.lastEnd.date)
          .add(1, "days")
          .format("YYYY-MM-DD HH:mm");


        const response = await ServiceCalendars.getCalendar(
          this.$store.state.user.id,
          this.$refs.calendar.lastStart.date,
          lastEnd
        );


        _.forEach(response.data, value => {
          value.color = value.roomColor;
          this.events.push(value);
        });

        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
  },
  watch: {
    intervalMinutes() {
      this.$nextTick(() => {
        if (this.intervalMinutes === 15) {
          this.intervalCount = config.Calendar_Interval_Count_15
        } else if (this.intervalMinutes === 30) {
          this.intervalCount = config.Calendar_Interval_Count_30
        } else {
          this.intervalCount = config.Calendar_Interval_Count_60
        }
        this.getCalendar()
      });
    },
  },
  async mounted() {
    try {
      this.intervalCount = config.Calendar_Interval_Count_start
      this.intervalMinutes = config.Calendar_Interval_Minutes_start
      this.weekday = config.WEEK_DAYS
      this.type = config.CALENDAR_HOME_TYPE

      this.eventstatus = config.EVENT_STATUS;
      this.loading = true;
      await getUsersAndRooms(1, 1, this.users, this.rooms);
      await getTypeevents(1, this.typeevents);
      await getGroups(1, this.groups);

      this.getCalendar();
    } catch (error) {
      this.loading = false;
      this.$store.dispatch("setSnackbar", {
        color: "error",
        text: error
      });

    }
  },

  // watch: {
  //   $route(newVal) {

  //     // if (this.$refs.eventDialog.dialog) {
  //     //   this.$refs.eventDialog.close();
  //     //   return;
  //     // } else {
  //     //   this.$router.go(1);
  //     // }

  //   }
  // },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.calHeight {
  height: -moz-calc(100% - 64px);
  height: -webkit-calc(100% - 64px);
  height: -o-calc(100% - 64px);
  height: calc(100% - 64px);
}
</style>
