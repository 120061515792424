<template>
    <v-container style="margin-bottom: 70px;" v-if="$store.state.user.role === 'infordio'">
      <v-row align="center" justify="center">
        <v-col cols="12" md="10">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-switch v-model="configuracoeserp.ativo" label="Ativo"></v-switch>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-text-field v-model="configuracoeserp.erp" :rules="[rules.required]" label="Erp"></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col cols="12" class="text-right">
                  <v-btn text color="primary" outlined @click="guardarAlteracoes">Guardar</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import ServiceConfiguracoesErp from "@/services/ServiceConfiguracoesErp";
import ServiceAcessoBD from "@/serviceGgest/ServiceAcessoBD";
import ServiceLogin from "@/serviceGgest/ServiceLogin";
export default {
  name: "Configurações-Erp",
  data() {
    return {
      valid: true,
      configuracoeserp: {
        ativo: null,
        erp: null
      },
      rules: {
        required: value => !!value || "Campo Obrigatório.",
      }
    };
  },
  methods: {
    async guardarAlteracoes () {
      try {
        await ServiceConfiguracoesErp.configuracoeserpupdate(this.configuracoeserp)
        this.$store.dispatch("setSnackbar", {});
        if (this.configuracoeserp.erp == 'ggest') {
          const dados = await ServiceAcessoBD.acessobd()
          if (dados.status === 500) {
              alert("Erro a configurar acessos à bd ggest.");
          } else {
              alert("Acessos da bd ggest configurados com sucesso.");
          }
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    }
  },
  async mounted() {
    if (this.$store.state.user.role === 'infordio') {
      this.configuracoeserp = (await ServiceConfiguracoesErp.configuracoeserp()).data
      const response = await ServiceLogin.login(process.env.VUE_APP_API_USER_GGEST, process.env.VUE_APP_API_PASS_GGEST);
      this.$store.dispatch("setTokenGgest", response.data.token);
    } else {
      this.$router.push(this.$route.query.redirect || "home");
    }

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
