<template>
  <v-card>
    <v-card-title class="pa-0">
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ group.id == undefined ? 'Adicionar' : 'Editar' }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-text-field v-model="group.name" :rules="[rules.required, rules.counter]" :counter="250"
                label="Descrição" required></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-autocomplete id="autoselectclientes" label="Clientes" v-model="group.Clients" :items="clients" item-text="name" item-value="id" multiple chips></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn outlined text color="red" @click="clear">Limpar</v-btn>
      <v-spacer></v-spacer>
      <v-btn outlined text color="primary" :disabled="!valid" @click="submit">Submeter</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ServiceGroups from "@/services/ServiceGroups";
import ServiceClients from "@/services/ServiceClients";
import _ from "lodash";
export default {
  name: "AddEdit-Group",
  props: ['group'],
  data() {
    return {
      valid: true,
      rules: {
        required: value => !!value || "Campo Obrigatório.",
        counter: value =>
          (value && value.length <= 250) ||
          "Campo tem de ter menos de 250 caracteres",
        isValidNumber: value =>
          /^([0-9]+[.])?[0-9]+$/.test(value) || "Valor inválido"
      },
      locked: true,
      lockClient: false,
      loadingClients: false,
      arrayClientes: [],
      clients: [],
    };
  },
  async mounted () {
    this.clients = (await ServiceClients.list(null, null, 0)).data;
  },
  methods: {
    async submit() {
      try {
        if (this.$refs.form.validate()) {

          this.loading = true;
          const response = await ServiceGroups.createUpdate(this.group);

          if (!this.group.id) {
            this.$refs.form.reset();
          }

          this.$emit('submited', response.data);

          this.loading = false;
          this.$store.dispatch("setSnackbar", {});
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    clear() {
      this.$refs.form.reset();
    },
    close() {
      this.$emit('close');
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
